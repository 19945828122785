export enum FormFieldInputType {
  TEXT = 'text',

  PASSWORD = 'password',

  RADIO = 'radio',

  BUTTON = 'button',

  FILE = 'file',

  IMAGE = 'image',

  COLOR = 'color',

  NUMBER = 'number',

  EMAIL = 'email',

  TEL = 'tel',

  URL = 'url'
}
