export enum FormFieldType {
  ROW,
  INPUT,
  TEXTAREA,
  CHECKBOX,
  RADIO,
  SLIDE_TOGGLE,
  DROPDOWN,
  NUMBER,
  UPLOAD_FILES,
  DATEPICKER,
  TIMEPICKER,
  LINK,
  IMAGE_CROPPER,
  AUTOCOMPLETE,
  ADDRESS_AUTOCOMPLETE,
  CKEDITOR5,
  PASSWORD,
  SECTION_HEADER,
  PRICE,
  TEMPLATE_REF,
  IMAGE_UPLOADER,
  PHONE,
  PERCENTAGE,
  LOCATION_PIN_MAP,
  DYNAMIC_SECTION,
  COLOR_PICKER,
  IMAGE_UPLOAD_OR_DRAG_DROP,
  SLIDE_TOGGLE_CUSTOM
}
