<favie-label
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<ng-container [ngSwitch]="field?.dateMode">
  <ng-container *ngSwitchCase="dateModes.DAY_MONTH_YEAR">
    <mat-form-field appearance="fill" (click)="datepicker.open()">
      <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
      <div class="mat__datepicker input__wrapper relative">
        <input
          matInput
          [matDatepicker]="datepicker"
          [formControl]="control"
          [required]="field?.required"
          [min]="field?.minDate"
          [max]="field?.maxDate"
        />
        <mat-datepicker-toggle matSuffix class="input__custom-suffix" [for]="datepicker">
          <i matDatepickerToggleIcon *ngIf="field?.icon" [ngClass]="field?.icon"></i>
        </mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </div>
      <mat-error *ngIf="field?.errors?.length">
        <ng-container *ngFor="let error of field.errors">
          <ng-template [ngIf]="control.hasError(error.type)">
            {{ error.message }}
          </ng-template>
        </ng-container>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="dateModes.MONTH_YEAR">
    <favie-datepicker-month-year
      [field]="field"
      [control]="control"
    ></favie-datepicker-month-year>
  </ng-container>

  <ng-container *ngSwitchCase="dateModes.YEAR">
    <favie-datepicker-year
      [field]="field"
      [control]="control"
    ></favie-datepicker-year>
  </ng-container>
</ng-container>
