<section class="favie-textarea-editor__section" (click)="onClick()" (focusout)="onFocusOut()" [ngClass]='isInvalid ? "invalid" : "valid" '>
  <favie-label *ngIf="field?.labelOutSide" [label]="field?.labelOutSide" [required]="field?.required"></favie-label>
  <div class="favie-textarea-editor__content">
    <favie-label [class.focused]="isFocus" [label]="field?.label" [required]="field?.required"></favie-label>
    <favie-ckeditor5 [control]="control"
                     [config]="editorConfig"
                     [maxLength]="field.maxLength">
    </favie-ckeditor5>
    <span class="numb-character m__l--16" *ngIf="field.maxLength">{{pureValue.length}}/{{field.maxLength}}</span>
  </div>
  <mat-error *ngIf="field?.errors?.length && control.touched">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</section>
