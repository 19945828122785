import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { PriceFormField } from '../favie-form/interfaces/form-field.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'favie-percentage',
  templateUrl: './favie-percentage.component.html',
})
export class FaviePercentageComponent extends BaseComponent {
  @Input() field: PriceFormField;
  @Input() control: FormControl;

  protected onInit() {
    super.onInit();
    this.subscribe(
      this.control.valueChanges.pipe(
        debounceTime(30),
        distinctUntilChanged(),
      ),
      val => {
        if (val > 100) {
          this.control.setValue(100);
        }
      },
    );
  }
}
