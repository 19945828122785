<section class="simple-header-button__section">
  <button (click)="buttonHeader?.action()">
    <span>{{ buttonHeader?.label }}</span>
    <img
      *ngIf="buttonHeader?.image"
      [src]="buttonHeader?.image?.src"
      [alt]="buttonHeader?.image?.alt"
    />
  </button>
</section>
