import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { LandingPageContent } from './interfaces/landing-page-content.interface';

@Component({
  selector: 'favie-landing-page-content',
  templateUrl: './landing-page-content.component.html',
})
export class LandingPageContentComponent extends BaseComponent {

  @Input() content: LandingPageContent;

  onInit() { }

}
