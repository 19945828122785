import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { FormControl } from '@angular/forms';
import { TextareaFormField } from 'src/common/components/favie-form/interfaces/form-field.interface';
import { StringUtil } from '../../utils/string.util';

@Component({
  selector: 'favie-textarea-editor',
  templateUrl: './favie-textarea-editor.component.html',
})
export class FavieTextAreaEditorComponent extends BaseComponent {
  @Input() control: FormControl;
  @Input() field: TextareaFormField;
  @Input() editorConfig: any;

  public characters = 0;
  public isFocus = false;
  public isInvalid = false;

  public pureValue = '';
  private previousValue = '';

  public readonly CK_EDITOR_CONFIG = {
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
      ],
    },
  };
  public onFocusOut(): void {
    this.isFocus = this.control.value ? true : false;
  }

  public onClick(): void {
    this.isFocus = true;
  }

  onInit() {
    this.editorConfig = this.editorConfig || this.CK_EDITOR_CONFIG;
    this.subscribe(
      this.control.valueChanges,
      (value) => {
        const pureValue = StringUtil.removeRichTextFormat(value);
        if (this.field.maxLength && pureValue.length > this.field.maxLength) {
          this.control.setValue(this.previousValue);
        } else {
          this.previousValue = value;
          this.pureValue = pureValue;
        }
        this.isInvalid = this.field.required && !this.pureValue;
      }
    );
  }
}
