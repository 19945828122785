import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { StringUtil } from '../../utils/string.util';

@Component({
  selector: 'favie-ckeditor5',
  templateUrl: './favie-ckeditor5.component.html',
  styleUrls: [
    './favie-ckeditor5.component.scss',
  ],
})
export class FavieCkEditor5Component extends BaseComponent {
  @Input() control: FormControl;
  @Input() labelOutSide: string;
  @Input() config?: any;
  @Input() maxLength = Infinity;

  public editor;
  public editorConfig = null;
  public readonly CK_EDITOR_CONFIG = {
    image: {
      toolbar: [
        'imageTextAlternative',
        '|',
        'imageStyle:full',
        'imageStyle:alignCenter',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        'imageStyle:side',
      ],
      styles: ['full', 'alignCenter', 'alignLeft', 'alignRight', 'side'],
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'alignment',
        'mediaEmbed',
        'undo',
        'redo',
      ],
    },
    language: 'en',
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
      ],
    },
    licenseKey: '',
  };

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
  ) {
    super();
  }

  onInit() {
    if (this.isBrowser()) {
      const editor = require('./ckeditor5/ckeditor');
      this.editor = editor;
    }
    this.editorConfig = this.config || this.CK_EDITOR_CONFIG;
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  public onReadyEditor(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader);
    };

    const clipboardPlugin = editor.plugins.get('Clipboard');
    const editingView = editor.editing.view;
    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly) {
        return;
      }
      const dataTransfer = data.dataTransfer;
      if (!dataTransfer) {
        return;
      }
      let content = StringUtil.plainTextToHtml(dataTransfer.getData('text/plain'));
      content = StringUtil.removeRichTextFormat(content);
      let availableLength = this.maxLength - StringUtil.removeRichTextFormat(editor.getData()).length;
      if (availableLength < 0) {
        availableLength = 0;
      }
      if (content.length > availableLength) {
        content = content.substr(0, availableLength);
      }
      content = clipboardPlugin._htmlDataProcessor.toView(content);
      clipboardPlugin.fire('inputTransformation', { content, dataTransfer });
      editingView.scrollToTheSelection();
      evt.stop();
    });
  }
}

export class UploadAdapter {
  private loader;

  constructor(loader: any) {
    this.loader = loader;
  }

  public upload(): Promise<any> {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const myReader = new FileReader();
          myReader.onload = (e) => {
            resolve({ default: e.target.result });
          };

          myReader.readAsDataURL(file);
        }),
    );
  }
}
