import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { LocalSpinnerService } from '../../services/local-spinner.service';
import { BaseComponent } from '../base-component/base.component';
import { FavieImageTemp } from '../upload-pictures/upload-pictures.component';
import { FormFieldInputType } from './enums/form-field-input-type.enum';
import { FormFieldType } from './enums/form-field-type.enum';
import { FormField, InputFormField } from './interfaces/form-field.interface';
import { FormInput } from './interfaces/form-input.interface';
import { FavieDynamicSectionComponent } from '../favie-dynamic-section/favie-dynamic-section.component';

@Component({
  selector: 'favie-form',
  templateUrl: './favie-form.component.html',
  styleUrls: ['./favie-form.component.scss'],
})
export class FavieFormComponent extends BaseComponent implements OnChanges {
  @Input() contentTemplate: TemplateRef<any>;

  @Input() formInput: FormInput;
  @Input() formInitValues: any;

  @Output() primaryEmitter = new EventEmitter<any>();
  @Output() secondaryEmitter = new EventEmitter<any>();

  @ViewChildren('dynamicSectionComponent') dynamicSectionComponents!: QueryList<FavieDynamicSectionComponent>;

  form: FormGroup;
  formFieldType = FormFieldType;
  formFieldInputType = FormFieldInputType;

  isPrimaryLoading = false;

  constructor(
    private readonly location: Location,
    private readonly formBuilder: FormBuilder,
    private readonly localSpinnerService: LocalSpinnerService
  ) {
    super();
  }

  public getValue() {
    const formValue = this.form.value;
    if (!this.formInput.isDynamic) {
      return formValue;
    }
    const containerFieldTypes = [FormFieldType.DYNAMIC_SECTION];
    const templateFieldTypes = [FormFieldType.ROW, FormFieldType.SECTION_HEADER];
    let dynamicSectionComponentIndex = 0;
    const allFields = this.getAllField(this.formInput as FormField).filter(f => !templateFieldTypes.includes(f.type));
    const formValueWithId = allFields.map((field) => {
      if (containerFieldTypes.includes(field.type)) {
        const componentRef = this.dynamicSectionComponents.toArray()[dynamicSectionComponentIndex];
        const { valueId, values, valueKey, formFieldId } = componentRef.getValue();
        dynamicSectionComponentIndex += 1;
        return { valueId, values, valueKey, formFieldId };
      } else {
        return {
          valueId: field.valueId,
          value: formValue[field.key],
          valueKey: field.key,
          formFieldId: field.formFieldId,
        };
      }
    });
    return formValueWithId;
  }

  private getAllField(formField: FormField): FormField[] {
    if (formField.fields) {
      const subFields = formField.fields
        .map(f => this.getAllField(f))
        .reduce((a, b) => [...a, ...b], []);
      return [formField, ...subFields];
    }
    return [formField];
  }

  public onPrimaryButtonClick(): void {
    if (this.isPrimaryDisabled()) {
      return;
    }
    const value = this.getValue();
    this.transformOutput(value);
    this.primaryEmitter.next(value);
  }

  public onSecondaryButtonClick(): void {
    this.secondaryEmitter.next();
  }

  public goBack() {
    this.location.back();
  }

  public clearFile(formControl) {
    formControl.setValue(undefined);
  }

  public setImagesChange(images: FavieImageTemp[], formControl) {
    formControl.setValue(images);
  }

  public isPrimaryDisabled() {
    return (
      this.isPrimaryLoading ||
      (!this.formInput.skipCheckInvalidForm && this.form.invalid)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initForm();
    this.initFormValues();
  }

  onInit() {
    this.initForm();
    this.initFormValues();
  }

  onAfterViewInit() {
    this.initLocalSpinner();
  }

  private initForm() {
    if (!this.formInput) {
      return;
    }
    const buildControlsConfig = (fields: FormField[]) => {
      const skipTypes = [FormFieldType.SECTION_HEADER];
      const config = fields
        .filter(v => !skipTypes.includes(v.type))
        .reduce((previousValue, currentValue) => {
          if (currentValue.type === FormFieldType.ROW) {
            const innerConfig = buildControlsConfig(currentValue.fields);
            const fullConfig = {
              ...previousValue,
              ...innerConfig,
            };
            return fullConfig;
          }

          previousValue[currentValue.key] = [
            {
              value: currentValue.initValue,
              disabled: currentValue.disabled || false,
            },
            currentValue.validators,
          ];

          return previousValue;
        }, {});
      return config;
    };

    const controlsConfig = buildControlsConfig(this.formInput.fields);
    this.form = this.formBuilder.group(controlsConfig);

    if (this.formInput.valueChanges) {
      this.initPipe(
        this.form.valueChanges,
        this.formInput.valueChangesPipes
      ).subscribe((value) => this.formInput.valueChanges(value, this.form));
    }
    if (this.formInput.statusChanges) {
      this.initPipe(
        this.form.statusChanges,
        this.formInput.statusChangesPipes
      ).subscribe((status) => this.formInput.statusChanges(status, this.form));
    }

    this.initFormFields(this.formInput.fields);
  }

  private initFormFields(fields: FormField[]) {
    if (!fields?.length) {
      return;
    }
    fields.forEach((field: FormField) => this.initFormField(field));
  }

  private initFormField(field: FormField) {
    const control = this.form.controls[field.key];
    if (field.valueChanges) {
      this.initPipe(
        control.valueChanges,
        field.valueChangesPipes
      ).subscribe((value) => field.valueChanges(value, this.form));
    }
    if (field.statusChanges) {
      this.initPipe(
        control.statusChanges,
        field.statusChangesPipes
      ).subscribe((status) => field.statusChanges(status, this.form));
    }

    if (field.fields) {
      this.initFormFields(field.fields);
    }
  }

  private initLocalSpinner() {
    if (this.formInput?.primaryLocalSpinnerId) {
      this.localSpinnerService
        .getSpinnerSubject(this.formInput.primaryLocalSpinnerId)
        .subscribe((loading: boolean) => (this.isPrimaryLoading = loading));
    }
  }

  private initPipe(
    observable: Observable<any>,
    valueChangesPipes: OperatorFunction<any, any>[]
  ) {
    if (!valueChangesPipes || valueChangesPipes.length === 0) {
      return observable;
    }
    if (valueChangesPipes.length === 1) {
      return observable.pipe(valueChangesPipes[0]);
    }
    if (valueChangesPipes.length === 2) {
      return observable.pipe(valueChangesPipes[0], valueChangesPipes[1]);
    }
    if (valueChangesPipes.length === 3) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2]
      );
    }
    if (valueChangesPipes.length === 4) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3]
      );
    }
    if (valueChangesPipes.length === 5) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4]
      );
    }
    if (valueChangesPipes.length === 6) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4],
        valueChangesPipes[5]
      );
    }
    if (valueChangesPipes.length === 7) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4],
        valueChangesPipes[5],
        valueChangesPipes[6]
      );
    }
    if (valueChangesPipes.length === 8) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4],
        valueChangesPipes[5],
        valueChangesPipes[6],
        valueChangesPipes[7]
      );
    }
    if (valueChangesPipes.length === 9) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4],
        valueChangesPipes[5],
        valueChangesPipes[6],
        valueChangesPipes[7],
        valueChangesPipes[8]
      );
    }
    if (valueChangesPipes.length > 9) {
      return observable.pipe(
        valueChangesPipes[0],
        valueChangesPipes[1],
        valueChangesPipes[2],
        valueChangesPipes[3],
        valueChangesPipes[4],
        valueChangesPipes[5],
        valueChangesPipes[6],
        valueChangesPipes[7],
        valueChangesPipes[8],
        ...valueChangesPipes.slice(9)
      );
    }
  }

  private initFormValues() {
    if (!this.form || !this.formInitValues) {
      return;
    }
    this.form.patchValue(this.formInitValues);
  }

  private transformOutput(data: any) {
    const transformImplement = (field: FormField) => {
      if (field.type === FormFieldType.INPUT) {
        if ((field as InputFormField).inputType === FormFieldInputType.NUMBER) {
          const key = field.key;
          const value = data[key];
          if (!value) {
            return;
          }
          const valueAsNumber = Number(value);
          data[key] = valueAsNumber;
        }
      } else if (field.type === FormFieldType.ROW) {
        field.fields.forEach((childField: FormField) => {
          transformImplement(childField);
        });
      }
    };
    this.formInput.fields.forEach((field) => {
      transformImplement(field);
    });
  }

  public fieldTracker(item: FormField, index: number) {
    return item.key;
  }
}
