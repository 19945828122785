<section class="user-menu__section">
  <div
    class="user-menu__display clickable"
    fxLayout
    fxLayoutAlign="center center"
    fxFlexAlign="center"
    (click)="toggleUserMenu()"
  >
    <i class="user-icon icon-user-circle icon-sidebar-profile"></i>
    <span class="user-name m__h--8">
      {{ displayName }}
    </span>
    <a>
      <i class="menu-toggle-icon icon-arrow-drop-down"></i>
    </a>
  </div>

  <div class="toggle-secondary-menu-user" *ngIf="displayUserMenu">
    <ul>
      <li class="item" *ngFor="let menuItem of menuItems">
        <a (click)="menuItem.action()">
          <i
            *ngIf="menuItem.icon"
            class="item-icon"
            [class]="menuItem.icon"
          ></i>
          <span class="item-label m__l--8">{{ menuItem.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</section>
