import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FooterGroup, HeaderExpandPanel1, HeaderExpandPanel2, HeaderSectionItem } from 'src/app/enum/enum';
import { FooterItem, MenuItem } from 'src/app/share/models/all.models';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { SectionHeader } from 'src/common/components/header/components/simple-header-items/simple-header-items.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})

export class HeaderMobileComponent extends BaseComponent {
  @Output() closePanel = new EventEmitter<any>();

  public header: MenuItem[];
  public sectionsHeader: SectionHeader[];
  public id: string;
  public footer: FooterItem[];
  public opacityFooter = 1;
  public tabColor = '#454560';
  public tabColorSelected = '#6191D8';

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  public handleSectionHeader( antherLink?: boolean, routerLink?: string) {
    if (antherLink === true){
      window.location.href = 'https://esp-aceurope.woisa.net/connexion.html';
    }else{
      this.router.navigate([routerLink]);
      this.closeMenu();
    }
  }

  public onClick(item: MenuItem) {
    this.header.map((v) => {
      if (v.id === item.id) {
        v.isSelected = !v.isSelected;
      } else {
        v.isSelected = false;
      }
    });

    // const selectedItem = this.header.find((i) => i.id === item.id);
    // selectedItem.action(item.isToggle);

  }

  public onToggle(isOpen: boolean) {
    if (isOpen === true){
      this.opacityFooter = 0;
    }
    else {
      this.opacityFooter = 1;
    }
  }

  onInit(): void {
    this.initDataHeader();
  }

  private initDataHeader() {
    this.header = this.getHeaderMobile();
    this.footer = this.getFooterMobile();
  }

  // Footer
  private getFooterMobile() {
    const footer = [
      {
        id: FooterGroup.LIENS,
        label: this.translateService.instant('Footer.link.title'),
        sections: [
          {
            id: FooterGroup.LIENS1,
            label: this.translateService.instant('Footer.link.section1'),
            action: () => this.router.navigate(['domiciliation/useful-links']),
          },
          {
            id: FooterGroup.LIENS1,
            label: this.translateService.instant('Footer.link.section2'),
            action: () => this.router.navigate(['domiciliation/institutional-links']),
          }
        ]
      },
      {
        id: FooterGroup.RESTROUVEZ,
        label: this.translateService.instant('Footer.social.title'),
      }
    ];
    return footer;
  }

  private getHeaderMobile() {
    const header = [
      {
        id: HeaderSectionItem.FORMATIONS,
        label: this.translateService.instant('Formations'),
        sections: [
          {
            id: HeaderExpandPanel1.IMMOBILIER,
            label: this.translateService.instant('Header.panel1.immobilier'),
            action: () => this.handleSectionHeader(false, 'formation/immobilier')
          },
          {
            id: HeaderExpandPanel1.UNIVERDULUXE,
            label: this.translateService.instant('Header.panel1.univers'),
            action: () => this.handleSectionHeader(false, 'formation/univers-luxe')
          },
          {
            id: HeaderExpandPanel1.LESESSENTIELSDELS,
            label: this.translateService.instant('Header.panel1.les'),
            action: () => this.handleSectionHeader(false, 'formation/essentiels-entreprise')
          },
          {
            id: HeaderExpandPanel1.NOSFORMATIONS,
            label: this.translateService.instant('Header.panel1.nosformations'),
            action: () => this.handleSectionHeader(false, 'formation/nos-formations')
          },
          {
            id: HeaderExpandPanel1.APROSPOS,
            label: this.translateService.instant('Header.panel1.propos'),
            action: () => this.handleSectionHeader(false, 'formation/about')
          },
          {
            id: HeaderExpandPanel1.ZOOM,
            label: this.translateService.instant('Header.Panel1.handicap'),
            action: () => this.handleSectionHeader(false, 'formation/about#handicap')
          },
          {
            id: HeaderExpandPanel1.ZOOM,
            label: this.translateService.instant('Header.Panel1.Zoom'),
            action: () => {
              const url = 'https://firebasestorage.googleapis.com/v0/b/semyoswebsite.appspot.com/o/public-resources%2FZoom%20sur%20nos%20certifications.pdf?alt=media&token=129529d1-f3ce-46cd-869c-d0a02e98ca1b';
              window.open(url, '__blank');
            }
          }
        ],
        isSelected: false,
        // action: (isToggle) => this.handleSectionHeader(isToggle, 'formations')
      },
      {
        id: HeaderSectionItem.DOMICILIATION,
        label: this.translateService.instant('Domiciliation'),
        sections: [
          {
            id: HeaderExpandPanel2.NOSOFFRES,
            label: this.translateService.instant('Header.panel2.offres'),
            action: () => this.handleSectionHeader(false, 'domiciliation/our-offers')
          },
          {
            id: HeaderExpandPanel2.SECRETARIAT,
            label: this.translateService.instant('Header.panel2.secretariat'),
            action: () => this.handleSectionHeader(false, 'domiciliation/our-offers#secretariat')
          },
          {
            id: HeaderExpandPanel2.ESPACE,
            label: this.translateService.instant('Header.panel2.espace'),
            action: () => this.handleSectionHeader(true)
          }
        ],
        isSelected: false,
        // action: (isToggle) => this.handleSectionHeader(isToggle, 'domiciliation')
      },
      {
        id: HeaderSectionItem.LOCATION,
        label: this.translateService.instant('Location'),
        isSelected: false,
        action: () => this.handleSectionHeader(false, 'space-rental')
      },
      {
        id: HeaderSectionItem.BLOG,
        label: this.translateService.instant('Blog'),
        isSelected: false,
        action: () => this.handleSectionHeader(false, 'blog')
      },
      {
        id: HeaderSectionItem.CONTACT,
        label: this.translateService.instant('Contact'),
        isSelected: false,
        action: () => this.handleSectionHeader(false, 'contact')
      }
    ];
    return header;
  }

  private closeMenu() {
    this.closePanel.emit();
  }

}
