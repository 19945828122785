<div class="form-array-container">

  <ng-container *ngFor="let formInput of formInputs; index as i">
    <div class="form" [@inOutAnimation]>
      <favie-section [formInput]='formInput'></favie-section>
      <div class="form-action">
        <div class="btn-delete" (click)="deleteSection(i)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<button (click)="$event.stopPropagation(); $event.preventDefault(); addSection()" [disabled]='isMaximumSection'>
  <mat-icon>add</mat-icon>
  <span>{{ 'Product.Vip.Btn.AddSection' | translate }}</span>
</button>
