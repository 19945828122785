import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FavieImageTemp } from '../upload-pictures/upload-pictures.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'favie-image-upload-or-drag-drop',
  templateUrl: './favie-image-upload-or-drag-drop.html',
  styleUrls: ['./favie-image-upload-or-drag-drop.scss']
})
export class FavieImageUploadOrDragDropComponent extends BaseComponent {
  public files: FavieImageTemp[] = [];
  @Input() control: FormControl;
  @Input() field: any;
  @Input() labelOutSide: string;
  @Output() imagesChange: EventEmitter<FavieImageTemp[]> = new EventEmitter<FavieImageTemp[]>();
  @ViewChild('fileInputElement') fileInputElement: ElementRef;

  public onFileChange(files: FavieImageTemp[]){
    this.files = files;
    this.notifyChange();
  }

  public onUploadedFiles(event: File[] & { target: HTMLInputElement }) {
    const innerFiles = event.target.files;
    const readFile = (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.files.push({
          file,
          src: e.target.result,
        });
        this.imagesChange.emit(this.files);
      };
      reader.readAsDataURL(file);
    };
    readFile(innerFiles[0]);
  }

  public triggerUpload() {
    this.fileInputElement.nativeElement.click();
  }

  public remove(index: number){
    this.files = this.files.filter((value, i) => i !== index);
    this.notifyChange();
  }

  private notifyChange() {
    this.imagesChange.emit(this.files);
  }

}
