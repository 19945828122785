import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
  private _activeItem$ = new BehaviorSubject<any>(undefined);
  private _isExpandSidebar$ = new BehaviorSubject<boolean>(false);
  private _isExpandOnHoverSidebar$ = new Subject<boolean>();
  private _isSidebarVisible = new BehaviorSubject<boolean>(false);

  setActiveItem(item) {
    this._activeItem$.next(item);
  }

  getActiveItem() {
    return this._activeItem$.asObservable();
  }

  expandSidebar(expand = true) {
    this._isExpandSidebar$.next(expand);
  }

  getExpandSidebarObs() {
    return this._isExpandSidebar$.asObservable();
  }

  expandOnHoverSidebar(expand = true) {
    this._isExpandOnHoverSidebar$.next(expand);
  }

  getExpandOnHoverSidebarObs() {
    return this._isExpandOnHoverSidebar$.asObservable();
  }

  setSidebarVisibility(isVisible: boolean) {
    this._isSidebarVisible.next(isVisible);
  }

  isSideBarVisibleObs() {
    return this._isSidebarVisible.asObservable();
  }
}
