import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { FormInput } from 'src/common/components/favie-form/interfaces/form-input.interface';

@Component({
  selector: 'favie-sign-in',
  templateUrl: './favie-sign-in.component.html',
})
export class FavieSignInComponent extends BaseComponent {
  @Input() form: FormInput;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cancelEmitter: EventEmitter<any> = new EventEmitter();

  public formInput: FormInput;

  constructor() {
    super();
  }

  onInit() {
    super.onInit();
    this.initForm();
  }

  onSubmit(data: { email: string, password: string }) {
    this.submitEmitter.next(data);
  }

  onCancel() {
    this.cancelEmitter.next();
  }

  private initForm() {
    this.formInput = this.form;
  }
}
