<section class="favie-auto-complete__section">
  <favie-label *ngIf="field?.labelOutSide"
               [label]="field?.labelOutSide"
               [required]="field?.required">
  </favie-label>

  <mat-form-field appearance="fill">
    <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
    <input matInput
           type="search"
           class="flex--1"
           [matAutocomplete]="auto"
           [formControl]="control"
           [required]="field?.required"
           [placeholder]="field?.placeholder"
           [class.p__r--16]="field?.icon"/>
    <mat-icon *ngIf="field?.icon">{{ field?.icon }}</mat-icon>

    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="selectOption($event)"
                      (opened)="loadNewOptions()">
      <ng-container *ngIf="field?.options?.length">
        <mat-option *ngFor="let option of field.options" [value]="option">
          {{ option.label }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>

    <mat-error *ngIf="field?.errors?.length">
      <ng-container *ngFor="let error of field.errors">
        <ng-template [ngIf]="control.hasError(error.type)">
          {{ error.message }}
        </ng-template>
      </ng-container>
    </mat-error>
  </mat-form-field>
</section>
