import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const mutationSignUp = gql`
  mutation SignUp($input: SignUpRequestType!) {
    signUp(input: $input) {
      statusCode
      message
    }
  }
`;

const mutationSignIn = gql`
  mutation SignIn($input: SignInRequestType!) {
    signIn(input: $input) {
      token
      refreshToken
    }
  }
`;

const mutationForgotPassword = gql`
  mutation forgotPassword($input: ForgotPasswordType!) {
    forgotPassword(input: $input) {
      statusCode
    }
  }
`;

const mutationResetPassword = gql`
  mutation resetPassword($input: ResetPasswordType!) {
    resetPassword(input: $input)
  }
`;

const mutationUpdateConsent = gql`
  mutation updateConsent($input: ConsentType!) {
    updateConsent(type: $input) {
      statusCode
    }
  }
`;

const getConsentByUserId = gql`
  query getConsentByUserId($input: ConsentType!) {
    getConsentByUserId(type: $input) {
      type
      docVersion
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class AuthGqlService {
  constructor(private readonly apollo: Apollo) {}

  public signUp(input) {
    return this.apollo.mutate({
      mutation: mutationSignUp,
      variables: { input },
    });
  }

  public forgotPassword(input) {
    return this.apollo.mutate({
      mutation: mutationForgotPassword,
      variables: { input },
    });
  }


  public resetPassword(input) {
    return this.apollo.mutate({
      mutation: mutationResetPassword,
      variables: { input },
    });
  }

  public updateConsent(type) {
    return this.apollo.mutate({
      mutation: mutationUpdateConsent,
      variables: { input: type },
    });
  }

  public getConsentByUserId(type) {
    return this.apollo.mutate({
      mutation: getConsentByUserId,
      variables: { input: type },
    });
  }
}
