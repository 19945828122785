import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { PasswordFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-password',
  templateUrl: './favie-password.component.html',
})
export class FaviePasswrodComponent extends BaseComponent {
  @Input() field: PasswordFormField;
  @Input() control: FormControl;
}
