import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstant } from 'src/common/constants/common.constant';
import { AuthService } from 'src/common/modules/auth/components/services/auth.service';
import { FavieAuthService } from '../../../../modules/auth/services/favie-auth.service';
import { BaseComponent } from '../../../base-component/base.component';
import { BurgerMenuComponent } from '../burger-menu/burger-menu.component';
import { ButtonHeader } from '../simple-header-button/simple-header-button.component';
import { SectionHeader } from '../simple-header-items/simple-header-items.component';
import { ProfileHeader } from '../simple-header-profile/simple-header-profile.component';
import { IUserMenuItem } from '../user-menu/user-menu.component';

export interface LogoHeader {
  image: {
    src?: string;
    alt?: string;
    width?: string;
    height?: string;
  };
  label?: string;
  routerLink?: string[];
  action?: () => void;
}

export interface ContactHeader {
  email?: string;
  phoneNumber: string;
}

export interface Menu {
  image: {
    src?: string;
    alt?: string;
  };
}

export interface HeaderSub {
  name?: string;
  routerLink?: string;
}

export interface Header {
  menu?: HeaderSub[];
  social?: HeaderSub[];
  policy?: HeaderSub[];
  staySocial?: string;
  user?: {
    firstName?: string;
    lastName?: string;
  };
}

export interface SubButton {
  label?: string;
  image?: {
    src?: string;
    alt?: string;
  };
  action: () => void;
}

@Component({
  selector: 'favie-simple-header',
  templateUrl: './simple-header.component.html',
})
export class SimpleHeaderComponent extends BaseComponent implements OnChanges {
  @ViewChild('burgerMenu') burgerMenu: BurgerMenuComponent;

  @Input() logo: LogoHeader;
  @Input() sections: SectionHeader[];
  @Input() contact: ContactHeader;
  @Input() isCollapsed: boolean;
  @Input() menu: Menu;
  @Input() header: Header;
  @Input() formSignInSignUp;
  @Input() userMenuItems: IUserMenuItem[];
  @Input() subButton: SubButton;
  @Input() buttonHeader: ButtonHeader;
  @Input() profileHeader: ProfileHeader;
  @Input() langSwitcherIconClass: string;

  @Output() toggledBurgerMenu = new EventEmitter();

  isBurgerMenuDisplay = false;
  isLoggedIn = false;
  fullName;
  getRedirectedResult: boolean;

  constructor(
    private readonly favieAuthService: FavieAuthService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly elementRef: ElementRef,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event: any) {
    if (!this.elementRef.nativeElement.contains($event.target)) {
      if (this.isBurgerMenuDisplay) {
        this.isBurgerMenuDisplay = false;
      }
      document.getElementById('client').classList.remove('noscroll');
    }
  }

  onToggleBurgerMenu() {
    this.isBurgerMenuDisplay = !this.isBurgerMenuDisplay;
    if (this.isBurgerMenuDisplay) {
      document.getElementById('client').classList.add('noscroll');
    } else {
      document.getElementById('client').classList.remove('noscroll');
    }
    this.toggledBurgerMenu.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isCollapsed?.currentValue) {
      this.isCollapsed = changes.isCollapsed.currentValue;
      this.isBurgerMenuDisplay = false;
    }
  }

  onInit() {
    this.authService.getRedirectUser().subscribe(
      (userCredential) => {
        this.getRedirectedResult = true;
        if (userCredential && userCredential.user) {
          this.isLoggedIn = true;
          this.authService.handleRedirectUser(userCredential);
        }
      },
      (error) => {
        this.getRedirectedResult = true;
        const msg = error.message || this.translateService.instant('fail');
        this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
      }
    );
    this.subscribe(this.favieAuthService.getLogged(), (isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn) {
        this.fullName = undefined;
        this.favieAuthService.setUserProfile(undefined);
      }
    });
  }

  closeBurgerMenu() {
    if (this.isBurgerMenuDisplay) {
      this.isBurgerMenuDisplay = false;
      document.getElementById('client').classList.remove('noscroll');
    }
  }

  setFullName(fullName: string) {
    if (fullName) {
      this.fullName = fullName;
      this.burgerMenu.setFullName(this.fullName);
    }
  }

  handleLogo() {
    this.closeBurgerMenu();
    if (this.logo.action) {
      return this.logo.action();
    } else {
      return null;
    }
  }
}
