import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CommonConstant } from '../../constants/common.constant';
import { BaseComponent } from '../base-component/base.component';
import { AutoCompleteFormField } from '../favie-form/interfaces/form-field.interface';
import { fromEvent } from 'rxjs';

export interface AutoCompleteOptionPagination {
  keyword?: string;
  paging: {
    pageIndex: number,
    pageSize: number,
  };
}

@Component({
  selector: 'favie-auto-complete',
  templateUrl: './favie-auto-complete.component.html',
})
export class FavieAutoCompleteComponent extends BaseComponent implements OnChanges {
  public searchValue: string;
  public searchValueInfinite: string;
  public paginationConfig: AutoCompleteOptionPagination;

  @Input() field: AutoCompleteFormField;
  @Input() control: FormControl;

  @Output() searchChanged = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  @ViewChild('auto') statesAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor() {
    super();
    this.paginationConfig = {
      paging: {
        pageIndex: CommonConstant.PAGING_PAGE_INDEX,
        pageSize: CommonConstant.PAGING_PAGE_SIZE,
      },
    };
  }

  selectOption(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.next(event);
  }

  displayFn(option: any): string {
    return option && option.label
      ? option.label
      : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control?.currentValue) {
      this.subscribe(
        this.control.valueChanges.pipe(
          debounceTime(CommonConstant.INPUT_DEBOUNCE_INTERVAL),
          distinctUntilChanged(),
        ),
        (searchValue) => {
          if (
            typeof searchValue === 'string' &&
            this.searchValueValidate(searchValue)
          ) {
            this.searchValue = searchValue;
            this.searchChanged.next(searchValue);
            if (this.field.autocompleteOptionGetter) {
              this.loadNewOptions();
            }
          } else {
            if (this.field.initOptions) {
              this.field.options = this.field.initOptions;
            }
          }
        },
      );
    }
  }

  public loadNewOptions() {
    setTimeout(() => {
      if (
        this.statesAutocompleteRef &&
        this.autocompleteTrigger &&
        this.statesAutocompleteRef.panel
      ) {
        fromEvent(this.statesAutocompleteRef.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.statesAutocompleteRef.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions),
          )
          .subscribe(x => {
            const scrollTop = this.statesAutocompleteRef.panel.nativeElement.scrollTop;
            const scrollHeight = this.statesAutocompleteRef.panel.nativeElement.scrollHeight;
            const elementHeight = this.statesAutocompleteRef.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;
            if (atBottom) {
              let searchValue = this.searchValueInfinite;
              if (searchValue !== this.searchValue) {
                searchValue = this.searchValue;
                this.paginationConfig.paging.pageSize = CommonConstant.PAGING_PAGE_SIZE;
              }
              const input: AutoCompleteOptionPagination = {
                keyword: searchValue,
                paging: {
                  pageIndex: this.paginationConfig.paging.pageIndex,
                  pageSize: this.paginationConfig.paging.pageSize + 10,
                },
              };
              this.field.autocompleteOptionGetter(input).subscribe(
                (result) => {
                  if (result?.length > 0) {
                    this.field.options = result;
                    this.searchValueInfinite = input.keyword;
                    this.paginationConfig.paging.pageSize = input.paging.pageSize;
                  }
                },
              );
            }
          });
      }
    });
  }

  private searchValueValidate(searchValue: string) {
    return (
      searchValue &&
      searchValue.trim().length >= (this.field.minLength || CommonConstant.INPUT_DEBOUNCE_THRESH_HOLD)
    );
  }
}
