<section class="simple-header__section d__flex align__items--center">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="simple-header__content full-width"
  >
    <div class="header-container">
      <div class="primary" *ngIf="logo">
        <a [routerLink]="logo?.routerLink" (click)="handleLogo()">
          <img
            class="logo"
            *ngIf="logo?.image && !isCollapsed"
            [src]="logo.image?.src"
            [alt]="logo.image?.alt"
          />
          <img
            [style.width]="logo?.image?.width"
            [style.height]="logo?.image?.height"
            *ngIf="logo?.image && isCollapsed"
            [src]="logo.image?.src"
            [alt]="logo.image?.alt"
          />
          <span class="label" *ngIf="logo?.label">{{ logo.label }}</span>
        </a>
      </div>

      <div class="secondary" fxLayoutAlign="space-between center">
        <ng-container *ngIf="!isCollapsed">
          <ng-template [ngIf]="sections">
            <favie-simple-header-items
              [sections]="sections"
            ></favie-simple-header-items>

            <!-- <div class="third">
              <a
                class="email"
                *ngIf="contact?.email"
                [href]="'mailto:' + contact.email"
              >
                {{ contact.email }}
              </a>
              <a
                class="phone"
                *ngIf="contact?.phoneNumber"
                [href]="'tel:+' + contact.phoneNumber"
              >
                {{ contact.phoneNumber }}
              </a>
            </div> -->
          </ng-template>

          <div class="sub__button" *ngIf="subButton">
            <button
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
              (click)="subButton.action()"
            >
              <img [src]="subButton?.image.src" />
              <span>{{ subButton?.label }}</span>
            </button>
          </div>
        </ng-container>
      </div>

      <div
        class="favie__menu--toggle"
        [class.d__none]="!isCollapsed"
        (click)="onToggleBurgerMenu()"
      >
        <!-- <button  mat-icon-button [class.d__none]="!isCollapsed" (click)="onToggleBurgerMenu()"> -->
        <!-- <mat-icon>menu</mat-icon> -->
        <img
          class="favie-user__menu"
          *ngIf="menu?.image"
          [src]="menu?.image.src"
          [alt]="menu?.image.alt"
        />
        <!-- </button> -->
      </div>

      <favie-burger-menu
        (closeEmitter)="closeBurgerMenu()"
        [class.d__none]="!(isCollapsed && isBurgerMenuDisplay)"
        [formSignInSignUp]="formSignInSignUp"
        [sections]="sections"
        [header]="header"
        #burgerMenu
      >
      </favie-burger-menu>

      <div
        [class.d__none]="isCollapsed"
        class="user-actions favie__menu--secondary"
        *ngIf="getRedirectedResult && formSignInSignUp"
      >
        <div
          class="user-actions-box"
          fxLayout
          fxLayoutAlign="center center"
          fxFlexAlign="center"
        >
          <favie-sign-in-button
            *ngIf="!isLoggedIn"
            [form]="formSignInSignUp"
          ></favie-sign-in-button>
          <favie-user-menu
            *ngIf="isLoggedIn"
            [displayName]="fullName"
            [menuItems]="userMenuItems"
          ></favie-user-menu>
        </div>
      </div>

      <div [class.d__none]="isCollapsed">
        <favie-language-switcher
          [iconClass]="langSwitcherIconClass"
        ></favie-language-switcher>
      </div>

      <ng-template [ngIf]="profileHeader && !isCollapsed">
        <favie-simple-header-profile
          [profileHeader]="profileHeader"
        ></favie-simple-header-profile>
      </ng-template>
    </div>

    <favie-simple-header-button
      *ngIf="buttonHeader && !isCollapsed"
      [buttonHeader]="buttonHeader"
    ></favie-simple-header-button>
  </div>
</section>
