import { NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserStorage } from '../common/storages/browser.storage';
import { STORAGE } from '../common/storages/storages.inject';
import { TranslateModule } from '../common/translate/translate.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import initLanguageProvider from './share/services/init-language';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    TranslateModule,
  ],
  providers: [
    { provide: STORAGE, useClass: BrowserStorage },
    initLanguageProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
