export class StringUtil {
  public static removeRichTextFormat(value: string): string {
    return this.removeHtmlTags(value).replace(/&nbsp;/g, ' ');
  }

  public static removeHtmlTags(value: string): string {
    const rex = /(<([^>]+)>)/ig;
    return value.replace(rex, '');
  }

  public static plainTextToHtml( text ) {
    text = text
      // Encode <>.
      .replace( /</g, '&lt;' )
      .replace( />/g, '&gt;' )
      // Creates a paragraph for each double line break.
      .replace( /\r?\n\r?\n/g, '</p><p>' )
      // Creates a line break for each single line break.
      .replace( /\r?\n/g, '<br>' )
      // Preserve trailing spaces (only the first and last one – the rest is handled below).
      .replace( /^\s/, '&nbsp;' )
      .replace( /\s$/, '&nbsp;' )
      // Preserve other subsequent spaces now.
      .replace( /\s\s/g, ' &nbsp;' );

    if ( text.includes( '</p><p>' ) || text.includes( '<br>' ) ) {
      // If we created paragraphs above, add the trailing ones.
      text = `<p>${ text }</p>`;
    }

    return text;
  }

  public static isUrl(text: string): boolean {
    try {
      // tslint:disable-next-line: no-unused-expression
      new URL(text);
      return true;
    } catch (error) {
      return false;
    }
  }
}
