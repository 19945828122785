import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {
  FavieBreadCrumbComponent,
  BreadcrumbOption,
} from './favie-breadcrumb.component';
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'favie-breadcrumb-item',
  exportAs: 'favieBreadcrumbItem',
  preserveWhitespaces: false,
  template: `
    <span class="favie-breadcrumb-link">
      <a
        *ngIf="breadcrumb?.url; else nonUrl"
        [attr.href]="breadcrumb.url"
        (click)="navigate(breadcrumb.url, $event)"
        >{{ breadcrumb.label }}</a
      >
      <ng-template #nonUrl
        ><span>{{ breadcrumb.label }}</span></ng-template
      >
    </span>

    <span class="favie-breadcrumb-separator">
      <i
        [class]="favieBreadCrumbComponent.separator"
        *ngIf="favieBreadCrumbComponent?.separator; else defaultSeparator"
      ></i>
      <ng-template #defaultSeparator>/</ng-template>
    </span>
  `,
})
export class FavieBreadCrumbItemComponent {
  @Input() breadcrumb: BreadcrumbOption;
  constructor(
    public favieBreadCrumbComponent: FavieBreadCrumbComponent,
    private readonly router: Router
  ) {}

  navigate(url: string, e: MouseEvent): void {
    e.preventDefault();
    this.router.navigateByUrl(url);
  }
}
