<div>
  <div
    class="social-auth-section-wrapper"
    fxLayout="column" fxLayoutAlign="center center"
  >
    <div class="title">
      <span> {{ socialLabel }} </span>
    </div>
    <div
      class="social-auth-section d__flex full-width m__b--32"
      fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.lt-md="8px"
    >
      <button class="button button--facebook" *ngIf="icon.facebook" (click)="handleFacebook()">
        <i class="{{ icon.facebook }}"></i>
      </button>
      <button class="button button--google" *ngIf="icon.gmail" (click)="handleGoogle()">
        <img src="{{ icon.gmail }}" />
      </button>
      <button class="button button--apple"  *ngIf="icon.apple" (click)="handleApple()">
        <i class="{{ icon.apple }}"></i>
      </button>
    </div>
    <div class="title">
      <span> {{ localLabel }} </span>
    </div>
  </div>
</div>
