import { Injectable } from '@angular/core';

export interface CountryType {
  value: string;
  label: string;
}

@Injectable({ providedIn: 'root' })
export class CountryService {
  private countries: CountryType[];

  public getCountries() {
    if (!this.countries) {
      const importCountries = require('../jsons/countries.json');
      this.countries = importCountries.map((country) => ({
        value: country.code,
        label: country.name,
      }));
    }
    return this.countries;
  }

  public getDialCodeCountries() {
    if (!this.countries) {
      const importCountries = require('../jsons/countries.json');
      this.countries = importCountries.map((country) => ({
        value: country.dial_code,
        label: country.code,
      })).sort((a, b) => ('' + a.label).localeCompare(b.label));
    }
    return this.countries;
  }
}
