import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryService, CountryType } from '../../services/country.service';
import { isValidPhoneNumber, splitPhoneNumber } from '../../utils/phone.util';
import { BaseComponent } from '../base-component/base.component';
import { FormFieldInputType } from '../favie-form/enums/form-field-input-type.enum';
import { FormFieldType } from '../favie-form/enums/form-field-type.enum';
import { FavieFormComponent } from '../favie-form/favie-form.component';
import { PhoneFormField } from '../favie-form/interfaces/form-field.interface';
import { FormInput } from '../favie-form/interfaces/form-input.interface';

@Component({
  selector: 'favie-phone',
  templateUrl: './favie-phone.component.html',
  styleUrls: ['./favie-phone.component.scss'],
})
export class FaviePhoneComponent extends BaseComponent {
  @Input() field: PhoneFormField;
  @Input() control: FormControl;

  @ViewChild('formComponent') formComponent: FavieFormComponent;
  public formInput: FormInput;
  public countryPrefixes: CountryType[];

  private internalForm: FormGroup;

  constructor(
    private readonly countryService: CountryService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  protected onInit() {
    super.onInit();
    this.initFormInput();
  }

  protected onAfterViewInit() {
    super.onAfterViewInit();
    this.internalForm = this.formComponent.form;
  }

  private initFormInput() {
    this.countryPrefixes = this.countryService.getDialCodeCountries();
    const { phoneNumberPrefix, phoneNumber } = this.splitPhoneNumber(this.field.initValue);
    const onSelectPrefix = (value: any, form?: FormGroup) => this.emitPhoneNumberAndValidate(form, this.buildPhoneNumber(form));
    const onInputNumber = (value: any, form?: FormGroup) => this.emitPhoneNumberAndValidate(form, this.buildPhoneNumber(form));
    this.formInput = {
      fields: [
        {
          key: 'row',
          type: FormFieldType.ROW,
          fields: [
            {
              key: 'phoneNumberPrefix',
              type: FormFieldType.DROPDOWN,
              required: this.field.required,
              options: this.countryPrefixes,
              classes: 'country-prefix',
              initValue: phoneNumberPrefix,
              valueChanges: onSelectPrefix,
            },
            {
              key: 'phoneNumber',
              type: FormFieldType.INPUT,
              inputType: FormFieldInputType.NUMBER,
              label: this.field.label,
              placeholder: this.field.placeholder,
              required: this.field.required,
              initValue: phoneNumber,
              valueChanges: onInputNumber,
              errors: [
                {
                  type: 'required',
                  message: this.translateService.instant('Global.Phone.Error'),
                },
                {
                  type: 'isValid',
                  message: this.translateService.instant('Global.InvalidPhone'),
                },
              ],
            },
          ],
        },
      ],
    };
    this.initValuePatcher();
  }

  private initValuePatcher() {
    this.subscribe(
      this.control.valueChanges,
      value => {
        if (this.internalForm && value !== this.buildPhoneNumber(this.internalForm)) {
          const { phoneNumberPrefix, phoneNumber } = this.splitPhoneNumber(value);
          this.internalForm.setValue({ phoneNumberPrefix, phoneNumber });
          this.validate(this.internalForm);
        }
      },
    );
  }

  private splitPhoneNumber(phone: string): { phoneNumberPrefix, phoneNumber } {
    try {
      return splitPhoneNumber(phone);
    } catch (e) {
      const phonePrefixes = this.countryPrefixes.map(p => p.value);
      const isDefaultDialCodeValid = phonePrefixes.some(p => p === this.field.defaultDialCode);
      return {
        phoneNumberPrefix: isDefaultDialCodeValid ? this.field.defaultDialCode : '',
        phoneNumber: '',
      };
    }
  }

  private buildPhoneNumber(form: FormGroup): string {
    const { phoneNumberPrefix, phoneNumber } = form.getRawValue();
    return phoneNumberPrefix + phoneNumber;
  }

  private emitPhoneNumberAndValidate(form: FormGroup, phone: string) {
    this.control.setValue(phone);
    this.control.markAsDirty();
    this.validate(form);
  }

  private validate(form: FormGroup) {
    const completePhone = this.buildPhoneNumber(form);
    let errors = form.controls.phoneNumber.errors;
    if (!isValidPhoneNumber(completePhone)) {
      if (!errors) {
        errors = {};
      }
      errors.isValid = this.translateService.instant('Global.InvalidPhone');
      form.controls.phoneNumber.setErrors(errors);
    } else {
      if (errors && errors.isValid) {
        delete errors.isValid;
      }
      form.controls.phoneNumber.setErrors(errors);
    }
  }
}
