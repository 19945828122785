<section
  class="favie-content-column-field__section d__flex justify__content--between align__items--start"
>
  <ng-template [ngIf]="fields && fields.length">
    <div class="content-column-field-item" *ngFor="let field of fields">
      <div class="label">
        <span>{{ field.label }}</span>
      </div>

      <div class="value">
        <span>
          {{ field.value || "" }}
        </span>
      </div>
    </div>
  </ng-template>
</section>
