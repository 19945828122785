import { CommonConstant } from '../constants/common.constant';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isValidPhoneNumber(phoneNumber: string) {
  const lib = require('libphonenumber-js');
  if (lib) {
    try {
      const pn = lib.parsePhoneNumber(phoneNumber);
      return pn.isValid();
    } catch (e) {
      return false;
    }
  } else {
    return CommonConstant.PHONE_NUMBER_REGEX.test(phoneNumber);
  }
}

export function getPhoneNumberValidator(errorKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = control.value;
    const isValid = isValidPhoneNumber(phoneNumber);
    if (!isValid) {
      return {
        [errorKey]: `${ control.value } is not a valid phone number`,
      };
    }
    return null;
  };
}

export function splitPhoneNumber(phone: string): { phoneNumberPrefix, phoneNumber } {
  let phoneNumberPrefix = '';
  let phoneNumber = '';
  const lib = require('libphonenumber-js');
  if (lib) {
    try {
      const pn = lib.parsePhoneNumber(phone);
      phoneNumberPrefix = '+' + pn.countryCallingCode;
      phoneNumber = pn.nationalNumber;
    } catch (e) {
      throw e;
    }
  } else {
    const importCountries = require('../jsons/countries.json');
    const phonePrefixes = importCountries.map((country) => country.dial_code);
    phone = phone ? ('' + phone).trim() : '';
    if (phone) {
      phoneNumberPrefix = phonePrefixes.find(p => phone.startsWith(p));
    }
    if (!phoneNumberPrefix) {
      throw new Error('Phone number does not match any dial code');
    }
    phoneNumber = phone.substring(phoneNumberPrefix.length);
  }
  return { phoneNumberPrefix, phoneNumber };
}
