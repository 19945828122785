import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const InterceptorIgnoreLanguage = 'X-Ignore-Language-Interceptor';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private readonly translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const ignore = request.headers.has(InterceptorIgnoreLanguage);
    let headers = request.headers;
    headers = request.headers.delete(InterceptorIgnoreLanguage);

    if (!ignore && this.translateService.getDefaultLang()) {
      headers = headers.set('language', this.translateService.getDefaultLang());
    }
    const changedRequest = request.clone({ headers });

    return next.handle(changedRequest);
  }
}
