import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { BaseComponent } from '../base-component/base.component';
import { FavieStepperActionInput } from './favie-stepper-action-input.interface';

@Component({
  selector: 'favie-stepper-action',
  templateUrl: './favie-stepper-action.component.html',
})
export class FavieStepperActionComponent extends BaseComponent {
  @Input()
  public stepper: MatStepper;
  @Input()
  public input: FavieStepperActionInput;

  @Output()
  public submitted = new EventEmitter();
  @Output()
  public cancelled = new EventEmitter();

  public get isSubmitVisible(): boolean {
    if (!this.stepper) { return false; }
    return this.stepper.selectedIndex === this.stepper.steps.length - 1;
  }
  public get isNextStepVisible(): boolean {
    return !this.isSubmitVisible;
  }

  public get isPreviousStepVisible(): boolean {
    return !this.isCancelVisible;
  }

  public get isCancelVisible(): boolean {
    if (!this.stepper) { return false; }
    return this.stepper.selectedIndex === 0;
  }

  public get isSubmitDisabled(): boolean {
    return this.isNextStepDisabled;
  }

  public get isNextStepDisabled(): boolean {
    if (!this.stepper) { return false; }
    return !this.stepper.selected.completed;
  }

  public submit() {
    this.submitted.next();
  }

  public cancel() {
    this.cancelled.next();
  }

  public nextStep() {
    this.stepper.next();
  }

  public previousStep() {
    this.stepper.previous();
  }
}
