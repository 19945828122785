import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { InfoPanelInput } from './interfaces/info-panel-input.interface';

@Component({
  selector: 'favie-info-panel',
  templateUrl: './favie-info-panel.component.html',
  styleUrls: ['./favie-info-panel.component.scss'],
})
export class FavieInfoPanelComponent extends BaseComponent {
  @Input()
  public readonly info: InfoPanelInput;
}
