import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FavieContentColumnData } from './favie-content-column-data.interface';

@Component({
  selector: 'favie-content-column',
  templateUrl: './favie-content-column.component.html',
})
export class FavieContentColumnComponent extends BaseComponent {
  @Input()
  public value: FavieContentColumnData[];
}
