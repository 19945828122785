import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FavieAuthService } from '../../../../modules/auth/services/favie-auth.service';
import { BaseComponent } from '../../../base-component/base.component';
import { SimpleHeaderSecondary } from './simple-header-secondary.interface';

@Component({
  selector: 'favie-simple-header-secondary',
  templateUrl: './simple-header-secondary.component.html',
})
export class SimpleHeaderSecondaryComponent extends BaseComponent implements OnChanges {
  @Input() headerSecondary: SimpleHeaderSecondary;
  @Input() formSignInSignUp;

  public profilePicture;
  public displayMenu = false;
  public isLoggedIn = false;

  constructor(private favieAuthService: FavieAuthService) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  clickedOutside(element: HTMLElement) {
    if (!element.classList.contains('click-drop-down-menu')) {
      this.displayMenu = false;
    }
  }

  openMenu() {
    this.displayMenu = !this.displayMenu;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headerSecondary) {
      this.initProfilePicture();
    }
  }

  onInit() {
    this.subscribe(this.favieAuthService.getLogged(), (isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  private initProfilePicture() {
    this.profilePicture = this.headerSecondary?.user?.image?.src
      ? this.headerSecondary?.user?.image?.src
      : this.headerSecondary?.user?.image?.default?.src;
  }
}
