import { Location } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface Section {
  sub_button: {
    image: {
      src?: string,
      alt?: string,
      width?: string,
      height?: string
    },
    label?: string,
    back?: boolean
    customGoBack?: boolean
  };
  title?: string;
  sub_title?: string;
}

@Component({
  selector: 'favie-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent extends BaseComponent {
  @Input() section: Section;

  @Output() handleEmitter = new EventEmitter<any>();

  constructor(
    private readonly location: Location
  ) {
    super();
  }

  handleSubButton() {
    if (!this.section?.sub_button) {
      return;
    }
    if (this.section?.sub_button?.back) {
      if (this.section?.sub_button?.customGoBack)
        this.handleEmitter.next();
      else {
        this.location.back();
      }
      return;
    }
    this.handleEmitter.next();
  }
}
