import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../components/base-component/base.component';
import { HeaderService } from '../../components/header/services/header.service';
import { SidebarItem } from '../../components/sidebar/sidebar-item.interface';
import { SidebarLogo } from '../../components/sidebar/sidebar-logo.interface';
import { SidebarService } from '../../components/sidebar/sidebar.service';
import { Layouts, ResponsiveLayoutService } from '../../services/responsive-layout.service';

@Component({
  selector: 'favie-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent extends BaseComponent {
  @Input() sidebarLogo: SidebarLogo;
  @Input() sidebarItems: SidebarItem[];

  public isHeaderVisible = false;

  showSidebar = true;
  isExpandSidebar = true;
  shouldExpandSidebarOnHover: boolean;

  layouts = Layouts;
  currentLayout: Layouts;

  constructor(
    private readonly headerService: HeaderService,
    private sidebarService: SidebarService,
    private responsiveLayoutService: ResponsiveLayoutService
  ) {
    super();
  }

  onInit() {
    this.initHeader();
    this.initSideBar();
  }

  onMouseHoverSidebar(expand = true) {
    if (this.shouldExpandSidebarOnHover) {
      this.sidebarService.expandOnHoverSidebar(expand);
    }
  }

  private initSideBar() {
    this.subscribe(
      this.sidebarService.isSideBarVisibleObs(),
      (isVisible) => (this.showSidebar = isVisible)
    );

    this.subscribe(
      this.sidebarService.getExpandSidebarObs(),
      (isExpandSidebar) => (this.isExpandSidebar = isExpandSidebar)
    );

    // change sidebar layouts
    this.subscribe(
      this.responsiveLayoutService.getCurrentLayout(),
      (layout) => {
        this.currentLayout = layout;
        switch (layout) {
          case Layouts.MOBILE:
            this.shouldExpandSidebarOnHover = false;
            this.sidebarService.expandSidebar(true);
            break;
          case Layouts.DESKTOP:
            this.shouldExpandSidebarOnHover = true;
            this.sidebarService.expandSidebar(false);
            break;
          case Layouts.DESKTOP_WIDE:
            this.shouldExpandSidebarOnHover = false;
            this.sidebarService.expandSidebar(true);
            break;
          default:
            break;
        }
      }
    );
  }

  private initHeader() {
    this.subscribe(this.headerService.isVisibilityObs(), (isVisible) => {
      this.isHeaderVisible = isVisible;
    });
  }
}
