<section class="favie-form__section form-container">
  <form [formGroup]="form" (ngSubmit)="onPrimaryButtonClick()" *ngIf="form">
    <div
      class="d__flex align__items--center m__b--16"
      *ngIf="formInput?.showBackArrow || formInput?.title"
    >
      <button
        class="m__r--16"
        mat-icon-button
        (click)="goBack()"
        *ngIf="formInput?.showBackArrow"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>

      <h1 class="m--0" *ngIf="formInput?.title">
        {{ formInput?.title }}
      </h1>
    </div>

    <div class="subtitle" *ngIf="formInput?.subtitle">
      <h2>{{ formInput?.subtitle }}</h2>
    </div>

    <div class="summary" *ngIf="formInput?.summary">
      <span>{{ formInput?.summary }}</span>
    </div>

    <div class="form-box">
      <ng-container *ngFor=" let field of formInput?.fields, trackBy: fieldTracker">
        <ng-container *ngIf="!field.hidden">
          <ng-container *ngTemplateOutlet="fieldSelector; context: { field: field }"></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="form-content">
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>

    <div
      class="form-actions"
      fxLayout="row-reverse"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-around center"
      *ngIf="formInput?.primaryButtonLabel || formInput?.secondaryButtonLabel"
    >
      <favie-local-spinner [componentId]="formInput?.primaryLocalSpinnerId">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="isPrimaryDisabled()"
          *ngIf="formInput?.primaryButtonLabel"
        >
          <mat-icon *ngIf="formInput?.primaryButtonMatIcon">
            {{ formInput.primaryButtonMatIcon }}
          </mat-icon>

          <span>
            {{ formInput?.primaryButtonLabel }}
          </span>
        </button>
      </favie-local-spinner>

      <button
        mat-stroked-button
        class="secondary_btn"
        type="button"
        (click)="onSecondaryButtonClick()"
        *ngIf="formInput?.secondaryButtonLabel"
      >
        <mat-icon *ngIf="formInput?.secondaryButtonMatIcon">
          {{ formInput.secondaryButtonMatIcon }}
        </mat-icon>

        <span>
          {{ formInput?.secondaryButtonLabel }}
        </span>
      </button>
    </div>
  </form>
</section>

<ng-template #fieldSelector let-field="field">
  <ng-container [ngSwitch]="field?.type">
    <div
      class="form__row"
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.ROW"
    >
      <ng-container
        *ngFor="
          let fieldChild of field?.fields
            | filter: { hidden: [undefined, false] }
        "
      >
        <ng-container
          *ngTemplateOutlet="fieldSelector; context: { field: fieldChild }"
        ></ng-container>
      </ng-container>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.SECTION_HEADER"
    >
      <span>{{ field?.label }}</span>
    </div>

    <div [ngClass]="field?.classes || ''" *ngSwitchCase="formFieldType.INPUT">
      <favie-input
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-input>
    </div>

    <div [ngClass]="field?.classes || ''" *ngSwitchCase="formFieldType.PRICE">
      <favie-price
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-price>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.PASSWORD"
    >
      <favie-password
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-password>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.TEXTAREA"
    >
      <favie-textarea
        *ngIf="!field?.isEditor"
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-textarea>
      <favie-textarea-editor
        *ngIf="field?.isEditor"
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-textarea-editor>

    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.CHECKBOX"
    >
      <favie-checkbox
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-checkbox>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.RADIO"
    >
      <favie-radio-button
        [field]="field"
        [control]="form.controls[field.key]"
        [color]="field.color"
      ></favie-radio-button>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.SLIDE_TOGGLE"
    >
      <favie-label
        *ngIf="field?.labelOutSide"
        [label]="field.labelOutSide"
        [required]="field.required"
      ></favie-label>

      <mat-slide-toggle
        color="primary"
        [required]="field.required"
        [formControl]="form.controls[field.key]"
      >
        {{ field.label }}
      </mat-slide-toggle>
    </div>

    <div *ngSwitchCase="formFieldType.SLIDE_TOGGLE_CUSTOM">
      <app-favie-mat-slide-toggle
      [control]="form.controls[field.key]"
      [isDefaultValue]="true">
      </app-favie-mat-slide-toggle>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.DROPDOWN"
      class="type__dropdown"
    >
      <favie-dropdown
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-dropdown>
    </div>

    <div
      class="d__flex flex__direction--column"
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.UPLOAD_FILES"
    >
      <favie-upload-pictures
        *ngIf="formInitValues && formInitValues[field.key]"
        [label]="field?.label"
        [labelOutSide]="field?.labelOutSide"
        [icon]="field?.icon"
        [iconImage]="field?.iconImage"
        [limitImages]="field?.limitImages || 1"
        [images]="formInitValues[field.key] || []"
        (imagesChange)="setImagesChange($event, form.controls[field.key])"
      ></favie-upload-pictures>

      <favie-upload-pictures
        *ngIf="!formInitValues || !formInitValues[field.key]"
        [label]="field?.label"
        [labelOutSide]="field?.labelOutSide"
        [icon]="field?.icon"
        [iconImage]="field?.iconImage"
        [limitImages]="field?.limitImages || 1"
        (imagesChange)="setImagesChange($event, form.controls[field.key])"
      ></favie-upload-pictures>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.DATEPICKER"
    >
      <favie-datepicker
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-datepicker>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.TIMEPICKER"
    >
      <favie-time-picker
        [field]="field"
        [control]="form.controls[field.key]"
      ></favie-time-picker>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.LINK"
      id="favie-link"
      class="p__b--8"
    >
      <a (click)="field?.click()"> {{ field?.label }} </a>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.IMAGE_CROPPER"
    >
      <favie-image-cropper
        [formInitValues]="formInitValues"
        [control]="form.controls[field.key]"
        [field]="field"
        (imageAfterCropper)="setImagesChange($event, form.controls[field.key])"
      ></favie-image-cropper>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.AUTOCOMPLETE"
    >
      <favie-auto-complete
        [field]="field"
        [control]="form.controls[field.key]"
        (searchChanged)="
          field.autocompleteSearchChanged
            ? field.autocompleteSearchChanged($event, field, form)
            : null
        "
        (optionSelected)="
          field.autocompleteOptionSelected
            ? field.autocompleteOptionSelected($event, form)
            : null
        "
      ></favie-auto-complete>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.ADDRESS_AUTOCOMPLETE"
    >
      <favie-address-autocomplete
        [field]="field"
        [control]="form.controls[field.key]"
        (addressChanged)="
          field.addressChanged ? field.addressChanged($event, form) : null
        "
      ></favie-address-autocomplete>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.CKEDITOR5"
    >
      <favie-ckeditor5
        [control]="form.controls[field.key]"
        [labelOutSide]="field.labelOutSide"
      ></favie-ckeditor5>
    </div>

    <div [ngClass]="field?.classes || ''"
         *ngSwitchCase="formFieldType.IMAGE_UPLOADER">
      <favie-image-uploader [control]="form.controls[field.key]"
                            [field]="field"></favie-image-uploader>
    </div>

    <div [ngClass]="field?.classes || ''" *ngSwitchCase="formFieldType.IMAGE_UPLOAD_OR_DRAG_DROP">
      <favie-image-upload-or-drag-drop
        [control]="form.controls[field.key]"
        [field]="field"
        [labelOutSide]="field?.labelOutSide"
        (imagesChange)="setImagesChange($event, form.controls[field.key])"
      ></favie-image-upload-or-drag-drop>
    </div>

    <div
      [ngClass]="field?.classes || ''"
      *ngSwitchCase="formFieldType.TEMPLATE_REF"
    >
      <ng-container *ngTemplateOutlet="field.templateRef"></ng-container>
    </div>

    <div [ngClass]="field?.classes || ''"
         *ngSwitchCase="formFieldType.PHONE">
      <favie-phone [control]="form.controls[field.key]"
                   [field]="field"></favie-phone>
    </div>

    <div [ngClass]="field?.classes || ''"
         *ngSwitchCase="formFieldType.PERCENTAGE">
      <favie-percentage [control]="form.controls[field.key]"
                        [field]="field"></favie-percentage>
    </div>

    <div [ngClass]="field?.classes || ''" *ngSwitchCase="formFieldType.DYNAMIC_SECTION">
      <favie-dynamic-section #dynamicSectionComponent [control]="form.controls[field.key]" [field]="field"></favie-dynamic-section>
    </div>
  </ng-container>
</ng-template>
