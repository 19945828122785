import { Component, HostListener, Input } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface IUserMenuItem {
  label: string;
  icon?: string;
  action: () => void;
}

@Component({
  selector: 'favie-user-menu',
  templateUrl: './user-menu.component.html',
})
export class FavieUserMenuComponent extends BaseComponent {
  @Input() displayName: string;
  @Input() menuItems: IUserMenuItem[];

  public displayUserMenu = false;
  private openAsked = false;

  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (this.displayUserMenu) {
      this.closeSecondaryMenu();
    }
  }

  toggleUserMenu() {
    if (this.displayUserMenu === false) {
      this.openAsked = true;
      this.displayUserMenu = true;
    }

    if (this.displayUserMenu === true && this.openAsked === false) {
      this.displayUserMenu = false;
    }
  }

  private closeSecondaryMenu() {
    if (this.displayUserMenu === true && this.openAsked === false) {
      this.displayUserMenu = false;
    }

    this.openAsked = false;
  }
}
