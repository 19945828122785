import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalSpinnerService } from '../../services/local-spinner.service';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'favie-local-spinner',
  templateUrl: './local-spinner.component.html',
  styleUrls: ['./local-spinner.component.scss'],
})
export class LocalSpinnerComponent extends BaseComponent {
  @Input() componentId: string;

  public showSpinner = false;

  constructor(private readonly localSpinnerService: LocalSpinnerService) {
    super();
  }

  onInit(): void {
    if (this.componentId) {
      this.localSpinnerService
        .registerComponent(this.componentId)
        .subscribe((loading: boolean) => {
          this.showSpinner = loading;
        });
    }
  }
}
