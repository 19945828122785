import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { MomentFromPipe } from './moment-from.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';
import { FavieCurrencyPipe } from './currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MomentFromPipe,
    TruncatePipe,
    SafePipe,
    SafeHtmlPipe,
    FilterPipe,
    FavieCurrencyPipe,
  ],
  exports: [
    MomentFromPipe,
    TruncatePipe,
    SafePipe,
    SafeHtmlPipe,
    FilterPipe,
    FavieCurrencyPipe,
  ],
})
export class PipesModule {}
