import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _isVisible = new BehaviorSubject<boolean>(false);
  private _isBackable = new BehaviorSubject<boolean>(false);

  private _headerType = new BehaviorSubject<any>(undefined);

  isVisibilityObs() {
    return this._isVisible.asObservable();
  }

  setVisibility(isVisible = true) {
    this._isVisible.next(isVisible);
  }

  isBackableObs() {
    return this._isBackable.asObservable();
  }

  setBackable(isBackable: boolean = false) {
    this._isBackable.next(isBackable);
  }

  getHeaderTypeObs() {
    return this._headerType.asObservable();
  }

  setHeaderTypeObs(value) {
    this._headerType.next(value);
  }
}
