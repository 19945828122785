import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { BehaviorSubject } from 'rxjs';
import { Subscribable } from '../../../components/base-component/subscribable';
import { FavieAuthService } from '../../auth/services/favie-auth.service';
import firebase from 'firebase';

@Injectable()
export class FirebaseService extends Subscribable {
  private signInWithPopupResult = new BehaviorSubject<any>(undefined);
  constructor(
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private favieAuthService: FavieAuthService
  ) {
    super();

    this.subscribe(this.angularFireAuth.authState, (user) => {
      if (user) {
        this.angularFireAuth.updateCurrentUser(user);
      }
    });
  }

  public createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  public signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public signOut() {
    this.angularFireAuth.signOut();
  }

  public getIdToken() {
    // return this.angularFireAuth.currentUser.then(user => user.getIdToken());
    return this.angularFireAuth.idToken;
  }

  public async uploadFile(file: File, path?: string): Promise<string> {
    const uid = this.favieAuthService.getUserUid();
    // The storage path
    path = path || `${uid}/${Date.now()}_${file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);
    // The main task
    const task: AngularFireUploadTask = this.storage.upload(path, file);
    const taskResult = await task.snapshotChanges().toPromise();
    const url = await taskResult.ref.getDownloadURL();
    return url;
  }

  public async signinByGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await this.angularFireAuth.signInWithPopup(provider).then((value) => this.signInWithPopupResult.next(value));
  }

  public async signinByFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    await this.angularFireAuth.signInWithPopup(provider).then((value) => this.signInWithPopupResult.next(value));
  }

  public async signinByApple() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    await this.angularFireAuth.signInWithPopup(provider).then((value) => this.signInWithPopupResult.next(value));
  }

  public getRedirectLoginResult() {
    return this.signInWithPopupResult.asObservable();
  }

  public async changePassword(newPassword: string) {
    return (await this.angularFireAuth.currentUser).updatePassword(newPassword);
  }

  public async sendForgotPasswordEmail(email: string) {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  public async updateEmail(newEmail: string) {
    return (await this.angularFireAuth.currentUser).updateEmail(newEmail);
  }

  public downloadEvidence(folderName: string, fileName: string) {
    const files = this.storage.ref(`${folderName}/${fileName}/`);
    return files;
  }

  public createRecaptchaVerifier(id: string, option) {
    return new firebase.auth.RecaptchaVerifier(id, option);
  }

  public async signInByPhone(phoneNumber: string, recaptchaVerifier: firebase.auth.RecaptchaVerifier) {
    try {
      const confirmResult = await this.angularFireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
      return confirmResult;
    } catch (error) {
      throw error;
    }
  }

  public async getCurrentUser() {
    return (await this.angularFireAuth.currentUser);
  }
}
