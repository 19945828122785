import { Inject, Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InitTranslateService {
  constructor(
    @Inject(NgxTranslateService) private translate: NgxTranslateService
  ) {}

  public initLanguage(): Promise<any> {
    return new Promise((resolve: any) => {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr').subscribe((lang) => {
        resolve();
      });
    });
  }
}
