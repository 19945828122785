import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringUtil } from '../utils/string.util';

export function isUrl(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    if (StringUtil.isUrl(value)) {
      return null;
    } else {
      return { isUrl: { value } };
    }
  };
}
