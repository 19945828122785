import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FavieContentHeaderData } from './favie-content-header-data.interface';

@Component({
  selector: 'favie-content-header',
  templateUrl: './favie-content-header.component.html',
})
export class FavieContentHeaderComponent extends BaseComponent {
  @Input()
  public value: FavieContentHeaderData[];
}
