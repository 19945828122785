import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { FavieCurrencyPipe } from '../pipes/currency.pipe';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[favieCurrency], input[maxAmount]',
})
export class FavieCurrencyDirective {
  @Input('maxAmount') maxAmount?: string

  constructor(
    private elementRef: ElementRef,
    private favieCurrencyPipe: FavieCurrencyPipe
  ) {}

  @HostListener('focus', ['$event.target.value', '$event'])
  onFocus(value, event) {
    this.elementRef.nativeElement.value = this.favieCurrencyPipe.parse(value);

    // tab
    if (event.which === 9) {
      return false;
    }
    this.elementRef.nativeElement.select();
  }

  @HostListener('blur', ['$event.target.value', '$event'])
  onBlur(value, event) {
    event.target.value = this.favieCurrencyPipe.transform(
      value,
      2
    );
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as any;
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }

    const a = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.'];
    if (a.indexOf(e.key) < 0) {
      e.preventDefault();
    }
    const value = e.target.value;
    const decimalSeparator = [',', '.'];
    if (decimalSeparator.indexOf(e.key) > -1 && value && value.indexOf(',') > -1) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    event.target.value = event.target.value.replace('.', ',');
    if (this.maxAmount) {
      const currentAmount = parseFloat((event.target.value as string).replace(',', '.')).toFixed(2)
      if (+currentAmount > +this.maxAmount) {
        event.target.value = this.maxAmount
      }
    }
  }
}
