import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { FirebaseService } from '../../modules/firebase/services/firebase.service';
import { isUrl } from '../../validators/is-url.validator';
import { BaseComponent } from '../base-component/base.component';
import { FormFieldInputType } from '../favie-form/enums/form-field-input-type.enum';
import { FormFieldType } from '../favie-form/enums/form-field-type.enum';
import {
  DatePickerFormField,
  ImageUploaderFormField,
  InputFormField,
  UploadPictureFormField,
} from '../favie-form/interfaces/form-field.interface';
import { FavieImageTemp } from '../upload-pictures/upload-pictures.component';
import { FavieImageUploaderError, FavieImageUploaderValue } from './favie-imgae-uploader-value.inteface';

@Component({
  selector: 'favie-image-uploader',
  templateUrl: './favie-image-uploader.component.html',
})
export class FavieImageUploaderComponent extends BaseComponent {
  @Input() field: ImageUploaderFormField;
  @Input() control: FormControl;

  public readonly TAB_INDEX_UPLOAD = 0;
  public readonly TAB_INDEX_LINK = 1;
  public currentTabIndex = this.TAB_INDEX_UPLOAD;

  public uploadPictureField: UploadPictureFormField;
  public imageLinkField: InputFormField;
  public expireDateField: DatePickerFormField;

  public imageLinkControl: FormControl;
  public expireDateControl: FormControl;
  public hasExpireDateControl: FormControl;

  public uploadedImageLink: string;
  private isFromLink: boolean;

  public tabLabel = {
    computer: this.translateService.instant(
      'Global.ImageUploader.FromComputer'
    ),
    link: this.translateService.instant('Global.ImageUploader.FromLink'),
  };

  public errors = [
    {
      type: FavieImageUploaderError.REQUIRE,
      message: this.translateService.instant(
        'Global.ImageUploader.Error.Require'
      ),
    },
  ];

  constructor(
    private readonly firebaseService: FirebaseService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  protected onInit() {
    super.onInit();
    this.initControlValue();
    this.initUploadPicture();
    this.initImageLink();
    this.initExpireDate();
    this.initValuePatcher();
    this.initValueEmitter();
  }

  protected onAfterViewInit() {
    super.onAfterViewInit();
    this.focusTabByImageOrigin();
    this.validate();
  }

  public setImagesChange(images: FavieImageTemp[]): void {
    if (images[0]?.file) {
      this.uploadImage(images[0]?.file).then((url) =>
        this.patchValue({ imageUrl: url, isFromLink: false }, true)
      );
    } else {
      this.patchValue({ imageUrl: null, isFromLink: false }, true);
    }
  }

  public changeEndOfRight(event: Event, enable: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.hasExpireDateControl.setValue(enable);

    this.setDatePickerState(enable);
  }

  private setDatePickerState(isEnable: boolean): void {
    if (!isEnable) {
      this.expireDateControl.setValue(null);
      this.expireDateControl.disable();
    } else if (this.expireDateControl.disabled) {
      this.expireDateControl.enable();
    }
  }

  private initControlValue() {
    const value = this.control.value || {};
    const defaultValue: FavieImageUploaderValue = {
      imageUrl: undefined,
      imageExpirationDate: undefined,
      isFromLink: false,
    };
    this.control.setValue({
      ...defaultValue,
      ...value,
      isFromLink: value.isFromLink || defaultValue.isFromLink,
    });
  }

  private initUploadPicture() {
    this.uploadPictureField = {
      key: 'logoUrlFromComputer',
      type: FormFieldType.UPLOAD_FILES,
      label: 'BROWSE',
      iconImage: 'assets/icons/common/image-plus.svg',
      limitImages: 1,
    };
  }

  private initImageLink() {
    this.imageLinkField = {
      key: 'logoUrl',
      type: FormFieldType.INPUT,
      inputType: FormFieldInputType.URL,
      label: this.translateService.instant('Global.ImageUploader.PasteURL'),
      icon: 'icon-link',
      errors: [
        {
          type: 'link',
          message: this.translateService.instant(
            'Global.ImageUploader.InvalidUrl'
          ),
        },
      ],
    };

    const isFromLink = this.control?.value?.isFromLink;
    if (isFromLink) {
      this.imageLinkControl = new FormControl(this.control?.value?.imageUrl, [
        isUrl(),
      ]);
      this.uploadedImageLink = null;
    } else {
      this.imageLinkControl = new FormControl(null, [isUrl()]);
      this.uploadedImageLink = this.control?.value?.imageUrl;
    }
    this.markImageFromLink(isFromLink);
  }

  private initExpireDate() {
    const canHaveExpiration = this.field.expireDate?.isDisplayed;
    if (canHaveExpiration) {
      this.expireDateField = {
        key: 'logoExpireAt',
        type: FormFieldType.DATEPICKER,
        label: this.translateService.instant('Global.ImageUploader.ExpireDate'),
        icon: 'icon-schedule',
        required: this.field.expireDate?.required,
      };
      if (this.control?.value?.imageExpirationDate) {
        this.hasExpireDateControl = new FormControl(true, [
          Validators.required,
        ]);
        this.expireDateControl = new FormControl(
          this.control?.value?.imageExpirationDate,
          this.field.expireDate?.required ? [Validators.required] : undefined
        );
      } else {
        this.hasExpireDateControl = new FormControl(false, [
          Validators.required,
        ]);
        this.expireDateControl = new FormControl(
          { value: null, disabled: true },
          this.field.expireDate?.required ? [Validators.required] : undefined
        );
      }
    }
  }

  private initValuePatcher() {
    this.subscribe(
      this.control.valueChanges,
      (value: FavieImageUploaderValue) => this.setValue(value, false)
    );
  }

  private setValue(value: FavieImageUploaderValue, emitValue: boolean = false) {
    if (value.isFromLink) {
      this.imageLinkControl.setValue(value?.imageUrl, { emitEvent: false });
      this.hasExpireDateControl.setValue(!!value?.imageExpirationDate, { emitEvent: false });
      this.expireDateControl.setValue(value?.imageExpirationDate, { emitEvent: false });
    } else {
      this.uploadedImageLink = value?.imageUrl;
      this.hasExpireDateControl.setValue(!!value?.imageExpirationDate, { emitEvent: false });
      this.expireDateControl.setValue(value?.imageExpirationDate, { emitEvent: false });
      this.setDatePickerState(!!value?.imageExpirationDate);
    }
    this.validate();
    this.markImageFromLink(value?.isFromLink);
    if (emitValue) {
      this.control.setValue(value);
    }
  }

  private patchValue(
    value: FavieImageUploaderValue,
    emitValue: boolean = false
  ) {
    if (value.imageUrl === undefined) {
      value.imageUrl = this.control.value.imageUrl;
    }
    if (value.imageExpirationDate === undefined) {
      value.imageExpirationDate = this.control.value.imageExpirationDate;
    }
    if (value.isFromLink === undefined) {
      value.isFromLink = this.control.value.isFromLink;
    }
    this.setValue(value, emitValue);
  }

  private initValueEmitter() {
    this.subscribe(
      this.imageLinkControl.valueChanges.pipe(distinctUntilChanged()),
      (url) => {
        if (url) {
          this.patchValue({ imageUrl: url, isFromLink: true }, true);
        }
      }
    );
    this.subscribe(
      this.expireDateControl.valueChanges.pipe(distinctUntilChanged()),
      (date) => this.patchValue({ imageExpirationDate: date }, true)
    );
    this.subscribe(
      this.hasExpireDateControl.valueChanges.pipe(distinctUntilChanged()),
      (hasExpireDate) => {
        if (!hasExpireDate) {
          this.expireDateControl.reset(null);
        }
        this.validate();
      }
    );
  }

  private validate() {
    let errors = { ...this.control.errors, ...this.imageLinkControl.errors };

    if (this.field.required) {
      if (this.currentTabIndex === this.TAB_INDEX_UPLOAD) {
        // upload tab
        if (
          !this.uploadedImageLink ||
          (this.hasExpireDateControl.value && !this.expireDateControl.value)
        ) {
          errors[FavieImageUploaderError.REQUIRE] = true;
        } else {
          delete errors[FavieImageUploaderError.REQUIRE];
        }
      } else {
        // link tab
        if (
          !this.imageLinkControl.value ||
          (this.hasExpireDateControl.value && !this.expireDateControl.value)
        ) {
          errors[FavieImageUploaderError.REQUIRE] = true;
        } else {
          delete errors[FavieImageUploaderError.REQUIRE];
        }
      }
    } else {
      delete errors[FavieImageUploaderError.REQUIRE];
    }

    if (Object.entries(errors).length === 0) {
      errors = null;
    }
    this.control.setErrors(errors);
  }

  private markImageFromLink(isFromLink) {
    this.isFromLink = isFromLink;
    this.focusTabByImageOrigin();
  }

  private focusTabByImageOrigin() {
    this.currentTabIndex = this.isFromLink
      ? this.TAB_INDEX_LINK
      : this.TAB_INDEX_UPLOAD;
  }

  private async uploadImage(file): Promise<string> {
    if (file) {
      const path = `${this.field.imageUploadPath}/${Date.now()}_${file.name}`;
      return this.firebaseService.uploadFile(file, path);
    }
    return null;
  }

  onChangeTab() {
    this.validate();
  }
}
