import { Injectable } from '@angular/core';
import { Subscribable } from '../../../../base-component/subscribable';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SimpleHeaderService extends Subscribable {
  private _item$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public selectedHeader(id) {
    this._item$.next(id);
  }

  public getSelectItem() {
    return this._item$.asObservable();
  }
}
