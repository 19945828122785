// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'http://localhost:4200',
  //apiUrl: 'http://localhost:8080/graphql',
  cmsUrl: 'https://strapi.semyos.favie.tech',
  apiUrl: 'https://api-staging.semyos.favie.tech/graphql',
  defaultLanguage: 'fr',
  supportedLanguages: ['en', 'fr'],
  firebase: {
    apiKey: 'AIzaSyBxUN6_YdKb9hnwhTFqUKaLQ1gbxnLKOPc',
    authDomain: 'semyoswebsite.firebaseapp.com',
    databaseURL: 'https://semyoswebsite.firebaseio.com',
    projectId: 'semyoswebsite',
    storageBucket: 'semyoswebsite.appspot.com',
    messagingSenderId: '664216230896',
    appId: '1:664216230896:web:4f1fd176cce8234c',
    measurementId: 'G-V0W7J81D26'
  },
  imageUploadMaximumFileSize: 3145728,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
