<section class="simple-footer__section">
  <div class="footer-container">
    <div fxLayout="row" fxLayoutGap="130px" fxLayoutAlign="space-between" fxLayout.lt-md="column"
      fxLayoutGap.lt-md="24px">
      <div class="company" fxLayout="column" *ngIf="footer?.company" fxLayoutGap="8px">
        <span class="title__footer" *ngIf="footer?.company?.name">{{
        footer?.company?.name
      }}</span>
        <div class="address-section">
          <span *ngIf="footer?.company?.address?.title">{{ footer?.company?.address?.title }}</span>
          <span *ngIf="footer?.company?.address"
            (click)="footer?.company?.address?.action()">{{ footer?.company?.address?.label }}</span>
        </div>
       <div class="tel-section">
        <span *ngIf="footer?.company?.tel?.title">{{ footer?.company?.tel?.title }}</span>
        <span *ngIf="footer?.company?.tel"
        (click)="footer?.company?.tel?.action()">{{ footer?.company?.tel?.label }}</span>
       </div>
        <div class="email-section">
          <span *ngIf="footer?.company?.email?.title">{{ footer?.company?.email?.title }}</span>
          <span *ngIf="footer?.company?.email" (click)="footer?.company?.email?.action()">
            {{ footer?.company?.email?.label }}
          </span>
        </div>
      </div>
      <ng-template [ngIf]="!isCollapsed && footer?.home">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="home" *ngFor="let home of footer?.home">
            <span (click)="home?.action()">{{ home?.label }}</span>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="footer?.showSection?.isShow || !isCollapsed && footer?.sections">
        <div [fxLayout]="footer?.showSection?.direction || 'column'" 
            [fxLayout.lt-md]="footer?.showSection?.directionMobile || 'column'"  fxLayoutGap="8px">
          <div class="sections" *ngFor="let section of footer?.sections">
            <span (click)="section?.action(section.id)">{{
              section?.label
            }}</span>
          </div>
        </div>
      </ng-template>
      <div class="socials" fxLayout="column" fxLayout.lt-md="row" *ngIf="footer?.socials" fxLayoutGap="16px">
        <span class="title__footer">{{ footer?.socials.label }}</span>
        <div fxLayout="row" fxLayoutGap="32px">
          <a *ngFor="let icon of footer?.socials?.icons" [href]="icon.link">
            <i [class]="icon.icon"></i>
          </a>
        </div>
      </div>
    </div>
    <ng-content></ng-content>    
  </div>
  <div *ngIf="footer?.rights">
    <div class="content-separator"></div>
    <div class="rightsFavie" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
      <span *ngIf="footer?.rights?.label">{{ footer?.rights?.label }}</span>
      <a href="https://favie.tech">
        <img *ngIf="footer?.rights?.image" [src]="footer?.rights?.image?.src" [alt]="footer?.rights?.image?.src"
          [style.max-width]="footer?.rights?.image?.maxWidth" [style.max-height]="footer?.rights?.image?.maxHeight" />
      </a>
    </div>
  </div>
</section>