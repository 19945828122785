import { Inject, Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import * as jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { Subscribable } from 'src/common/components/base-component/subscribable';
import { CommonConstant } from 'src/common/constants/common.constant';
import { STORAGE } from '../../../storages/storages.inject';
import { User } from '../../user/interfaces/user.interface';
import firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class FavieAuthService extends Subscribable {
  protected token: string;
  protected refreshToken: string;
  protected user: any;
  protected _userProfile: User;
  protected _firebaseUserCredential: firebase.auth.UserCredential;

  protected logged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    @Inject(STORAGE)
    private readonly storage: Storage,
  ) {
    super();
    this.refreshUserToken();
    this.refreshFirebaseUserCredential();
  }

  protected refreshUserToken() {
    const userToken = this.storage.getItem(CommonConstant.USER_TOKEN);
    if (userToken) {
      const token = JSON.parse(userToken);
      this.setUserToken(token);
    }
  }

  public setUserToken(userToken: { token: string; refreshToken: string }) {
    this.storage.setItem(CommonConstant.USER_TOKEN, JSON.stringify(userToken));
    this.token = userToken.token;
    this.refreshToken = userToken.refreshToken;
    this.user = jwtDecode(this.token);
    this.setLogged(true);
  }

  protected clearUserToken() {
    this.storage.removeItem(CommonConstant.USER_TOKEN);
    this.storage.removeItem(CommonConstant.FIREBASE_USER_CREDENTIAL);
    this.token = undefined;
    this.refreshToken = undefined;
    this.user = undefined;
    this._firebaseUserCredential = undefined;
    this._userProfile = undefined;
    this.setLogged(false);
  }

  public setLogged(status: boolean) {
    this.logged$.next(status);
  }

  public getLogged() {
    return this.logged$.asObservable();
  }

  public getToken() {
    return this.token;
  }

  public getDecodedToken() {
    const token = this.token;
    const decodedToken = jwt_decode(token);
    return decodedToken;
  }

  public getRefreshToken() {
    return this.refreshToken;
  }

  public setFirebaseUserCredential(
    firebaseUserCredential: firebase.auth.UserCredential
  ) {
    this._firebaseUserCredential = firebaseUserCredential;
    this.storage.setItem(
      CommonConstant.FIREBASE_USER_CREDENTIAL,
      JSON.stringify(this._firebaseUserCredential)
    );
  }

  protected refreshFirebaseUserCredential() {
    const firebaseUserCredential = this.storage.getItem(
      CommonConstant.FIREBASE_USER_CREDENTIAL
    );
    if (firebaseUserCredential) {
      const token = JSON.parse(firebaseUserCredential);
      this.setFirebaseUserCredential(token);
    }
  }

  public setUserProfile(userProfile: User) {
    this._userProfile = userProfile;
  }

  public getUserProfile() {
    return this._userProfile;
  }

  public logout() {
    this.clearUserToken();
  }

  public getUser() {
    return this.user;
  }

  public getUserId() {
    return this.user.user_uuid;
  }

  public getUserUid() {
    return this.user.user_id;
  }
}
