<section class="hearder-2__section">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div
      *ngIf="isLoggedIn"
      class="hearder__info"
      fxLayoutGap="16px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div class="info__picture">
        <img
          [src]="profilePicture"
          [alt]="headerSecondary?.user?.image?.alt"
          [style.max-width]="headerSecondary?.user.image?.maxWidth"
          [style.max-height]="headerSecondary?.user.image?.maxHeight"
        />
      </div>
      <div class="info__profile" fxLayout="column">
        <span>{{ headerSecondary?.user?.greet }}</span>
        <span>{{ headerSecondary?.user?.fullName }}</span>
      </div>
      <div class="info__menu" (click)="openMenu()">
        <img
          class="click-drop-down-menu"
          *ngIf="!displayMenu"
          [src]="headerSecondary?.user?.dropDown?.down?.src"
          [alt]="headerSecondary?.user?.dropDown?.down?.alt"
          [style.max-width]="headerSecondary?.user.dropDown?.maxWidth"
          [style.max-height]="headerSecondary?.user.dropDown?.maxHeight"
        />

        <img
          *ngIf="displayMenu"
          [src]="headerSecondary?.user?.dropDown?.up?.src"
          [alt]="headerSecondary?.user?.dropDown?.up?.alt"
          [style.max-width]="headerSecondary?.user.dropDown?.maxWidth"
          [style.max-height]="headerSecondary?.user.dropDown?.maxHeight"
        />

        <div class="drop-down__menu" *ngIf="displayMenu">
          <ul>
            <li *ngFor="let item of headerSecondary?.menu">
              <a (click)="item.action()">
                <i
                  class="icon-user-circle"
                  *ngIf="item.icon"
                  [class]="item.icon"
                ></i>
                <span class="m__l--8">{{ item.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <favie-sign-in-button
      *ngIf="!isLoggedIn"
      [form]="formSignInSignUp"
    ></favie-sign-in-button>
    <div class="hearder__notification">
      <img
        [src]="headerSecondary?.notification?.image?.src"
        [alt]="headerSecondary?.notification?.image?.alt"
      />
    </div>
  </div>
  <div class="header-2__separator"></div>
</section>
