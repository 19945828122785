import { APP_INITIALIZER } from '@angular/core';
import { InitTranslateService } from './translate.service';

export function initLanguage(translateService: InitTranslateService): any {
  return () => translateService.initLanguage();
}

const initLanguageProvider = {
  provide: APP_INITIALIZER,
  useFactory: initLanguage,
  multi: true,
  deps: [InitTranslateService],
};

export default initLanguageProvider;
