import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { LandingPageSubContent } from '../../interfaces/landing-page-sub-content.interface';

@Component({
  selector: 'favie-landing-page-content-sub',
  templateUrl: './landing-page-content-sub.component.html',
})
export class LandingPageContentSubComponent extends BaseComponent {
  @Input() content: LandingPageSubContent;
}
