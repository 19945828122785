<section class="simple-header-profile__section">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="simple-header-profile__avatar" *ngIf="profileHeader?.avatar">
      <img
        [src]="profileHeader?.avatar?.src"
        [alt]="profileHeader?.avatar?.alt"
      />
    </div>
    <div
      class="simple-header-profile__profile"
      fxLayout="column"
      fxLayoutAlign="center start"
    >
      <ng-template [ngIf]="profileHeader?.title">
        <span class="simple-header-profile__title">{{
          profileHeader?.title
        }}</span>
      </ng-template>
      <ng-template [ngIf]="profileHeader?.subTitle">
        <span class="simple-header-profile__sub-title">{{
          profileHeader?.subTitle
        }}</span>
      </ng-template>      
    </div>
    <img *ngIf='profileHeader.options' class="arrow-down-ic" src="./assets/icons/arrow-downward-fill.svg" alt="arrow down icon">
    <div class="simple-header-profile__options" *ngIf='profileHeader.options'>
      <div class="option-item" [class.divider]='item?.isHaveDivider' *ngFor="let item of profileHeader?.options" (click)="item?.action()">
        <img [src]="item?.iconUrl" alt="icon option" *ngIf="item?.iconUrl">
        <span>{{ item?.name }}</span>
      </div>
    </div>
  </div>
</section>
