import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { BaseDialogData } from './base-dialog-data.interface';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
})
export class BaseDialogComponent extends BaseComponent {
  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BaseDialogData
  ) {
    super();
  }

  primaryClick() {
    this.dialogRef.close(true);
  }

  secondaryClick() {
    this.dialogRef.close(false);
  }
}
