import { isPlatformBrowser, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'favie-language-switcher',
  templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent extends BaseComponent {
  @Input() iconClass: string;
  @Input() iconLocales;

  public currentLanguage: string;
  public displayLanguageSecondaryMenu = false;
  public languages: { value: string, label: string }[];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly languageService: LanguageService,
    private readonly elementRef: ElementRef,
    @Inject(LOCALE_ID)
    public readonly locale: string,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly location: Location,
  ) {
    super();
  }

  public openLanguageSecondaryMenu() {
    this.displayLanguageSecondaryMenu = !this.displayLanguageSecondaryMenu;
  }

  public changeLanguage(language: string) {
    this.localStorageService.setItem('userLanguage', language);
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = `${window.location.origin}/${language}${this.location.path()}`;
    }
  }

  onInit() {
    this.languages = this.languageService.getSupportedLanguages();
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.elementRef.nativeElement.contains($event.target)) {
    } else {
      if (this.displayLanguageSecondaryMenu) {
        this.displayLanguageSecondaryMenu = false;
      }
    }
  }
}
