<div class="base-dialog__section">
  <div class="close__btn" *ngIf="data.closeIcon">
    <img [src]="data.closeIcon" (click)="secondaryClick()" />
  </div>

  <h2 mat-dialog-title *ngIf="data.title">
    {{ data.title }}
  </h2>

  <mat-dialog-content *ngIf="data.message">
    {{ data.message }}
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      *ngIf="data.secondaryLabel"
      (click)="secondaryClick()"
    >
      <mat-icon *ngIf="data.primaryMatIcon">
        {{ data.secondaryMatIcon }}
      </mat-icon>
      <span>{{ data.secondaryLabel }}</span>
    </button>

    <button
      mat-raised-button
      *ngIf="data.primaryLabel"
      [color]="data.primaryButtonColor || 'primary'"
      (click)="primaryClick()"
    >
      <mat-icon *ngIf="data.primaryMatIcon">
        {{ data.primaryMatIcon }}
      </mat-icon>
      <span>{{ data.primaryLabel }}</span>
    </button>
  </mat-dialog-actions>
</div>
