import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'momentFrom' })
export class MomentFromPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID)
    private readonly locale: string
  ) { }

  transform(date: Date | string, withoutSuffix: boolean = true) {
    const locale = this.locale;
    const value = moment(new Date(date)).locale(locale).fromNow(withoutSuffix);
    return value;
  }
}
