import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnInit {
  @Input() scrollContainer: HTMLElement;
  @Input() isLoading: boolean;
  @Input() firstLoad: boolean;
  @Input() total: number;

  @Input() willShowScrollToTop = false;
  @Input() limitItemWidth = true;

  @Output() scrolled = new EventEmitter();

  scrollPositionStart: number;
  showScrollToTop = false;

  ngOnInit() {
    if (this.willShowScrollToTop) {
      this.scrollPositionStart = this.scrollContainer.scrollTop;
      this.scrollContainer.addEventListener('scroll', () => {
        const currentScrollPosition = this.scrollContainer.scrollTop;
        if (currentScrollPosition > this.scrollPositionStart) {
          this.showScrollToTop = true;
        } else {
          this.showScrollToTop = false;
        }
      });
    }
  }

  scrollToTop() {
    this.scrollContainer.scrollTo(this.scrollContainer.scrollLeft, 0);
  }

  onScroll() {
    this.scrolled.next();
  }
}
