import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-favie-mat-slide-toggle',
  templateUrl: './favie-mat-slide-toggle.component.html',
  styleUrls: ['./favie-mat-slide-toggle.component.scss']
})
export class FavieMatSlideToggleComponent {
  @Input() control: FormControl;
  @Input() isDefaultValue;
}
