import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AllComponentModule } from './all-components.module';
import { AppMaterialModule } from './app-material.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AppMaterialModule,
    AllComponentModule,
  ],
  exports: [AppMaterialModule, AllComponentModule],
})
export class InfrastructureModule {}
