<div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <i class="icon-cancel-circle" (click)="dialogRef.close()"></i>
  </div>
  <section class="from-sign-in-sign-up">
    <div class="form-box">
      <div class="center">
        <h1 class="from-sign-in-sign-up big_title" fxLayoutAlign="center center">
          {{ data?.title }}
        </h1>
        <favie-social-media
        *ngIf="data.socialIcon"
        [socialLabel]="data?.subTitle"
        [icon]="data.socialIcon">
        </favie-social-media>

        <section class="favie-sign-up__section">
          <favie-form #favieForm class="dialog__form" [formInput]="formInput" (primaryEmitter)="onSubmit($event)"></favie-form>
        </section>
      </div>
    </div>
  </section>
</div>