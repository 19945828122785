import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { map, tap } from 'rxjs/operators';
import { FavieAuthService } from '../../auth/services/favie-auth.service';
import { User } from '../interfaces/user.interface';
import { UserGqlService } from './user-gql.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private readonly favieAuthService: FavieAuthService,
    private readonly userGqlService: UserGqlService
  ) {}

  public getMyProfile() {
    return this.userGqlService.getMyProfile().pipe(
      map((result: ApolloQueryResult<any>) => {
        const data = result.data;
        const user: User = data.getMyProfile;
        return user;
      }),
      tap((user: User) => {
        this.favieAuthService.setUserProfile(user);
      })
    );
  }

  public canUserEmailAccessWebAdmin(email: string) {
    return this.userGqlService.canUserEmailAccessWebAdmin(email);
  }

  public setUserClaims() {
    return this.userGqlService.setUserClaims();
  }

  public updateUser(input) {
    return this.userGqlService.updateUserProfile(input);
  }
}
