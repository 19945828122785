<favie-label
  *ngIf="field?.labelOutSide"
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <mat-select
    [formControl]="control"
    [compareWith]="compareFn"
    [placeholder]="field?.placeholder || ''"
    [required]="field?.required"
    [multiple]="field?.multiple || false"
  >
    <mat-option *ngFor="let option of field?.options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
