import { Component, OnInit, Inject, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { FormInput } from 'src/common/components/favie-form/interfaces/form-input.interface';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { FavieFormComponent } from 'src/common/components/favie-form/favie-form.component';
import { CommonConstant } from 'src/common/constants/common.constant';
import { concatMap, map } from 'rxjs/operators';
import { UserService } from 'src/common/modules/user/services/user.service';

@Component({
  selector: 'favie-sign-up-social-media-dialog',
  templateUrl: './favie-sign-up-social-media-dialog.component.html',
  styleUrls: ['./favie-sign-up-social-media-dialog.component.scss']
})
export class FavieSignUpSocialMediaDialogComponent extends BaseComponent {
  @ViewChild('favieForm') favieForm: FavieFormComponent;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();

  public formInput: FormInput;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<FavieSignUpSocialMediaDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly userService: UserService
  ) {
    super();
  }

  onInit() {
    super.onInit();
    this.initForm();
  }

  onAfterViewInit() {
    this.favieForm.form.patchValue({
      firstName: this.data.userInformation.firstName,
      lastName: this.data.userInformation.lastName
    });
  }

  onSubmit(event: any): void {
    const userInfo  = {
      ...this.data.userInformation,
      country: event?.country
    };
    const updateInfo$ = this.authService.updateConsent()
        .pipe(
          concatMap(() => this.userService.updateUser(userInfo)),
          map(({ data }: any) => data.updateUser)
        );

    this.subscribe(updateInfo$, (result: any) => {
      if (result && result.statusCode === 200) {
        this.dialogRef.close();
      }
      else {
        const msg = result.message;
        this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
      }
    });
  }

  private initForm(): void {
    this.formInput = this.data.formInput;
    const providerId = this.data?.userCredential?.additionalUserInfo.providerId;
    this.showHideSocialIcon(providerId);
  }

  private showHideSocialIcon(providerId: string): void {
    switch (providerId) {
      case 'google.com':
        this.data.socialIcon = { gmail: './assets/icons/google.svg' };
        break;
      case 'facebook.com':
        this.data.socialIcon = { facebook: 'dogtor-icon-auth-facebook' };
        break;
      case 'appleid.apple.com':
        this.data.socialIcon = { gmail: 'dogtor-icon-auth-apple' };
        break;

      default:
        this.data.socialIcon = null;
        break;
    }
  }
}
