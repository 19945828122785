<section>
  <ng-template [ngIf]="content">
    <favie-landing-page-content-main
      *ngIf="content.main"
      [content]="content.main"
    >
    </favie-landing-page-content-main>
    <favie-landing-page-content-sub
      *ngFor="let subContent of content.subs"
      [content]="subContent"
    >
    </favie-landing-page-content-sub>
  </ng-template>
</section>
