import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { CheckboxFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-checkbox',
  templateUrl: './favie-checkbox.component.html',
})
export class FavieCheckboxComponent extends BaseComponent {
  @Input() field: CheckboxFormField;
  @Input() control: FormControl;
}
