<div
  class="section__simple"
  fxLayout="row"
  fxFill
  fxLayoutAlign="space-between center"
>
  <div
    *ngIf="section?.title"
    fxLayout="row"
    class="title"
    fxLayoutAlign="start center"
    (click)="handlePrimaryButton()"
  >
    <img
      *ngIf="section?.title?.image"
      [style.max-width]="section?.title?.image?.width"
      [style.max-height]="section?.title?.image?.height"
      [src]="section?.title?.image?.src"
      [alt]="section?.title?.image?.alt"
    />
    <p class="m__l--16" *ngIf="section?.title?.text">
      {{ section?.title?.text }}
    </p>
  </div>
  <div
    *ngIf="section?.subTitle"
    fxLayout="row"
    fxLayoutAlign="start center"
    class="sub__title"
    (click)="handleSecondaryButton()"
  >
    <img
      *ngIf="section?.subTitle?.image"
      [style.max-width]="section?.subTitle?.image?.width"
      [style.max-height]="section?.subTitle?.image?.height"
      [src]="section?.subTitle?.image?.src"
      [alt]="section?.subTitle?.image?.alt"
    />
    <p class="m__l--10" *ngIf="section?.subTitle?.text">
      {{ section?.subTitle?.text }}
    </p>
  </div>
</div>
