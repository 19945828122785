import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../base-component/base.component';
import { SidebarItem } from './sidebar-item.interface';
import { SidebarLogo } from './sidebar-logo.interface';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'favie-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent extends BaseComponent {
  @Input() hideSidebarAtUrls: string[];
  @Input() logo: SidebarLogo;
  @Input() items: SidebarItem[];

  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();

  public isVisible = false;
  public isHide = false;

  public activeItem;

  private _isExpand = false;
  private _isExpandOnHover = false;

  constructor(
    public router: Router,
    private sidebarService: SidebarService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  get isExpand() {
    return this._isExpand || this._isExpandOnHover;
  }

  onInit() {
    this.initActiveItem();
    this.initHideAtUrls();
    this.initVisible();
    this.initExpand();
    this.initExpandOnHover();
  }

  onToggleExpand() {
    this.sidebarService.expandSidebar(!this._isExpand);
  }

  private initActiveItem() {
    this.sidebarService.getActiveItem().subscribe((activeItem) => {
      this.activeItem = activeItem;
      this.changeDetectorRef.detectChanges();
    });
  }

  private initHideAtUrls() {
    if (this.hideSidebarAtUrls) {
      this.subscribe(this.router.events, (event) => {
        if (event instanceof NavigationEnd) {
          const arr = event.url.split('/');
          const currentRoute = arr[arr.length - 1];
          this.isHide = this.hideSidebarAtUrls.includes(currentRoute);
        }
      });
    }
  }

  private initVisible() {
    this.subscribe(this.sidebarService.isSideBarVisibleObs(), (isVisible) => {
      this.isVisible = isVisible;
    });
  }

  private initExpand() {
    this.subscribe(this.sidebarService.getExpandSidebarObs(), (isExpand) => {
      this._isExpand = isExpand;
    });
  }

  private initExpandOnHover() {
    this.subscribe(
      this.sidebarService.getExpandOnHoverSidebarObs(),
      (isExpand) => {
        this._isExpandOnHover = isExpand;
      }
    );
  }
}
