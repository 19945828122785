import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface ProfileHeader {
  avatar?: {
    src?: string;
    alt?: string;
  };
  title?: string;
  subTitle?: string;
  options?: ProfileHeaderOption[];
}

export interface ProfileHeaderOption {
  iconUrl?: string;
  name: string;
  isHaveDivider?: boolean;
  action?: () => void;
}

@Component({
  selector: 'favie-simple-header-profile',
  templateUrl: './simple-header-profile.component.html',
})
export class SimpleHeaderProfileComponent extends BaseComponent {
  @Input() profileHeader: ProfileHeader;
}
