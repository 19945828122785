import { FavieCurrencyDirective } from './currency.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FavieCurrencyPipe } from '../pipes/currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FavieCurrencyDirective],
  providers: [FavieCurrencyPipe],
  exports: [FavieCurrencyDirective],
})
export class DirectivesModule {}
