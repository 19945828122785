import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FormInput } from 'src/common/components/favie-form/interfaces/form-input.interface';
import { LocalSpinnerService } from 'src/common/services/local-spinner.service';
import {
  Layouts,
  ResponsiveLayoutService
} from 'src/common/services/responsive-layout.service';
import { BaseComponent } from '../../../../components/base-component/base.component';
import { CommonConstant } from '../../../../constants/common.constant';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'favie-sign-up-sign-in-dialog',
  templateUrl: './favie-sign-up-sign-in-dialog.component.html',
  styleUrls: ['./favie-sign-up-sign-in-dialog.component.scss'],
})
export class FavieSignUpSignInDialogComponent extends BaseComponent {
  /**
   * Sign up first
   * set mode === isSignInMode
   */
  public isSignInMode = true;

  public isCollapsed;
  public formSignIn: FormInput;
  public formSignUp: FormInput;
  public title: string;
  public subTitle: string;
  public changeModalText: string;
  public changeModalLabel: string;
  public localLabel: string;
  public socialLabel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<FavieSignUpSignInDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly responsiveLayoutService: ResponsiveLayoutService,
    private readonly localSpinnerService: LocalSpinnerService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  onInit() {
    this.initForm();
    this.initMode();
    this.initTitle();
    this.initSubTitle();

    this.subscribe(
      this.responsiveLayoutService.getCurrentLayout(),
      (layout) => {
        this.isCollapsed = layout === Layouts.MOBILE;
      }
    );
  }

  async onSubmitSignUp(account: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    country?: string;
    answer1: string;
    answer2: string;
    localSpinnerId?: string;
  }) {
    if (account?.localSpinnerId) {
      this.localSpinnerService.startLocalSpinner(account.localSpinnerId);
    }

    try {
      await this.authService.signUp(account);
      this.snackBar.open(
        this.translateService.instant('success.Signup'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.dialogRef.close();
    } catch (err) {
      const msg = err.message;
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    } finally {
      if (account?.localSpinnerId) {
        this.localSpinnerService.stopLocalSpinner(account.localSpinnerId);
      }
    }
  }

  async onSubmitSignIn(account: { email: string; password: string }) {
    try {
      await this.authService.signIn(account);
      this.snackBar.open(
        this.translateService.instant('success.Signin'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.dialogRef.close();
    } catch (err) {
      const msg = err.message;
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    }
  }

  private initSubTitle() {
    if (this.data?.customTitle) {
      this.subTitle = this.title + ' ' + this.data?.title?.subTitle;
    } else {
      this.subTitle = this.data?.title?.subTitle;
    }
  }

  public async googleEmitter() {
    await this.signInByGoogle();
  }

  public async facebookEmitter() {
    await this.signInByFacebook();
  }

  public async appleEmitter() {
    await this.signInByApple();
  }

  public changeModalMode() {
    this.isSignInMode = !this.isSignInMode;
    this.setTextLabelByMode();
    this.initSubTitle();
  }

  private initTitle() {
    if (this.data) {
      this.title = this.data.title.mainTitle;
      this.setTextLabelByMode();
    }
  }

  private initForm() {
    if (this.data) {
      this.formSignIn = this.data?.formSignIn;
      this.formSignUp = this.data?.formSignUp;
    }
  }

  private setTextLabelByMode() {
    this.changeModalText = this.isSignInMode
      ? this.data.title.dontHaveAccount
      : this.data.title.alreadyHaveAccount;
    this.changeModalLabel = this.isSignInMode
      ? this.data.title.signUp
      : this.data.title.signIn;
    this.localLabel = this.isSignInMode
      ? this.data.title.orSigninWith
      : this.data.title.orSignupWith;
    this.socialLabel = this.isSignInMode
      ? this.data.title.signinWith
      : this.data.title.signupWith;
  }

  private async signInByGoogle() {
    try {
      await this.authService.signInByGoogle();
      this.snackBar.open(
        this.translateService.instant('success.Signin'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.dialogRef.close();
    } catch (error) {
      const msg = error.message || this.translateService.instant('fail');
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    }
  }

  private updateDialogTitle() {
    this.title = this.isSignInMode
      ? this.data.title.signIn
      : this.data.title.signUp;
  }

  private async signInByFacebook() {
    try {
      await this.authService.signInByFacebook();
      this.snackBar.open(
        this.translateService.instant('success.Signin'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.dialogRef.close();
    } catch (error) {
      const msg = error.message || this.translateService.instant('fail');
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    }
  }

  private async signInByApple() {
    try {
      await this.authService.signInByApple();
      this.snackBar.open(
        this.translateService.instant('success.Signin'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.dialogRef.close();
    } catch (error) {
      const msg = error.message || this.translateService.instant('fail');
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    }
  }

  private initMode() {
    if (this.data?.isSignInMode !== undefined) {
      this.isSignInMode = this.data?.isSignInMode;
    }
  }
}
