import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { CommonConstant } from 'src/common/constants/common.constant';

@Component({
  selector: 'favie-search-bar',
  templateUrl: './favie-search-bar.component.html',
})
export class FavieSearchBarComponent extends BaseComponent {
  @Input()
  public placeholder: string;

  @Input() minLength = CommonConstant.INPUT_DEBOUNCE_THRESH_HOLD;
  @Input() debounceTime = CommonConstant.INPUT_DEBOUNCE_INTERVAL;

  @Input() imageSrc: string;

  @Output()
  public searched: EventEmitter<string> = new EventEmitter();

  public searchControl: FormControl;

  public search() {
    const value = this.searchControl.value;
    this.searched.next(value);
  }

  onInit() {
    this.searchControl = new FormControl();

    this.subscribe(
      this.searchControl.valueChanges.pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      ),
      (searchValue) => {
        if (
          typeof searchValue === 'string' &&
          this.searchValueValidate(searchValue)
        ) {
          this.searched.next(searchValue);
        }
      }
    );
  }

  private searchValueValidate(searchValue: string) {
    return (
      searchValue &&
      searchValue.trim().length >= (this.minLength)
    );
  }
}
