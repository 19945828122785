import { isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ContextDataService {
  public onContextDataChanged = new Subject<{ [key: string]: any }>();
  private contextData: { [key: string]: any } = {};

  private notifyContextDataChanged(): void {
    this.onContextDataChanged.next(this.contextData);
  }

  clearAll(): void {
    this.contextData = {};
    this.notifyContextDataChanged();
  }

  setData(key: string, data: any): void {
    this.contextData[key] = data;
    this.notifyContextDataChanged();
  }

  removeData(key: string): void {
    delete this.contextData[key];
    this.notifyContextDataChanged();
  }

  isEmpty(): boolean {
    return isEmpty(this.contextData);
  }

  hasData(key: string): boolean {
    const found = this.contextData[key];
    return found !== null && found !== undefined;
  }

  hasKey(key: string): boolean {
    return this.contextData.hasOwnProperty(key);
  }

  getData<T>(key: string): T {
    return <T>this.contextData[key];
  }
}
