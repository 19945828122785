<section class="landing-page-main__section">
  <div class="container ">
    <div class="title" *ngIf="content?.title">
      {{ content.title }}
    </div>
    <div class="subtitle" *ngIf="content?.subtitle">
      {{ content.subtitle }}
    </div>
    <div class="summary" *ngIf="content?.summary">
      {{ content.summary }}
    </div>
    <div class="action" *ngIf="content?.action">
      <a [routerLink]="content.action.url">
        {{ content.action.label }}
      </a>
    </div>
    <div class="button_scroll" *ngIf="content?.scroll">
      <button (click)="scrollNext()"><img src="./assets/img/company/button.png" /></button>
    </div>
  </div>
  <div class="overlay" #fileInputElement></div>
  <div class="media">
    <video autoplay loop preload="auto" *ngIf="content?.background?.videoUrl"
      [src]="content.background.videoUrl"></video>
    <img *ngIf="content?.background?.imageUrl" [src]="content.background.imageUrl" />
  </div>
</section>
