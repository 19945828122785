import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { InputFormField } from '../favie-form/interfaces/form-field.interface';
import { FormFieldInputType } from '../favie-form/enums/form-field-input-type.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const IS_URL_ERROR_TYPE = 'isUrl';

@Component({
  selector: 'favie-input',
  templateUrl: './favie-input.component.html',
})
export class FavieInputComponent extends BaseComponent {
  @Input() field: InputFormField;
  @Input() control: FormControl;

  protected onAfterViewInit() {
    super.onAfterViewInit();
    this.initValueWatcher();
  }

  private initValueWatcher() {
    if (this.field.inputType === FormFieldInputType.NUMBER) {
      this.subscribe(
        this.control.valueChanges.pipe(
          debounceTime(30),
          distinctUntilChanged(),
        ),
        val => {
          if (!val) {
            return;
          }
          if (this.field?.max && val > this.field.max) {
            this.control.setValue(this.field.max);
          } else if (this.field?.min && val < this.field.min) {
            this.control.setValue(this.field.min);
          }
        },
      );
    }
  }

  public onTestLink() {
    if (this.control.hasError(IS_URL_ERROR_TYPE)) {
      return;
    }
    let link = this.control.value;
    if (!(link.startsWith('http://') || link.startsWith('https://'))) {
      link = 'https://' + link;
    }
    if (link) {
      window.open(link, '_blank');
    }
  }

  public clearField() {
    this.control.setValue('');
  }
}
