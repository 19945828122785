export class PriceUtil {
  private static decimalSeparator = ',';
  private static thousandSeparator = ' ';
  private static padding = '000000';

  public static toString(value: string, fractionSize: number = 0): string {
    value = (value + '').replace(',', '.');
    if (parseFloat(value) % 1 !== 0) {
      fractionSize = 2;
    }
    let [integer, fraction = ''] = (parseFloat(value).toString() || '')
      .toString()
      .split('.');

    fraction =
      fractionSize > 0
        ? this.decimalSeparator +
          (fraction + this.padding).substring(0, fractionSize)
        : '';
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);
    if (isNaN(parseFloat(integer))) {
      integer = '0';
    }
    return integer + fraction;
  }

  public static toNumber(value: string, fractionSize: number = 0) {
    let stringValue = this.toString(value, fractionSize);
    stringValue = stringValue.replace(' ', '');
    stringValue = stringValue.replace(',', '.');
    return Number(stringValue);
  }
}
