<section class="upload-pictures__section m__b--16">

  <favie-label *ngIf="labelOutSide" [label]="labelOutSide"></favie-label>

  <input type="file"
    accept="image/png,image/jpeg"
    hidden
    #fileInputElement
    (change)="onUploadedFiles($event)" />

  <div class="preview-image-container">
    <div class="preview-image" *ngIf="canUpload">

      <div class="btn-import" (click)="triggerUpload()">

        <div class="icon" >
          <i *ngIf="icon" [ngClass]="icon"></i>
          <img *ngIf="!icon && iconImage" [src]="iconImage" />
        </div>

        <span class="label" *ngIf="label">{{ label }}</span>
      </div>
    </div>

    <div class="preview-image m__r--8"  *ngFor="let image of images; let i = index" cdkDrag>
      <div class="preview-image__content">
        <img [src]="image.src" />
        <div class="preview-image__actions" *ngIf="showActions">
          <i class="preview-image__action icon-cancel-circle"
             (click)="remove(i)"></i>
        </div>
      </div>
    </div>
  </div>

</section>
