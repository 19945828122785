<favie-label
  *ngIf="field?.labelOutSide"
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<div
  class="favie-radio-container d__flex"
  [class.flex__direction--row]="isRowDirection"
  [class.flex__direction--column]="isColumnDirection"
  [class.align__items--center]="isRowDirection"
>
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <mat-radio-group
    [formControl]="control"
    [required]="field?.required"
    [style.flexDirection]="field?.flexDirection || 'row'"
    class="d__flex custom-radio__group"
  >
    <mat-radio-button
      class="custom-radio__button"
      *ngFor="let option of field?.options"
      [value]="option?.value"
      [disabled]="option?.disabled"
    >
      {{ option?.label }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</div>
