import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { DynamicSectionFormField, FormField } from '../favie-form/interfaces/form-field.interface';
import { FormInput } from '../favie-form/interfaces/form-input.interface';
import { Subscription } from 'rxjs';
import { DynamicFormFieldValue } from '../../modules/dynamic-form/models/dynamic-form';
import { FavieSectionComponent } from '../favie-section/favie-section.component';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'favie-dynamic-section',
  templateUrl: './favie-dynamic-section.component.html',
  styleUrls: ['./favie-dynamic-section.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ],
})
export class FavieDynamicSectionComponent extends BaseComponent {
  @Input() field: DynamicSectionFormField;
  @Input() control: FormControl;

  @ViewChildren(FavieSectionComponent) favieSectionComps!: QueryList<FavieSectionComponent>;

  public formInputs: FormInput[] = [];
  public formInputSubscriptions: Subscription[] = [];

  public isMaximumSection = false;

  protected onInit() {
    super.onInit();
    this.initForm();
  }

  public getValue() {
    const formsValue = [];
    const productForms = this.favieSectionComps.toArray();

    for (const [index, productForm] of productForms.entries()) {
      const formValue = productForm.getValue();
      formsValue.push(formValue);
    }
    return {
      valueId: this.field.valueId,
      values: formsValue,
      valueKey: this.field.key,
      formFieldId: this.field.formFieldId,
    };
  }

  public setValue(value: DynamicFormFieldValue[] = []) {
    value.forEach((v) => this.addSection(v));
  }

  public addSection(value?: DynamicFormFieldValue): void {
    const sectionFormFields = this.mapValueToSection(value?.value, this.field.formFields);
    const sectionFormInput = {
      valueId: value?.id,
      fields: sectionFormFields,
      isDynamic: true,
    } as FormInput;
    this.formInputs.push(sectionFormInput);
    if (this.formInputs.length === this.field.max) {
      this.isMaximumSection = true;
    }
  }

  public deleteSection(index): void {
    this.formInputs.splice(index, 1);

    if (this.formInputs.length !== this.field.max) {
      this.isMaximumSection = false;
    }
  }

  private initForm() {
    this.setValue(this.field.initValue || Array.of(this.field.min || 1));
  }

  private mapValueToSection(value: DynamicFormFieldValue, sectionFormFields: FormField[]): FormField[] {
    value = value || {} as DynamicFormFieldValue;
    return sectionFormFields.map(formField => ({
      ...formField,
      initValue: value[formField.key]?.value || formField.initValue,
      valueId: value[formField.key]?.valueId
    }));
  }
}
