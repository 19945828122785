import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { CommonConstant } from '../../constants/common.constant';
import { BaseComponent } from '../base-component/base.component';

export interface FavieImageTemp {
  file?: File;
  src?: any;
  event?: any;
}

@Component({
  selector: 'favie-upload-pictures',
  templateUrl: './upload-pictures.component.html',
  styleUrls: ['./upload-pictures.component.scss'],
})
export class UploadPicturesComponent extends BaseComponent {
  private readonly DEFAULT_SIZE_LIMIT = environment.imageUploadMaximumFileSize ?
    environment.imageUploadMaximumFileSize / 1024 :
    1024 * 1024;

  @Input() label: string;
  @Input() icon: string;
  @Input() iconImage: string;
  @Input() limitImages = 3;
  @Input() limitSize = this.DEFAULT_SIZE_LIMIT;
  @Input() images: FavieImageTemp[] = [];
  @Input() labelOutSide: string;
  @Input() showActions = true;

  @Output() imagesChange: EventEmitter<FavieImageTemp[]> = new EventEmitter<FavieImageTemp[]>();

  @ViewChild('fileInputElement') fileInputElement: ElementRef;

  get canUpload() {
    return this.images && this.images.length < this.limitImages;
  }

  constructor(
    private readonly matSnackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  public triggerUpload() {
    this.fileInputElement.nativeElement.click();
  }

  public remove(index: number) {
    this.images = this.images.filter((value, i) => i !== index);
    this.notifyChange();
  }

  public onUploadedFiles($event) {
    const files: File[] = $event.target.files;
    if (files.length > 1) {
      this.matSnackBar.open(
        'One file per time please!',
        null,
        CommonConstant.FAILURE_SNACKBAR_CONFIG
      );
      return;
    }

    const readFile = (file) => {
      if (file.size > 1024 * this.limitSize) {

        this.matSnackBar.open(
          this.translateService.instant('Global.UploadPicture.Error.Size', {size: this.limitSize}),
          null,
          CommonConstant.FAILURE_SNACKBAR_CONFIG,
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images = [...this.images, {
          file,
          src: e.target.result,
          event: $event,
        }];
        this.notifyChange();
      };
      reader.readAsDataURL(file);
    };

    if (files.length > 0) {
      readFile(files[0]);
    }
  }

  onInit() {
    this.label = this.label;
  }

  private notifyChange() {
    this.imagesChange.emit(this.images);
  }
}
