<favie-label [label]="field?.labelOutSide"
  [required]="field?.required"></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <div [class.input__icon]="field?.icon"
    class="input__wrapper">
    <input matInput
      [formControl]="control"
      [type]="field?.inputType || 'password'"
      [placeholder]="field?.placeholder || ''"
      [required]="field?.required"
      [minlength]="field?.minLength"
      [maxlength]="field?.maxLength"
      [pattern]="field?.pattern" />
    <span matSuffix
      *ngIf="field?.suffix">{{ field?.suffix }}</span>
    <i *ngIf="field?.icon"
      [ngClass]="field?.icon"
      (click)="field?.handleIcon(field)"></i>
  </div>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
