import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonConstant } from 'src/common/constants/common.constant';
import { ConsentTypeEnum } from 'src/common/enums/consent.enum';
import { SocialProviderEnum } from 'src/common/enums/social-provider.enum';
import { FavieAuthService } from 'src/common/modules/auth/services/favie-auth.service';
import { FirebaseService } from 'src/common/modules/firebase/services/firebase.service';
import { FormFieldInputType } from '../../../../components/favie-form/enums/form-field-input-type.enum';
import { FormFieldType } from '../../../../components/favie-form/enums/form-field-type.enum';
import { FormInput } from '../../../../components/favie-form/interfaces/form-input.interface';
import { UserService } from '../../../user/services/user.service';
import { AuthGqlService } from './auth-qgl.service';
import firebase from 'firebase';

type UserSignupInput = {
  email?: string;
  firstName: string;
  lastName: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private socialMediaSignUp$ = new BehaviorSubject<any>(null);

  constructor(
    protected readonly firebaseService: FirebaseService,
    protected readonly favieAuthService: FavieAuthService,
    protected readonly userService: UserService,
    protected readonly authGqlService: AuthGqlService,
    private readonly translateService: TranslateService,
  ) { }

  public async signUp(value: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    country?: string;
    answer1: string;
    answer2: string;
  }) {
    const userCredential = await this.firebaseService.createUserWithEmailAndPassword(
      value.email,
      value.password
    );
    this.favieAuthService.setFirebaseUserCredential(userCredential);

    // delete value.email;
    delete value.password;

    const input = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      answer1: value.answer1,
      answer2: value.answer2,
      country: undefined,
    };

    if (value.country) {
      input.country = value.country;
    }

    await this.authGqlService.signUp(input).toPromise();

    const userToken = await this.getUserToken(userCredential);
    this.favieAuthService.setUserToken(userToken);
  }

  public async signIn(input: { email: string; password: string }) {
    const userCredential = await this.firebaseService.signInWithEmailAndPassword(
      input.email,
      input.password
    );
    await this.setUserCredentialAndToken(userCredential);
  }

  public signOut() {
    this.firebaseService.signOut();
    this.favieAuthService.logout();
  }

  public async signInByGoogle() {
    await this.firebaseService.signinByGoogle();
  }

  public async signInByFacebook() {
    await this.firebaseService.signinByFacebook();
  }

  public async signInByApple() {
    await this.firebaseService.signinByApple();
  }

  public getRedirectUser() {
    return this.firebaseService.getRedirectLoginResult();
  }

  public async handleRedirectUser(
    userCredential: firebase.auth.UserCredential
  ) {
    if (userCredential.additionalUserInfo?.isNewUser) {
      const input = this.getRedirectUserSignupInput(userCredential);
      await this.authGqlService.signUp(input).toPromise();
    }
    await this.setUserCredentialAndToken(userCredential);
    this.socialMediaSignUp$.next(userCredential);
  }

  public async changePassword(value: {
    email: string;
    password: string;
    newPassword: string;
  }) {
    const userCredential = await this.firebaseService.signInWithEmailAndPassword(
      value.email,
      value.password
    );
    this.favieAuthService.setFirebaseUserCredential(userCredential);

    await this.firebaseService.changePassword(value.newPassword);

    const userToken = await this.getUserToken(userCredential);
    this.favieAuthService.setUserToken(userToken);
  }

  public async updateEmail(value: {
    email: string;
    password: string;
    newEmail: string;
  }) {
    const userCredential = await this.firebaseService.signInWithEmailAndPassword(
      value.email,
      value.password
    );
    this.favieAuthService.setFirebaseUserCredential(userCredential);

    await this.firebaseService.updateEmail(value.newEmail);

    const userToken = await this.getUserToken(userCredential);
    this.favieAuthService.setUserToken(userToken);
  }

  public async forgotPassword(input) {
    await this.authGqlService.forgotPassword(input).toPromise();
  }

  public async resetPassword(input) {
    return await this.authGqlService.resetPassword(input).toPromise();
  }

  public getResetPasswordFormInput(): FormInput {
    const input: FormInput = {
      fields: [
        {
          key: 'newPassword',
          type: FormFieldType.INPUT,
          inputType: FormFieldInputType.PASSWORD,
          label: this.translateService.instant('newPassword'),
          validators: [Validators.required],
          required: true,
        },
        {
          key: 'passwordConfirmation',
          type: FormFieldType.INPUT,
          inputType: FormFieldInputType.PASSWORD,
          label: this.translateService.instant('passwordConfirmation'),
          validators: [Validators.required],
          required: true,
        },
      ],
      primaryButtonLabel: this.translateService.instant('changePassword'),
    };
    return input;
  }

  public getSocialMediaSignUpSubject(): Observable<any> {
    return this.socialMediaSignUp$.asObservable();
  }

  private async getUserToken(userCredential: firebase.auth.UserCredential, forceRefresh?: boolean) {
    const user = userCredential.user;
    const token = await user.getIdToken(forceRefresh);
    const refreshToken = user.refreshToken;
    return { token, refreshToken };
  }

  public async setUserCredentialAndToken(userCredential: firebase.auth.UserCredential) {
    await this.userService.setUserClaims().toPromise();
    const userToken = await this.getUserToken(userCredential, true);
    this.favieAuthService.setUserToken(userToken);
    this.favieAuthService.setFirebaseUserCredential(userCredential);
  }

  public getConsentByUserId(): Observable<any> {
    const type = ConsentTypeEnum.TERM_AND_CONDITION;
    return this.authGqlService.getConsentByUserId(type)
      .pipe(
        map(({ data }: any) => data),
        catchError((error) => {
          return of(null);
        })
      );
  }

  public updateConsent() {
    const type = ConsentTypeEnum.TERM_AND_CONDITION;
    return this.authGqlService.updateConsent(type)
      .pipe(
        map(({ data }: any) => data.updateConsent),
        catchError((error) => {
          return of(error);
        })
      );
  }

  public async signInByPhone(phoneNumber: string, verifier: firebase.auth.RecaptchaVerifier) {
    return await this.firebaseService.signInByPhone(phoneNumber, verifier);
  }

  public async getCurrentUser() {
    return await this.firebaseService.getCurrentUser();
  }

  public isUserLinkWithPhone(user: firebase.User) {
    const providers = user.providerData;
    return providers.some((provider: firebase.UserInfo) => {
     return provider.providerId === CommonConstant.FIREBASE_PHONE_PROVIDER;
    });
  }

  private getRedirectUserSignupInput(user: firebase.auth.UserCredential): UserSignupInput {
    const input = {
      email: '',
      lastName: '',
      firstName: '',
    };
    switch (user.additionalUserInfo.providerId) {
      case SocialProviderEnum.FACEBOOK: {
        input.email = user.user.email;
        input.firstName = user.additionalUserInfo.profile[CommonConstant.FACEBOOK_FIRST_NAME_KEY];
        input.lastName = user.additionalUserInfo.profile[CommonConstant.FACEBOOK_LAST_NAME_KEY];
        break;
      }
      case SocialProviderEnum.GOOGLE: {
        input.email = user.user.email;
        input.firstName = user.additionalUserInfo.profile[CommonConstant.GOOGLE_FIRST_NAME_KEY];
        input.lastName = user.additionalUserInfo.profile[CommonConstant.GOOGLE_LAST_NAME_KEY];
        break;
      }
      case SocialProviderEnum.APPLE: {
        input.email = user.user.email;
        input.firstName = user.user.displayName;
        input.lastName = CommonConstant.EMPTY;
      }
    }

    input.email = input.email || CommonConstant.EMPTY;
    input.firstName = input.firstName || CommonConstant.EMPTY;
    input.lastName = input.lastName || CommonConstant.EMPTY;

    return input;
  }
}
