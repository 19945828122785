import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FavieFormComponent } from '../favie-form/favie-form.component';
import { FormInput } from '../favie-form/interfaces/form-input.interface';

@Component({
  selector: 'favie-section',
  templateUrl: './favie-section.component.html',
  styleUrls: ['./favie-section.component.scss'],
})
export class FavieSectionComponent extends BaseComponent {
  @Input() formInput: FormInput;
  @Input() formInitValues: any;
  @ViewChild('formComponent') formComponent: FavieFormComponent;

  public getValue() {
    const formValues = this.formComponent.getValue();
    return {
      valueId: this.formInput.valueId,
      values: formValues,
    };
  }
}
