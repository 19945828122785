import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { FirebaseService } from '../modules/firebase/services/firebase.service';

export const InterceptorIgnoreAuthorization = 'X-Ignore-Language-Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly firebaseService: FirebaseService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.firebaseService.getIdToken().pipe(
      first(),
      switchMap((token: any) => {
        const ignore = request.headers.has(InterceptorIgnoreAuthorization);
        let headers = request.headers;
        headers = request.headers.delete(InterceptorIgnoreAuthorization);

        if (!ignore && token) {
          const authorizationValue = 'Bearer ' + token;
          headers = headers.set('Authorization', authorizationValue);
        }
        const changedRequest = request.clone({ headers });
        return next.handle(changedRequest);
      })
    );
  }
}
