import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home-page/home-page.module').then((m) => m.HomePageModule),
  },
  {
    path: 'formation',
    loadChildren: () => import('./modules/formation/formation.module').then((m) => m.FormationModule),
  },
  {
    path: 'domiciliation',
    loadChildren: () => import('./modules/domiciliation/domiciliation.module').then((m) => m.DomiciliationModule),
  },
  {
    path: 'space-rental',
    loadChildren: () => import('./modules/space-rental/space-rental.module').then((m) => m.SpaceRentalModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then((m) => m.SemyosContactModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
