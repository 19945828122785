<section class="favie-page-content-layout">
  <div class="page-content-layout-header">
    <div class="page-content-layout-header-content">
      <div class="page-content-layout-header-title">
        <ng-content select="[title]"></ng-content>
      </div>

      <div class="spacing"></div>

      <div class="page-content-layout-header-action">
        <ng-content select="[action]"></ng-content>
      </div>
    </div>
  </div>

  <div class="page-content-layout-content">
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="page-content-layout-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</section>
