import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { TextareaFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-textarea',
  templateUrl: './favie-textarea.component.html',
})
export class FavieTextareaComponent extends BaseComponent {
  @Input() field: TextareaFormField;
  @Input() control: FormControl;
}
