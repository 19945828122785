<section class="favie__main-layout">
  <favie-header *ngIf="isHeaderVisible">
    <ng-content select="[header]"></ng-content>
  </favie-header>

  <div
    class="layout-page"
    [class.page-with-contextual-sidebar]="showSidebar"
    [class.page-with-contextual-expanded-sidebar]="
      showSidebar && isExpandSidebar && currentLayout !== layouts.MOBILE
    "
  >
    <favie-sidebar
      [logo]="sidebarLogo"
      [items]="sidebarItems"
      (mouseover)="onMouseHoverSidebar(true)"
      (mouseleave)="onMouseHoverSidebar(false)"
    >
    <ng-content header select="[sidebar-header]"></ng-content>
    <ng-content footer select="[sidebar-footer]"></ng-content>
    </favie-sidebar>
    <div
      class="content__wrapper"
      [class.content__wrapper--with-contextual-header]="isHeaderVisible"
    >
      <div class="alert__wrapper"></div>
      <div class="container-fluid container-limited limit-container-width">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  </div>

  <favie-footer>
    <ng-content select="[footer]"></ng-content>
  </favie-footer>
</section>
