import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { TimePickerFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-time-picker',
  templateUrl: './favie-time-picker.component.html',
  styleUrls: ['./favie-time-picker.component.scss'],
})
export class FavieTimePickerComponent extends BaseComponent {
  @Input() field: TimePickerFormField;
  @Input() control: FormControl;
  @Output() onTimeChanged = new EventEmitter<string | Date>(null);
}
