import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface ButtonHeader {
  label: string;
  image?: {
    src?: string;
    alt?: string;
  };
  action?: () => void;
}

@Component({
  selector: 'favie-simple-header-button',
  templateUrl: './simple-header-button.component.html',
})
export class SimpleHeaderButtonComponent extends BaseComponent {
  @Input() buttonHeader: ButtonHeader;
}
