import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private languagesData: any;

  constructor() {
    this.languagesData = require('../jsons/languages.json');
  }

  public getAllLanguages() {
    const languages = Object.keys(this.languagesData)
      .map(v => this.mapLanguageOption(v));
    return languages;
  }

  public getLanguages(codes: string[]) {
    const languages = codes
      .filter(v => this.languagesData[v])
      .map(v => this.mapLanguageOption(v));
    return languages;
  }

  public getSupportedLanguages() {
    const supportedLanguages = environment.supportedLanguages;
    const languages = this.getLanguages(supportedLanguages);
    return languages;
  }

  public getLanguageDisplayName(languageCode: string): string {
    return String(this.languagesData[languageCode]?.native) || languageCode;
  }

  private mapLanguageOption(key: string) {
    return {
      value: key,
      label: this.getLanguageDisplayName(key),
    };
  }
}
