import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { PriceFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-price',
  templateUrl: './favie-price.component.html',
})
export class FaviePriceComponent extends BaseComponent {
  @Input() field: PriceFormField;
  @Input() control: FormControl;
}
