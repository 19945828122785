<section class="favie-web-admin-signin-form__section">
  <div class="title-container" *ngIf="input?.title">
    <span class="title">{{ input.title }}</span>
  </div>

  <div class="form-container">
    <favie-form [formInput]="formInput"
      [contentTemplate]="contentTemplate"
      (primaryEmitter)="onSubmitted($event)"
      (secondaryEmitter)="onForgotPassword()"></favie-form>
  </div>
</section>
