<favie-label
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <textarea
    matInput
    [formControl]="control"
    [rows]="field?.maxrows || 3"
    [placeholder]="field?.placeholder || ''"
    [required]="field?.required"
    [maxlength]="field?.maxLength"
    [minlength]="field?.minLength"
  ></textarea>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
