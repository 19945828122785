<section
  class="favie-content-header__section d__flex justify__content--between align__items--start"
>
  <ng-template [ngIf]="value && value.length">
    <div class="content-header-item" *ngFor="let item of value">
      <span class="content-label">
        {{ item.label }}
      </span>

      <span class="content-value">
        {{ item.value }}
      </span>
    </div>
  </ng-template>
</section>
