import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

export interface BreadcrumbOption {
  label: string;
  url?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'favie-breadcrumb',
  exportAs: 'favieBreadcrumb',
  preserveWhitespaces: false,
  template: `
    <ng-container>
      <favie-breadcrumb-item
        *ngFor="let breadcrumb of breadcrumbs"
        [breadcrumb]="breadcrumb"
      >
      </favie-breadcrumb-item>
    </ng-container>
  `,
})
export class FavieBreadCrumbComponent {
  @Input() breadcrumbs: BreadcrumbOption[] | undefined = [];
  @Input() separator?: string;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    renderer.addClass(elementRef.nativeElement, 'favie-breadcrumb');
  }
}
