import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { LandingPageMainContent } from '../../interfaces/landing-page-main-content.interface';

@Component({
  selector: 'favie-landing-page-content-main',
  templateUrl: './landing-page-content-main.component.html',
})
export class LandingPageContentMainComponent extends BaseComponent {
  @Input() content: LandingPageMainContent;
  @ViewChild('fileInputElement') fileInputElement: ElementRef;
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    super();
  }

  onInit() {}

  scrollNext() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        behavior: 'smooth',
        top:
          this.fileInputElement.nativeElement.offsetTop +
          this.fileInputElement.nativeElement.offsetHeight +
          90,
      });
    }
  }
}
