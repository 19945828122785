<section class="simple-header-items__section" fxLayout="row" fxLayoutGap="44px">
  <div *ngFor="let section of sections">
    <div
      class="simple-header-items__content"
      fxLayout="column"
      fxLayoutAlign="center"
      [class.active]="section?.selected"
    >
      <div (click)="section.action(section.id)">
        <span>{{ section.label }}</span>
        <mat-icon *ngIf="section.children">expand_more</mat-icon>
      </div>
      <div
        class="simple-header-items__status full-width"
        *ngIf="section.selectStatus"
      ></div>

      <div class="sub-menu-container" [class.expanded]="section.isExpand" *ngIf="section.children">
        <div *ngFor="let item of section.children" (click)="item.action(item)">
          <span class="sub-menu-name">{{item.label}}</span>
        </div>
      </div>
    </div>
  </div>
</section>
