<section class="image-cropper-dialog__section form-container">
  <div fxLayoutAlign="center center" class="m__b--16">
    <h1 *ngIf="data?.title">{{ data?.title }}</h1>
  </div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    format="png,jpeg"
    (imageCropped)="imageCropped($event)"
    (loadImageFailed)="loadImageFailed()"
    [cropperMinWidth]="64"
    [alignImage]="'center'"
  ></image-cropper>

  <div
    class="m__t--32"
    fxLayout="row-reverse"
    fxLayout.lt-sm="column"
    fxLayoutGap="16px"
    fxLayoutAlign="space-around center"
  >
    <button
      mat-raised-button
      type="submit"
      (click)="confirmImage()"
    >
      {{ 'cropper.confirm' | translate }}
    </button>
    <button
      class="secondary_btn"
      mat-stroked-button
      type="button"
      (click)="close()"
    >
      {{ 'cropper.cancel' | translate }}
    </button>
  </div>
</section>
