<div
  class="w-100 overflow-auto"
  infiniteScroll="infiniteScroll"
  [scrollWindow]="false"
  [infiniteScrollContainer]="scrollContainer || null"
  [infiniteScrollDisabled]="isLoading"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="onScroll()"
>
  <div
    class="infinite-scroll__content h-100"
    [class.max-width]="limitItemWidth"
    [hidden]="firstLoad"
  >
    <ng-content select="[search-filter]"></ng-content>

    <div *ngIf="total > 0">
      <ng-content select="[search-result]"></ng-content>
    </div>

    <div class="w-100 overflow-hidden">
      <div
        [ngClass]="{ 'fav__spinner m__v--24': isLoading && !firstLoad }"
      ></div>
    </div>

    <div
      class="fav__empty w-100 h-100"
      *ngIf="!isLoading && !total"
      responsive="responsive"
    >
      <ng-content select="[search-result-empty]"></ng-content>
    </div>
  </div>
  <div class="fav__empty w-100 overflow-hidden" *ngIf="firstLoad">
    <div class="fav__spinner m__v--24"></div>
  </div>

  <button
    class="scroll__top__btn padding--0 fav__btn fav__btn--fixed fav__btn--large fav__circle fav__btn--primary"
    type="button"
    (click)="scrollToTop()"
    *ngIf="showScrollToTop"
  >
    <i class="icon-arrow-up margin--0"></i>
  </button>
</div>
