<section class="burger-menu__section d__flex align__items--center">
  <div class="user-actions d__flex align__items--center burger-menu__user">
    <div class="user-actions-box" fxLayout="column" fxLayoutAlign>
      <favie-sign-in-button
        *ngIf="!isLoggedIn"
        [form]="formSignInSignUp"
      ></favie-sign-in-button>

      <a
        class="user-name"
        routerLink="/user"
        *ngIf="isLoggedIn && fullName"
        fxLayoutAlign="space-between center"
        (click)="closeBurger()"
      >
        <div>
          <i class="icon-user-circle m__l--24"></i>
          <span class="m__l--8">
            {{ fullName }}
          </span>
        </div>
        <img
          src="./assets/icons/right-arrow-fill.svg"
          class="user-toggle m__r--10"
        />
      </a>
      <div
        class="user-actions-infor p__v--16"
        fxLayout="column"
        fxLayoutAlign="space-between start"
      >
        <div
          class="p__b--24 m__b--24 menu-section"
          fxLayout="column"
          fxFlex="44%"
          fxLayoutAlign="center center"
        >
          <div
            class="menu-moble"
            *ngFor="let item of header?.menu"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="16px"
          >
            <a routerLink="{{ item.routerLink }}" (click)="closeBurger()">
              <p>{{ item.name }}</p>
            </a>
          </div>
          <a
            class="m__t--10 d__flex align__items--center justify__content--center"
            routerLink="{{ header?.askvet.routerLink }}"
            (click)="closeBurger()"
            *ngIf="header?.askvet"
          >
            <button mat-raised-button color="primary">
              <img [src]="header.askvet.src" />
              <span> {{ header.askvet.label }}</span>
            </button>
          </a>
        </div>
        <div
          class="terms"
          fxLayout="column"
          fxFlex="30%"
          fxLayoutAlign="space-around start"
          (click)="closeBurger()"
          *ngFor="let item of header?.policy"
        >
          <a routerLink="{{ item.routerLink }}" (click)="closeBurger()">
            <p>{{ item.name }}</p>
          </a>
        </div>
        <div
          class="p__v--16 logout-section"
          fxLayout="row"
          fxFlex="9%"
          fxLayoutAlign="space-around center"
        >
          <a
            class="logout"
            fxLayout="row"
            fxLayoutAlign="center center"
            (click)="logout()"
            *ngIf="isLoggedIn"
          >
            <i class="icon-log-out"></i>
            <span class="m__l--8">{{ 'logOut' | translate }}</span>
          </a>
          <favie-language-switcher></favie-language-switcher>
        </div>
        <div
          class="stayConnect p__v--16"
          fxFlex="14%"
          fxLayout="column"
          fxLayoutAlign="center center"
          *ngIf="header?.staySocial"
        >
          <p>{{ header?.staySocial }}</p>
          <div
            class="social__icon"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <a
              *ngFor="let item of header?.social"
              href="{{ item.routerLink }}"
              target="_blank"
              [ngSwitch]="item.name"
            >
              <i *ngSwitchCase="'fb'" class="icon-facebook"></i>
              <i *ngSwitchCase="'youtube'" class="icon-youtube m__l--22"></i>
              <i
                class="icon-instagram m__l--22"
                *ngSwitchCase="'instagram'"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
