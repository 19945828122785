<favie-label
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <div class="d__flex" class="input__wrapper">
    <input
      matInput
      [formControl]="control"
      [placeholder]="field?.placeholder || ''"
      [required]="field?.required"
      [minlength]="field?.minLength"
      [maxlength]="field?.maxLength"
      [pattern]="field?.pattern"
      favieCurrency
      [maxAmount]="field?.maxAmount"
    />
    <span *ngIf="field?.suffix">{{ field?.suffix }}</span>
    <i class="icon-currency font__size--inherit"></i>
  </div>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
