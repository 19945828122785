import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'favie-drag-drop',
  templateUrl: './favie-drag-drop.component.html',
})
export class FavieDragDropComponent extends BaseComponent {
  @Input() id: string;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() items: any[];
  @Input() dropListIds: string[];
  @Input() enterPredicate: (item: CdkDrag) => boolean = (item: CdkDrag) => true;

  @Output() dropped = new EventEmitter<CdkDragDrop<[]>>();

  public drop(event: CdkDragDrop<[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.dropped.emit(event);
  }
}
