import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { FavieAuthService } from '../services/favie-auth.service';

@Component({
  selector: 'favie-auth',
  templateUrl: './favie-auth.component.html',
})
export class FavieAuthComponent extends BaseComponent {

  private readonly URL_HOMEPAGE = '';

  constructor(
    private readonly router: Router,
    private readonly authService: FavieAuthService,
  ) {
    super();
  }

  onInit() {
    if (this.authService.getToken()) {
      this.router.navigateByUrl(this.URL_HOMEPAGE);
    }
  }

}
