import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';

const queryGetMyProfile = gql`
  query getMyProfile {
    getMyProfile {
      firstName
      lastName
      id
      email
      country
      profileUrl
    }
  }
`;

const queryCanUserEmailAccessWebAdmin = gql`
  query CanUserEmailAccessWebAdmin($email: String!) {
    canUserEmailAccessWebAdmin(email: $email)
  }
`;

const mutationSetUserClaims = gql`
  mutation UserClaims {
    setUserClaims {
      statusCode
    }
  }
`;

const mutationUpdateUserProfile = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      message
      statusCode
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class UserGqlService {
  constructor(private readonly apollo: Apollo) {}

  getMyProfile() {
    return this.apollo.query({
      query: queryGetMyProfile,
      fetchPolicy: 'network-only',
    });
  }

  canUserEmailAccessWebAdmin(email: string) {
    return this.apollo
      .query({
        query: queryCanUserEmailAccessWebAdmin,
        variables: { email },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(
          (
            result: ApolloQueryResult<{ canUserEmailAccessWebAdmin: boolean }>
          ) => {
            const data = result.data;
            return data.canUserEmailAccessWebAdmin;
          }
        )
      );
  }

  setUserClaims() {
    return this.apollo.mutate({
      mutation: mutationSetUserClaims,
    });
  }

  updateUserProfile(input) {
    return this.apollo.mutate({
      mutation: mutationUpdateUserProfile,
      variables: { input },
    });
  }
}
