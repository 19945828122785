<div *ngIf="!files.length; else preview">
  <favie-label
    [label]="labelOutSide"></favie-label>
  <div class="dropzone" appFileDragDrop (files)="onFileChange($event)" (click)="triggerUpload()">
    <div class="text-wrapper">
      <div class="centered">
        <input #fileInputElement type="file" accept="image/png,image/jpeg" name="file" id="file" (change)="onUploadedFiles($event)" />
        <div class="wrapper-icon">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8333 2.66667V21.3333H3.16667V2.66667H21.8333ZM21.8333 0H3.16667C1.7 0 0.5 1.2 0.5 2.66667V21.3333C0.5 22.8 1.7 24 3.16667 24H21.8333C23.3 24 24.5 22.8 24.5 21.3333V2.66667C24.5 1.2 23.3 0 21.8333 0ZM15.3533 11.8133L11.3533 16.9733L8.5 13.52L4.5 18.6667H20.5L15.3533 11.8133Z" fill="#8000E5"/>
          </svg>
          <span>Upload Image</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #preview>
  <div class="row fileItem" *ngFor="let file of files; let i = index">
    <i class="preview-image__action action-cancel-circle icon-cancel-circle" (click)="remove(i)"></i>
    <div class="col-sm-12 fileItemText">
      <img [src]="file.src" alt="">
    </div>
  </div>
</ng-template>
