import { ApplicationRef, Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class NgSwCheckForUpdateService {
  constructor(
    @Optional() private readonly appRef: ApplicationRef,
    @Optional() private readonly updates: SwUpdate
  ) {}

  init() {
    if (this.appRef && this.updates) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$
      );

      // Application check for update for every 6hs.
      everySixHoursOnceAppIsStable$.subscribe(() => {
        this.checkForUpdate();
      });

      // Application check for update first time after load application
      this.checkForUpdate();
    }
  }

  private checkForUpdate() {
    this.updates.checkForUpdate();
    console.log('Check for update application version.');
  }
}
