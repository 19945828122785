<mat-form-field appearance="fill" (click)="datepicker.open()">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <div class="mat__datepicker" class="input__wrapper">
    <input
      matInput
      [matDatepicker]="datepicker"
      [formControl]="control"
      [required]="field?.required"
      [min]="field?.minDate"
      [max]="field?.maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker
      #datepicker
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event, datepicker)"
    >
    </mat-datepicker>
  </div>
  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
