import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IconSocials, SectionsFooter } from 'src/common/components/footer/components/simple-footer/simple-footer.component';
import { SimpleHeaderService } from 'src/common/components/header/components/simple-header-items/services/simple-header.service';
import { LogoHeader } from 'src/common/components/header/components/simple-header/simple-header.component';
import { SectionHeader } from '../common/components/header/components/simple-header-items/simple-header-items.component';
import { FooterGroup, HeaderExpandPanel1, HeaderExpandPanel2, HeaderExpandPanel3, HeaderSectionItem } from './enum/enum';
import { ScrollPositionSection } from './enum/scroll-position-section.enum';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private readonly simpleHeaderService: SimpleHeaderService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
  }

  public bidingDataHeader(): LogoHeader {
    const logo: LogoHeader = {
      image: {
        src: './assets/icons/logo.svg',
        alt: 'SEMYOS'
      },
      action: () => this.router.navigate([''])
    };
    return logo;
  }

  public getMenu() {
    return {
      image: {
        src: './assets/icons/burger-menu.svg',
        alt: 'MENU'
      }
    };
  }

  public getSectionHeader() {
    const sectionHeader: SectionHeader[] = [
      {
        id: HeaderSectionItem.FORMATIONS,
        label: this.translateService.instant('Formations'),
        action: (label) => this.handleSectionHeader(label, 'formation'),
        children: [
          {
            id: HeaderExpandPanel1.IMMOBILIER,
            label: this.translateService.instant('Header.panel1.immobilier'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/immobilier')
          },
          {
            id: HeaderExpandPanel1.UNIVERDULUXE,
            label: this.translateService.instant('Header.panel1.univers'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/univers-luxe')
          },
          {
            id: HeaderExpandPanel1.LESESSENTIELSDELS,
            label: this.translateService.instant('Header.panel1.les'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/essentiels-entreprise')
          },
          {
            id: HeaderExpandPanel1.NOSFORMATIONS,
            label: this.translateService.instant('Header.panel1.nosformations'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/nos-formations', {section: ScrollPositionSection.OUR_TRAINING})
          },
          {
            id: HeaderExpandPanel1.APROSPOS,
            label: this.translateService.instant('Header.panel1.propos'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/about')
          },
          {
            id: HeaderExpandPanel1.ZOOM,
            label: this.translateService.instant('Header.Panel1.handicap'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'formation/about', {section: ScrollPositionSection.HANDICAP})
          },
          {
            id: HeaderExpandPanel1.ZOOM,
            label: this.translateService.instant('Header.Panel1.Zoom'),
            selected: false,
            action: () => {
              const url = 'https://firebasestorage.googleapis.com/v0/b/semyoswebsite.appspot.com/o/public-resources%2FZoom%20sur%20nos%20certifications.pdf?alt=media&token=129529d1-f3ce-46cd-869c-d0a02e98ca1b';
              window.open(url, '__blank');
            }
          }
        ]
      },
      {
        id: HeaderSectionItem.DOMICILIATION,
        label: this.translateService.instant('Domiciliation'),
        action: (label) => this.handleSectionHeader(label, 'domiciliation'),
        children: [
          {
            id: HeaderExpandPanel2.NOSOFFRES,
            label: this.translateService.instant('Header.panel2.offres'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'domiciliation/our-offers')
          },
          {
            id: HeaderExpandPanel2.SECRETARIAT,
            label: this.translateService.instant('Header.panel2.secretariat'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'domiciliation/our-offers', {section: ScrollPositionSection.SECRETARIAT})
          },
          {
            id: HeaderExpandPanel2.ESPACE,
            label: this.translateService.instant('Header.panel2.espace'),
            selected: false,
            action: () => {
              const url = 'https://esp-aceurope.woisa.net/';
              window.open(url, '__blank');
            }
          }
        ]
      },
      {
        id: HeaderSectionItem.LOCATION,
        label: this.translateService.instant('Location'),
        action: (label) => this.handleSectionHeader(label, 'space-rental'),
        children: [
          {
            id: HeaderExpandPanel3.OUR_SPACE,
            label: this.translateService.instant('SpaceRental.Section1.Title'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'space-rental', {section: ScrollPositionSection.OUR_SPACE})
          },
          {
            id: HeaderExpandPanel3.SPACE_RENTAL_HOURS,
            label: this.translateService.instant('SpaceRental.Section2.Title'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'space-rental', {section: ScrollPositionSection.SPACE_RENTAL_HOURS})
          },
          {
            id: HeaderExpandPanel3.OUR_SERVICES,
            label: this.translateService.instant('SpaceRental.Section3.Title'),
            selected: false,
            action: (item) => this.handleSectionHeader(item.label, 'space-rental', {section: ScrollPositionSection.OUR_SERVICES})
          },
        ]
      },
      {
        id: HeaderSectionItem.CONTACT,
        label: this.translateService.instant('Contact'),
        action: (label) => this.handleSectionHeader(label, 'contact'),
      },
      {
        id: HeaderSectionItem.BLOG,
        label: this.translateService.instant('Blog'),
        action: (label) => this.handleSectionHeader(label, 'blog'),
      }
    ];
    return sectionHeader;
  }

  public handleSectionHeader(id: string, routerLink?: string, queryParams?) {
    this.simpleHeaderService.selectedHeader(id);
    if (routerLink) {
      this.router.navigate([routerLink], { queryParams });
    }
  }

  // Footer
  public getLogoBrand() {
    return {
      icon: './assets/icons/logo-footer'
    };
  }

  public bindingCompany() {
    const footer = {
      company: {
        tel: {
          id: FooterGroup.CONTACT,
          title: this.translateService.instant('Contact'),
          label: '04 94 19 62 60',
          action: () => this.callPhone(footer.company.tel.label),
        },
        address: {
          id: FooterGroup.ADDRESS,
          title: this.translateService.instant('Footer.Address'),
          label: this.translateService.instant('Footer.section.address'),
          action: () => this.openMap(footer.company.address.label)
        }
      },
      rights: {
        label: 'Designed and Developed by',
        image: {
          src: './assets/icons/copyright.svg',
          alt: 'Favie Tech makes apps for millions'
        }
      }
    };
    return footer;
  }

  public getSectionFooter() {
    const sectionFooter: SectionsFooter[] = [
      {
        id: HeaderSectionItem.FORMATIONS,

        label: this.translateService.instant('Formations'),
        action: (label) => this.handleSectionHeader(label, 'formation')
      },
      {
        id: HeaderSectionItem.DOMICILIATION,
        label: this.translateService.instant('Domiciliation'),
        action: (label) => this.handleSectionHeader(label, 'domiciliation')
      },
      {
        id: HeaderSectionItem.LOCATION,
        label: this.translateService.instant('Location'),
        action: (label) => this.handleSectionHeader(label, 'space-rental')
      },
      {
        id: HeaderSectionItem.CONTACT,
        label: this.translateService.instant('Contact'),
        action: (label) => this.handleSectionHeader(label, 'contact')
      },
      {
        id: HeaderSectionItem.BLOG,
        label: this.translateService.instant('Blog'),
        action: (label) => this.handleSectionHeader(label, 'blog'),
      }
    ];
    return sectionFooter;
  }

  public getSectionHome() {
    const home = [
      {
        label: this.translateService.instant('Footer.Liens'),
      },
      {
        id: FooterGroup.LIENS1,
        label: this.translateService.instant('Footer.link.section1'),
        action: (label) => this.handleSectionHeader(label, 'domiciliation/useful-links')
      },
      {
        id: FooterGroup.LIENS2,
        label: this.translateService.instant('Footer.link.section2'),
        action: (label) => this.handleSectionHeader(label, 'domiciliation/institutional-links')
      },
      {
        id: FooterGroup.LEGAL_NOTICE,
        label: this.translateService.instant('Footer.link.section3'),
        action: (label) => this.handleSectionHeader(label, 'domiciliation/legal-notice')
      },
    ];
    return home;
  }

  public getSocialItem() {
    const iconSocial: IconSocials[] = [
      {
        icon: './assets/icons/logos_linkedin.svg',
        link: 'https://www.linkedin.com/company/semyos/',
      }
    ];
    return iconSocial;
  }

  private openMap(address) {
    if (!address) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      const url = `https://maps.google.com/?q=${address}`;
      window.open(url, '_blank');
    }
  }

  private callPhone(phone) {
    if (!phone) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      const url = `tel:${phone}`;
      window.open(url, '_blank');
    }
  }
}
