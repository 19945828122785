<section class="header-mobile">
  <!-- Menu -->
  <ul class="menu-list">
    <li class="menu-item" (click)="onClick(item)" *ngFor="let item of header; let i = index">

      <mat-expansion-panel (opened)="onToggle(true)" (closed)="onToggle(false)"
        [expanded]="item.isSelected"
        *ngIf="item.sections; else sectionHeader">

        <mat-expansion-panel-header >
          <mat-panel-title [style.color]="!item.isSelected  ? tabColor : tabColorSelected ">
            {{ item.label }}
          </mat-panel-title>
        </mat-expansion-panel-header>

          <ng-container *ngFor="let subMenuItem of item.sections">
            <span class="sub-menu-item" (click)="subMenuItem.action()" > {{ subMenuItem.label }} </span>
          </ng-container>

      </mat-expansion-panel>
      <ng-template #sectionHeader>
        <span (click)="item.action()">{{item.label}}</span>
      </ng-template>
    </li>
  </ul>

  <!-- Footer - Mobile -->
  <div class="footer" [style.opacity]="opacityFooter">
    <div class="footer-section" *ngFor="let item of footer" >

      <span class="title-footer">{{item.label}}</span>

      <ng-container *ngIf="item.sections">
        <a class="section-link" (click)="section.action()" *ngFor="let section of item.sections"> {{ section.label }} </a>
      </ng-container>

    </div>
    <a href="https://www.linkedin.com/company/semyos/">
      <img loading="lazy" class="logo-social" src="./assets/icons/logos_linkedin.svg" alt="LinkedIn">
    </a>
  </div>

</section>