<favie-label
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <div class="mat__datepicker input__wrapper">
    <input
      matInput
      [formControl]="control"
      [placeholder]="field?.placeholder || ''"
      [required]="field?.required"
      [min]="field?.timeStart"
      [max]="field?.timeEnd"
      [ngxTimepicker]="timePicker"
      [format]="field?.format"
    />
    <ngx-material-timepicker-toggle matSuffix class="input__custom-suffix" [for]="timePicker">
      <i class="icon-clock"></i>
    </ngx-material-timepicker-toggle>
    <ngx-material-timepicker
      #timePicker
      [minutesGap]="field?.minutesGap"
      (timeChanged)="onTimeChanged.emit($event)"
    >
    </ngx-material-timepicker>
  </div>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
