import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/common/components/base-component/base.component';
import { FormInput } from 'src/common/components/favie-form/interfaces/form-input.interface';
import { LocalSpinnerService } from 'src/common/services/local-spinner.service';

@Component({
  selector: 'favie-sign-up',
  templateUrl: './favie-sign-up.component.html',
})
export class FavieSignUpComponent extends BaseComponent {
  @Input() form: FormInput;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cancelEmitter: EventEmitter<any> = new EventEmitter();

  public formInput: FormInput;

  private readonly localSpinnerId = 'sign-up-local-spinner';

  constructor(private readonly localSpinnerService: LocalSpinnerService) {
    super();
  }

  onInit() {
    super.onInit();
    this.initForm();
  }

  onSubmit(data: any) {
    data.localSpinnerId = this.localSpinnerId;
    this.submitEmitter.next(data);
  }

  onCancel() {
    this.cancelEmitter.next();
  }

  private initForm() {
    this.formInput = this.form;
    this.formInput.primaryLocalSpinnerId = this.localSpinnerId;
    this.localSpinnerService.registerComponent(this.localSpinnerId);
  }
}
