<section class="image-cropper__section">
  <favie-label [label]="field?.labelOutSide"
    [required]="field?.required"></favie-label>
  <div fxLayout="row">
    <favie-upload-pictures #uploadPicture
      *ngIf="!field?.disablePictureManagement"
      [class.hidden]="!canUpload"
      [label]="field?.label"
      [icon]="field?.icon"
      [iconImage]="field?.iconImage"
      [limitImages]="field?.limitImages || 1"
      [images]="field?.initValue || []"
      (imagesChange)="setImagesChange($event)"></favie-upload-pictures>

    <div fxLayoutGap="8px"
      fxLayout="row">
      <div class="image-cropper__preview"
        *ngFor="let image of cropperImagesPreview; index as i"
        cdkDrag>
        <img class="picture__preview"
          [src]="image?.src" />
        <div class="preview-image__actions">
          <div (click)="remove(i)"
            *ngIf="!field?.disablePictureManagement">
            <i class="preview-image__action"
              *ngIf="!field?.iconRemovePreviewPicture?.imageSrc"
              [ngClass]="getIconClass()"></i>
            <img *ngIf="field?.iconRemovePreviewPicture?.imageSrc"
              [src]="field?.iconRemovePreviewPicture?.imageSrc" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-error *ngIf="field?.errors?.length && control.touched">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</section>
