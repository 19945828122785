import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FavieAuthService } from 'src/common/modules/auth/services/favie-auth.service';
import { AuthService } from '../../../../modules/auth/components/services/auth.service';
import { BaseComponent } from '../../../base-component/base.component';
import { SectionHeader } from '../simple-header-items/simple-header-items.component';

@Component({
  selector: 'favie-burger-menu',
  templateUrl: './burger-menu.component.html',
})
export class BurgerMenuComponent extends BaseComponent {
  @Input() sections: SectionHeader[];
  @Input() header;
  @Input() formSignInSignUp;

  @Output() closeEmitter = new EventEmitter<any>();

  public isLoggedIn = false;
  public fullName: string;

  constructor(
    @Inject(LOCALE_ID)
    public readonly locale: string,
    private readonly router: Router,
    private readonly favieAuthService: FavieAuthService,
    private readonly authService: AuthService,
  ) {
    super();
  }

  public logout() {
    this.authService.signOut();
    this.favieAuthService.logout();

    this.router.navigate(['/']);
  }

  public closeBurger() {
    this.closeEmitter.next();
  }

  public setFullName(fullName: string) {
    this.fullName = fullName;
  }

  onInit() {
    this.subscribe(this.favieAuthService.getLogged(), (isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn) {
        this.fullName = undefined;
        this.favieAuthService.setUserProfile(undefined);
      }
    });
  }
}
