<section class="sign-in-button__section">
  <button mat-raised-button color="primary" (click)="toggleDialog()">
    <img *ngIf="form?.imageSignIn"
    [src]="form?.imageSignIn?.src"
    [style.max-width]="form?.imageSignIn?.maxWidth"
    [style.max-height]="form?.imageSignIn?.maxHeight" 
    alt="sign-in">
    <span>{{ 'signin' | translate }}</span>
  </button>
</section>
