import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, InjectionToken, Injector, PLATFORM_ID, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Layouts, ResponsiveLayoutService } from 'src/common/services/responsive-layout.service';
import { environment } from '../environments/environment';
import { BaseComponent } from '../common/components/base-component/base.component';
import { SectionHeader } from '../common/components/header/components/simple-header-items/simple-header-items.component';
import {
  FormatDateAdapter,
  FORMAT_DD_MM_YYYY
} from '../common/services/format-datepicker.service';
import { NgSwCheckForUpdateService } from '../common/services/ngsw-check-for-update.service';
import { NgSwLogUpdateService } from '../common/services/ngsw-log-update.service';
import { NgSwPromptUpdateService } from '../common/services/ngsw-prompt-update.service';
import { Footer, IconSocials } from './../common/components/footer/components/simple-footer/simple-footer.component';
import { ButtonHeader } from './../common/components/header/components/simple-header-button/simple-header-button.component';
import {
  Header,
  LogoHeader,
  Menu,
  SimpleHeaderComponent
} from './../common/components/header/components/simple-header/simple-header.component';
import { HeaderService } from './../common/components/header/services/header.service';
import { SidebarService } from './../common/components/sidebar/sidebar.service';
import { AppService } from './app.service';
import { HeaderMobileComponent } from './share/common-component/header-mobile/header-mobile.component';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

export const OVERLAY_REF = new InjectionToken<{}>('OVERLAY_REF');
export const MENU_ITEM = new InjectionToken<{}>('OVERLAY_REF');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: FormatDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: FORMAT_DD_MM_YYYY,
    },
  ],
})
export class AppComponent extends BaseComponent {
  @ViewChild('simpleHeader') simpleHeader: SimpleHeaderComponent;
  @ViewChild('floating_btn') floatingBtn: ElementRef;
  @ViewChild('scroll_btn') scrollBtn: ElementRef;

  public logo: LogoHeader;
  public header: Header;
  public isCollapsed = false;
  public sectionsHeader: SectionHeader[];
  public buttonHeader: ButtonHeader;
  public menu: Menu;
  public dropDownMenu: any[];

  public overlayRef: OverlayRef;

  // Footer
  public footer: Footer;
  public logoFooter: IconSocials;
  public sectionsFooter: Footer[];
  public titleFooter: string;

  constructor(
    private readonly headerService: HeaderService,
    private readonly sidebarService: SidebarService,
    private readonly ngSwLogUpdateService: NgSwLogUpdateService,
    private readonly ngSwCheckForUpdateService: NgSwCheckForUpdateService,
    private readonly ngSwPromptUpdateService: NgSwPromptUpdateService,
    private readonly appService: AppService,
    private readonly responsiveLayoutService: ResponsiveLayoutService,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly metaTagService: Meta,
    private readonly translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super();
  }

  onInit() {
    this.headerService.setVisibility();
    this.sidebarService.setSidebarVisibility(false);
    this.initNgSw();
    this.initDataHeader();
    this.initDataFooter();

    this.subscribe(
      this.responsiveLayoutService.getCurrentLayout(),
      (layout) => {
        this.isCollapsed = layout === Layouts.MOBILE;
      }
    );

    if (isPlatformBrowser(this.platformId)) {
      window.onscroll = () => this.handleShowHideFloatingBtn();
    }

    this.metaTagService.addTags([
      { name: 'description', content: '' },
      { name: 'twitter:site', content: '' },
      { name: 'twitter:title', content: '' },
      { name: 'twitter:description', content: '' },
      { name: 'twitter:image', content: '' },
      { property: 'og:title', content: ''},
      { property: 'og:url', content: ''},
      { property: 'og:image', content: ''},
      { property: 'og:description', content: ''},
      { property: 'og:type', content: ''},
    ]);
  }

  onAfterViewInit() {
    this.floatingBtn.nativeElement.style.visibility = 'hidden';
  }

  initDataHeader() {
    this.logo = this.appService.bidingDataHeader();
    this.sectionsHeader = this.appService.getSectionHeader();
    this.menu = this.appService.getMenu();
  }

  initDataFooter() {
    this.footer = this.appService.bindingCompany();
    this.logoFooter = this.appService.getLogoBrand();
    this.footer.home = this.appService.getSectionHome();
    this.footer.sections = this.appService.getSectionFooter();
    this.titleFooter = this.translateService.instant('Footer.section.title');
  }

  public scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  public onToggleBurgerMenu(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.dispose();
      return;
    }
    this.overlayRef = this.overlay.create({
      width: '100vw',
      height: '100vh',
      hasBackdrop: true,
    });
    const injectData = new WeakMap();
    injectData.set(OVERLAY_REF, this.overlayRef);
    injectData.set(MENU_ITEM, this.dropDownMenu);
    const portalInjector = new PortalInjector(this.injector, injectData);
    const userProfilePortal = new ComponentPortal(
      HeaderMobileComponent,
      null,
      portalInjector
    );
    const ref = this.overlayRef.attach(userProfilePortal);
    ref.instance.closePanel.subscribe(() => this.overlayRef.detach());
  }

  private initNgSw() {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      this.ngSwLogUpdateService.init();
      this.ngSwCheckForUpdateService.init();
      this.ngSwPromptUpdateService.init();
    }
  }

  private handleShowHideFloatingBtn(): void {
    const floatingBtnElement = this.floatingBtn.nativeElement as HTMLElement;
    const scrollBtnElement = this.scrollBtn.nativeElement as HTMLElement;
    const rect1 = scrollBtnElement.getBoundingClientRect();
    const rect2 = floatingBtnElement.getBoundingClientRect();

    if (document.documentElement.scrollTop < document.documentElement.clientHeight) {
      floatingBtnElement.style.visibility = 'hidden';
    }
    else {
      if (Math.floor(rect1.top) < Math.floor(rect2.top)) {
        floatingBtnElement.style.visibility = 'hidden';
        scrollBtnElement.style.visibility = 'visible';
      } else {
        floatingBtnElement.style.visibility = 'visible';
        scrollBtnElement.style.visibility = 'hidden';
      }
    }
  }
}
