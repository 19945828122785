import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

declare var Stripe: any;

export interface StripeFormInput {
  title: string;
  submitLabel: string;
  cancelLabel: string;
  componentId: string;
  stripeElementStyles: Object;
  stripeElementClasses: Object;
  formIcon: string;
  cardNamePlaceholder: string;
  fonts: {}[];
}
@Component({
  selector: 'favie-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent extends BaseComponent {
  @Input() publicKey: string;
  @Output() submitEmitter = new EventEmitter();
  @Output() cancelEmitter = new EventEmitter();
  @Input() formInput: StripeFormInput;
  public stripeForm: FormGroup;
  public errorMessage: string;
  private stripe;
  private cardNumber;
  private savedCardError = {};
  constructor(private formBuilder: FormBuilder) {
    super();
  }

  onInit() {
    this.initForm();
  }

  async onSubmit() {
    const { token, error } = await this.stripe.createToken(this.cardNumber, {
      name: this.stripeForm.value.cardName,
    });
    if (token) {
      this.errorMessage = undefined;
      this.submitEmitter.next({
        token: token.id,
        cardInfo: {
          lastFourDigits: token?.card?.last4,
          expYear: token?.card?.exp_year,
          expMonth: token?.card?.exp_month,
          brand: token?.card?.brand
        }
      });
    } else {
      this.errorMessage = error.message;
    }
  }

  cancel() {
    this.cancelEmitter.next();
  }

  private initForm() {
    this.stripeForm = this.formBuilder.group({
      cardName: [undefined, Validators.required],
    });
    this.stripe = Stripe(this.publicKey);
    const element = this.stripe.elements({
      fonts: this.formInput.fonts,
    });

    this.cardNumber = element.create('cardNumber', {
      style: this.formInput.stripeElementStyles,
      classes: this.formInput.stripeElementClasses,
      showIcon: true,
    });
    this.cardNumber.mount('#card-number');

    var cardExpiry = element.create('cardExpiry', {
      style: this.formInput.stripeElementStyles,
      classes: this.formInput.stripeElementClasses,
    });
    cardExpiry.mount('#card-expiry');

    var cardCvc = element.create('cardCvc', {
      style: this.formInput.stripeElementStyles,
      classes: this.formInput.stripeElementClasses,
    });
    cardCvc.mount('#card-cvc');

    this.detectAndShowError([this.cardNumber, cardExpiry, cardCvc]);
  }

  private detectAndShowError(elements: any[]) {
    elements.forEach((element, index) => {
      element.on('change', (event) => {
        if (event.error) {
          this.errorMessage = event.error.message;
          this.savedCardError[index] = this.errorMessage;
        } else {
          this.savedCardError[index] = undefined;

          const stillErrorMess = Object.keys(this.savedCardError)
            .sort()
            .reduce((errorMess, index) => {
              return errorMess || this.savedCardError[index];
            }, undefined);
          this.errorMessage = stillErrorMess;
        }
      });
    });
  }
}
