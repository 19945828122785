<div class="section__title" fxLayout.lt-md="column">
  <div fxLayout="row" class="sub__button d__absolute m__t--6" *ngIf="section?.sub_button" (click)="handleSubButton()">
    <img *ngIf="section?.sub_button?.image" 
    [style.width]="section?.sub_button?.image?.width" 
    [style.height]="section?.sub_button?.image?.height"
    [src]="section?.sub_button?.image?.src" [alt]="section?.sub_button?.image?.alt" />
    <p class="m__l--8" *ngIf="section?.sub_button?.label">{{section?.sub_button?.label}}</p>
  </div>
  <div class="title">
    <h1  *ngIf="section?.title">{{section?.title}}</h1>
    <p class="sub__title" *ngIf="section?.sub_title">{{section?.sub_title}}</p>
  </div>
</div>