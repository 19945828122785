import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscribable } from '../../../../../components/base-component/subscribable';
import { FavieSignUpSignInDialogComponent } from '../favie-sign-up-sign-in-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FavieSignUpSignInDialogService extends Subscribable {
  constructor(private readonly dialog: MatDialog) {
    super();
  }

  public openDialog(config, callback?) {
    const ref = this.dialog.open(FavieSignUpSignInDialogComponent, config);
    this.subscribeOne(ref.afterClosed(), () => {
      if (callback) {
        callback();
      }
    });
  }
}
