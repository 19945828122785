<favie-label
  [label]="field?.labelOutSide"
  [required]="field?.required"
></favie-label>

<mat-form-field appearance="fill">
  <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
  <div class="input__wrapper d__flex">
    <input
      matInput
      type="number"
      [formControl]="control"
      [placeholder]="field?.placeholder || ''"
      [required]="field?.required"
      [minlength]="field?.minLength"
      [maxlength]="field?.maxLength"
      [min]="0"
      [max]="100"
      [pattern]="field?.pattern"
    />
    <span *ngIf="field?.suffix">{{ field?.suffix }}</span>
    <i class="icon-percentage font__size--inherit"></i>
  </div>

  <mat-error *ngIf="field?.errors?.length">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
