import { Location } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface SectionSimple {
  title?: {
    image?: {
      src?: string,
      alt?: string,
      width?: string,
      height?: string
    },
    text?: string;
    back?: boolean
  };
  subTitle?: {
    image?: {
      src?: string,
      alt?: string,
      width?: string,
      height?: string
    },
    text?: string;
  };
}

@Component({
  selector: 'favie-section-simple',
  templateUrl: './section-simple.component.html',
  styleUrls: ['./section-simple.component.scss'],
})
export class SectionSimpleComponent extends BaseComponent {
  @Input() section: SectionSimple;

  @Output() handlePrimary = new EventEmitter<any>();
  @Output() handleSecondary = new EventEmitter<any>();

  constructor(
    private readonly location: Location
  ) {
    super();
  }

  handlePrimaryButton() {
    if (!this.section?.title) {
      return;
    }
    if (this.section?.title?.back) {
      this.location.back();
      return;
    }
    this.handlePrimary.next();
  }

  handleSecondaryButton() {
    if (!this.section?.subTitle) {
      return;
    }
    this.handleSecondary.next();
  }
}
