import { ResponsiveLayoutService, Layouts } from './../../../../services/responsive-layout.service';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';

export interface SectionsFooter {
  id?: string;
  title?: string;
  label: string;
  action?: (label?) => void;
}

export interface IconSocials {
  icon: string;
  link?: string;
  action?: () => void;
}

export interface Footer {
  company?: {
    name?: string;
    address?: SectionsFooter;
    tel?: SectionsFooter;
    email?: SectionsFooter;
  };
  home?: SectionsFooter[];
  sections?: SectionsFooter[];
  showSection?: {
    isShow?: boolean;
    direction?: string;
    directionMobile?: string;
  };
  socials?: {
    label?: string;
    icons?: IconSocials[];
  };
  rights?: {
    label?: string;
    image?: {
      src?: string;
      alt?: string;
      maxWidth?: string;
      maxHeight?: string;
    };
  };
}

@Component({
  selector: 'favie-simple-footer',
  templateUrl: './simple-footer.component.html',
})
export class SimpleFooterComponent extends BaseComponent {
  @Input() footer: Footer;

  public isCollapsed: boolean;

  constructor(
    private readonly responsiveLayoutService: ResponsiveLayoutService
  ) {
    super();
  }

  onInit() {
    this.initCurrentLayout();
  }

  private initCurrentLayout() {
    this.subscribe(
      this.responsiveLayoutService.getCurrentLayout(),
      (layout) => {
        this.isCollapsed = layout === Layouts.MOBILE;
      }
    );
  }
}
