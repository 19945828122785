<section class="card-manage-wrapper full-height">

  <form [formGroup]="stripeForm" (ngSubmit)="onSubmit()" class="form-container" >
    <div class="cell stripeForm">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="m__b--16">
        <div class="form-title">
          <span>{{formInput.title}}</span>
        </div>
        <img class="form-icon" src="{{formInput.formIcon}}"/>
      </div>
      <div class="form-label">
        <label>{{ 'card.name' | translate }}</label>
      </div>
      <div class="row">
        <div class="field">
          <input formControlName="cardName" class="input" type="text" required [placeholder]="formInput.cardNamePlaceholder">
        </div>
      </div>
      <div class="form-label">
        <label for="card-number">{{ 'card.number' | translate }}</label>
      </div>
      <div class="row">
        <div class="field">
          <div id="card-number" class="input"></div>
        </div>
      </div>
      <div class="row" fxLayoutAlign="space-between center">
        <div class="field half-width">
          <div class="form-label">
            <label for="card-expiry">{{ 'card.expiriedDate' | translate }}</label>
          </div>
          <div id="card-expiry" class="input"></div>
        </div>
        <div class="field half-width">
          <div class="form-label">
            <label for="card-cvc">CVC</label>
          </div>
          <div id="card-cvc" class="input"></div>
        </div>
      </div>

      <favie-local-spinner [componentId]="formInput.componentId">
        <div class="p__t--8">
          <button [disabled]="stripeForm.invalid" type="submit">{{formInput.submitLabel}}</button>
        </div>

      </favie-local-spinner>
      <div class="m__t--16 cancel">
        <a (click)="cancel()">{{formInput.cancelLabel}}</a>
      </div>
      <div *ngIf="errorMessage" class="error m__t--8" role="alert">
      <span class="message">{{errorMessage}}</span>
      </div>
    </div>
  </form>
</section>

