import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateMode } from '../../enums/date.enum';
import { BaseComponent } from '../base-component/base.component';
import { DatePickerFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-datepicker',
  templateUrl: './favie-datepicker.component.html',
})
export class FavieDatepickerComponent extends BaseComponent {
  @Input() field: DatePickerFormField;
  @Input() control: FormControl;

  public readonly DEFAULT_DATE_MODE = DateMode.DAY_MONTH_YEAR;

  dateModes = DateMode;

  onInit(): void {
    if (this.field) {
      this.field.dateMode = this.field.dateMode || this.DEFAULT_DATE_MODE;
    }
  }
}
