import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FavieContentColumnFieldData } from './favie-content-column-field-data.interface';

@Component({
  selector: 'favie-content-column-field',
  templateUrl: './favie-content-column-field.component.html',
})
export class FavieContentColumnFieldComponent extends BaseComponent {
  @Input()
  public fields: FavieContentColumnFieldData[];
}
