<section class="app__section">
  <favie-main-layout>

    <ng-container header>
      <favie-simple-header (toggledBurgerMenu)="onToggleBurgerMenu()" [header]="header" [logo]="logo"
        [isCollapsed]="isCollapsed" [sections]="sectionsHeader" [buttonHeader]="buttonHeader" [menu]="menu"
        #simpleHeader>
      </favie-simple-header>
    </ng-container>

    <ng-container content>
      <router-outlet></router-outlet>
    </ng-container>

    <ng-container footer>
      <div #floating_btn class="btn-floating-scroll-top" (click)="scrollToTop()">
        <div>
          <mat-icon>expand_less</mat-icon>
          <span>{{ 'scrollTop' | translate }}</span>
        </div>
      </div>
      <div class="favie__footer-head">
        <div class="shape shape-1"></div>
        <div class="shape shape-2"></div>
        <div class="shape shape-3"></div>
        <span class="footer-head__title">{{titleFooter}}</span>
      </div>
      <div class="favie__footer-bottom">
        <div #scroll_btn class="btn-scroll-top" (click)="scrollToTop()">
          <div>
            <mat-icon>expand_less</mat-icon>
            <span>{{ 'scrollTop' | translate }}</span>
          </div>
        </div>
        <div class="logo-brand"><img loading="lazy" src="./assets/icons/logo-footer.svg" alt=""></div>
        <div class="socials-links">
            <p>Rejoignez-nous sur nos réseaux sociaux:</p>
            <div class="items">
              <a href="https://www.linkedin.com/company/semyos/" target="_blank">
                <img loading="lazy" src="./assets/icons/logos_linkedin_white_text.svg" alt="Semyos est sur linkedin - formation en ligne">
              </a>
            </div>
          </div>
        <favie-simple-footer [footer]="footer">
        </favie-simple-footer>
      </div>
    </ng-container>

  </favie-main-layout>
</section>