<section
  class="favie-content-column__section d__flex justify__content--between align__items--start"
>
  <ng-template [ngIf]="value && value.length">
    <div class="content-column-item" *ngFor="let item of value">
      <span class="content-title">
        {{ item.title }}
      </span>

      <div class="content-field" *ngFor="let field of item.fields">
        <span class="content-label"> {{ field.label }}: </span>

        <span class="content-value" *ngIf="!field.isRichText && !field.isLink && !field.isImage">
          {{ field.value }}
        </span>
        <div class="content-value" *ngIf="field.isRichText" [innerHTML]="field.value"></div>
        <a class="content-value" *ngIf="field.isLink" [href]="field.value" target="_blank">{{ field.value }}</a>
        <img class="content-value" *ngIf="field.isImage" [src]="field.value" [alt]="field.label">

      </div>
    </div>
  </ng-template>
</section>
