import { Injectable, Optional } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';

@Injectable()
export class NgSwPromptUpdateService {
  constructor(@Optional() private readonly updates: SwUpdate) {}

  init() {
    if (this.updates) {
      this.updates.available.subscribe((event) => {
        if (this.promptUser(event)) {
          this.updates.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }

  promptUser(event: UpdateAvailableEvent) {
    // TODO: Notify or ask user if she wanna reload page.
    return true;
  }
}
