<section class="favie-stepper-action__section">
  <div class="action-container">
    <button
      mat-raised-button
      color="primary"
      *ngIf="isSubmitVisible"
      [disabled]="isSubmitDisabled"
      (click)="submit()"
    >
      <mat-icon>{{ input?.matIcons?.submit || "done" }}</mat-icon>
      <span>{{ input?.labels?.submit || "Submit" }}</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      *ngIf="isNextStepVisible"
      [disabled]="isNextStepDisabled"
      (click)="nextStep()"
    >
      <mat-icon>{{ input?.matIcons?.next || "arrow_forward_ios" }}</mat-icon>
      <span>{{ input?.labels?.next || "Next" }}</span>
    </button>

    <button
      mat-stroked-button
      *ngIf="isPreviousStepVisible"
      (click)="previousStep()"
    >
      <mat-icon>{{ input?.matIcons?.previous || "arrow_back_ios" }}</mat-icon>
      <span>{{ input?.labels?.previous || "Back" }}</span>
    </button>

    <button mat-stroked-button *ngIf="isCancelVisible" (click)="cancel()">
      <mat-icon>{{ input?.matIcons?.cancel || "cancel" }}</mat-icon>
      <span>{{ input?.labels?.cancel || "Cancel" }}</span>
    </button>
  </div>
</section>
