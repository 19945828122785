import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BaseComponent } from '../base-component/base.component';
import { DropDownFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-dropdown',
  templateUrl: './favie-dropdown.component.html',
})
export class FavieDropdownComponent extends BaseComponent {
  @Input() field: DropDownFormField;
  @Input() control: FormControl;

  @ViewChild(MatSelect) matSelect: MatSelect;

  // https://github.com/angular/components/issues/10214
  public compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  onAfterViewInit() {
    if (this.field.open) {
      this.matSelect.open();
    }
  }

  private compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1 === f2;
  }
}
