import { Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class NgSwLogUpdateService {
  constructor(@Optional() private readonly updates: SwUpdate) {}

  init() {
    if (this.updates) {
      this.updates.available.subscribe((event) => {
        console.log('current version is', event.current);
        console.log('available version is', event.available);
      });
      this.updates.activated.subscribe((event) => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });
    }
  }
}
