export enum HeaderSectionItem {
  FORMATIONS = 'FORMATIONS',
  DOMICILIATION = 'DOMICILIATION',
  LOCATION = 'LOCATION',
  CONTACT = 'CONTACT',
  BLOG = 'BLOG'
}

export enum HeaderExpandPanel1 {
  IMMOBILIER = 'IMMOBILIER',
  UNIVERDULUXE = 'UNIVERDULUXE',
  LESESSENTIELSDELS = 'LESESSENTIELSDELS',
  NOSFORMATIONS = 'NOSFORMATIONS',
  APROSPOS = 'APROSPOS',
  ZOOM = 'ZOOM'
}

export enum HeaderExpandPanel2 {
  NOSOFFRES = 'NOSOFFRES',
  ESPACE = 'ESPACE',
  SECRETARIAT = 'SECRETARIAT',
  LIENS_UTILES = 'LIENS_UTILES',
  LIEN_INSTITUTIONNELS = 'LIEN_INSTITUTIONNELS'
}

export enum HeaderExpandPanel3 {
  OUR_SPACE = 'OUR SPACES',
  SPACE_RENTAL_HOURS = 'SPACE_RENTAL_HOURS',
  OUR_SERVICES = 'OUR_SERVICES'
}

export enum FooterGroup {
  CONTACT = 'CONTACT',
  ADDRESS = 'ADDRESS',
  LIENS = 'LIENS',
  LIENS1 = 'LIENSUTILES',
  LIENS2 = 'LIENSINSTITUTIONNELS',
  LEGAL_NOTICE = 'LEGAL_NOTICE',
  RESTROUVEZ = 'RESTROUVEZ'
}

export enum ContactCategory {
  FORMATION = 'FORMATION',
  DOMICILIATION = 'DOMICILIATION',
  LOCATION_BUREAUX_ET_SALLE_DE_REUNION = 'LOCATION_BUREAUX_ET_SALLE_DE_REUNION',
  SECRETARIAT = 'SECRETARIAT',
  AUTRE = 'AUTRE',
  DISABILITY_PERSON_REPORT_TO_US = 'DISABILITY_PERSON_REPORT_TO_US'
}
