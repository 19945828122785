<section class="favie-web-admin-header__section">
  <div class="header-content" [class.sidebar-expand]="isSidebarExpand">
    <button mat-button class="backable" *ngIf="isBackalbe" (click)="back()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span>{{ input?.backLabel || "Back" }}</span>
    </button>

    <div style="flex: 1;"></div>

    <favie-user-menu
      *ngIf="input?.userMenu"
      [displayName]="input?.userMenu?.displayName"
      [menuItems]="input?.userMenu?.menuItems"
    ></favie-user-menu>
  </div>

  <div class="header-border-bottom"></div>
</section>
