import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { merge } from 'rxjs';
import { BaseComponent } from '../../../base-component/base.component';
import { HeaderService } from '../../../header/services/header.service';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { FavieWebAdminHeaderInput } from '../../interfaces/favie-web-admin-header-input.interface';

@Component({
  selector: 'favie-web-admin-header',
  templateUrl: './favie-web-admin-header.component.html',
})
export class FavieWebAdminHeaderComponent extends BaseComponent {
  @Input()
  public input: FavieWebAdminHeaderInput;

  public isBackalbe: boolean;
  public isSidebarExpand: boolean;

  constructor(
    private readonly location: Location,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly headerService: HeaderService,
    private readonly sidebarService: SidebarService,
  ) {
    super();
  }

  public back() {
    this.location.back();
  }

  onInit() {
    this.subscribe(this.headerService.isBackableObs(), (isBackable: boolean) => {
      this.isBackalbe = isBackable;
      this.changeDetectorRef.detectChanges();
    });

    this.subscribe(
      merge(
        this.sidebarService.getExpandSidebarObs(),
        this.sidebarService.getExpandOnHoverSidebarObs(),
      ),
      (isExpand: boolean) => {
        this.isSidebarExpand = isExpand;
      }
    );
  }
}
