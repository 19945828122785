<section class="favie-ckeditor5__section">
  <favie-label
    [label]="labelOutSide"></favie-label>
  <ng-container *ngIf="isBrowser()">
    <ckeditor
      [editor]="editor"
      [config]="editorConfig"
      [formControl]="control"
      (ready)="onReadyEditor($event)"></ckeditor>
  </ng-container>
</section>
