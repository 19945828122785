import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';
import { RadioFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-radio-button',
  templateUrl: './favie-radio-button.component.html',
})
export class FavieRadioButtonComponent extends BaseComponent {
  @Input() field: RadioFormField;
  @Input() control: FormControl;
  @Input() color: string;

  public get isRowDirection(): boolean {
    return !this.isColumnDirection;
  }

  public get isColumnDirection(): boolean {
    return this.field?.flexDirection === 'column';
  }
}
