<section class="favie-drag-drop__section">
  <div cdkDropList
       class="drop-list__box"
       [id]="id"
       [cdkDropListConnectedTo]="dropListIds"
       [cdkDropListData]="items"
       (cdkDropListDropped)="drop($event)"
       [cdkDropListEnterPredicate]="enterPredicate">
    <div *ngFor="let item of items; let i = index"
         class="drop-item"
         cdkDrag
         [cdkDragData]="item">
      <ng-container [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{items: items, item: item, index: i}"></ng-container>
    </div>
  </div>
</section>
