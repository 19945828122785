import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'favie-label',
  templateUrl: './favie-label.component.html',
})
export class FavieLabelComponent extends BaseComponent {
  @Input() label: string;
  @Input() required: boolean;
}
