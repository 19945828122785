import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../base-component/base.component';
import { SimpleHeaderService } from './services/simple-header.service';

export interface SectionHeader {
  id?: string;
  label?: string;
  selectStatus?: boolean;
  selected?: boolean;
  action?: (label?) => void;
  children?: SubMenuItem[];
  isExpand?: boolean;
}

interface SubMenuItem {
  id?: string;
  label: string;
  selected: boolean;
  action?: (item) => void;
}

@Component({
  selector: 'favie-simple-header-items',
  templateUrl: './simple-header-items.component.html',
})
export class SimpleHeaderItemsComponent extends BaseComponent {
  @Input() sections: SectionHeader[];

  constructor(private readonly simpleHeaderService: SimpleHeaderService) {
    super();
  }

  onInit() {
    setTimeout(() => {
      this.simpleHeaderService.getSelectItem().subscribe((res) => {
        if (!this.sections) {
          return;
        }
        this.sections = this.sections.map((e) => {
          return Object.assign(e, { selected: res === e.id });
        });
      });
    }, 1);
  }
}
