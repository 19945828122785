import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldErrorType } from 'src/common/components/favie-form/enums/form-field-error-type.enum';
import { BaseComponent } from '../../../base-component/base.component';
import { FormFieldInputType } from '../../../favie-form/enums/form-field-input-type.enum';
import { FormFieldType } from '../../../favie-form/enums/form-field-type.enum';
import { FormInput } from '../../../favie-form/interfaces/form-input.interface';
import { FavieWebAdminSigninFormInput } from '../../interfaces/favie-web-admin-signin-form-input.interface';

@Component({
  selector: 'favie-web-admin-signin-form',
  templateUrl: './favie-web-admin-signin-form.component.html',
})
export class FavieWebAdminSigninFormComponent extends BaseComponent {
  @Input()
  public input?: FavieWebAdminSigninFormInput;
  @Input()
  public contentTemplate?: TemplateRef<any>;

  @Output()
  public submitted = new EventEmitter<any>();
  @Output()
  public forgotPassword = new EventEmitter<any>();

  public formInput: FormInput;

  constructor(
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  public onSubmitted(value) {
    this.submitted.next(value);
  }

  public onForgotPassword() {
    this.forgotPassword.next();
  }

  onInit() {
    this.initForm();
  }

  private initForm() {
    this.formInput = {
      fields: [
        {
          key: 'email',
          type: FormFieldType.INPUT,
          inputType: FormFieldInputType.EMAIL,
          labelOutSide: this.input?.emailLabel || 'Email',
          placeholder: this.input?.emailPlaceholder || 'Email',
          minLength: this.input?.emailMinLength || 3,
          maxLength: this.input?.emailMaxLength || 320,
          required: true,
          ...this.emailIcon,
          validators: [
            Validators.required,
            Validators.email,
            ...(this.input.validators || []),
          ],
          ...this.validatorErrorsEmail,
        },
        {
          key: 'password',
          type: FormFieldType.PASSWORD,
          inputType: FormFieldInputType.PASSWORD,
          labelOutSide: this.input?.passwordLabel || 'Password',
          placeholder: this.input.passwordPlaceholder || 'Password',
          required: true,
          ...this.passwordIconField,
          ...this.validatorErrorsPassword,
          validators: [Validators.required],
        },
      ],
      skipCheckInvalidForm: this.input?.skipCheckInvalidForm,
      primaryButtonLabel: this.input?.primaryButtonLabel || 'Sign In',
      primaryButtonMatIcon: this.input?.primaryButtonIcon,
      secondaryButtonLabel: this.input?.secondaryButtonLabel,
      primaryLocalSpinnerId: this.input?.spinnerId,
      ...this.getValueChangeHandle,
    };
  }

  private get emailIcon() {
    let icon;
    if (this.input.hasIcon) {
      icon = this.input.emailIcon ? this.input.emailIcon : 'icon-user';
    }
    return icon ? { icon } : {};
  }

  private get validatorErrorsEmail() {
    const errors = this.input?.skipCheckInvalidForm
      ? {
          errors: [
            {
              type: FormFieldErrorType.REQUIRED,
              message: this.translateService.instant('thisFieldIsRequired'),
            },
            {
              type: FormFieldErrorType.EMAIL,
              message: this.translateService.instant('emailNotValid'),
            },
            ...(this.input.errors || []),
          ],
        }
      : {};

    return errors;
  }

  private get passwordIconField() {
    return this.input.hasIcon
      ? {
          icon: 'icon-eye',
          handleIcon: this.handlePasswordIcon,
        }
      : {};
  }

  private validatorErrorsPassword() {
    return this.input?.skipCheckInvalidForm
      ? {
          errors: [
            {
              type: FormFieldErrorType.REQUIRED,
              message: this.translateService.instant('thisFieldIsRequired'),
            },
          ],
        }
      : {};
  }

  private handlePasswordIcon(field) {
    switch (field.inputType) {
      case FormFieldInputType.TEXT:
        field.inputType = FormFieldInputType.PASSWORD;
        field.icon = 'icon-eye';
        break;
      case FormFieldInputType.PASSWORD:
        field.inputType = FormFieldInputType.TEXT;
        field.icon = 'icon-eye-blocked';
        break;
    }
  }

  private get getValueChangeHandle() {
    return this.input?.valueChanges
      ? {
          valueChanges: this.input?.valueChanges,
        }
      : {};
  }
}
