<section class="favie-image-uploader__section">
  <favie-label [label]="field?.label"></favie-label>

  <mat-tab-group #matTabGroup animationDuration="0ms" [(selectedIndex)]="currentTabIndex" (selectedIndexChange)="onChangeTab()">
    <mat-tab label="{{tabLabel.computer}}">
      <div class="upload-image">
        <favie-upload-pictures #uploadPicturesComponent
                               [iconImage]="uploadPictureField.iconImage"
                               [limitImages]="uploadPictureField.limitImages"
                               [limitSize]="field.imageSizeLimit"
                               [images]="uploadedImageLink ? [{ src: uploadedImageLink }] : []"
                               [showActions]="false"
                               [label]="'BROWSE'"
                               (imagesChange)="setImagesChange($event)">
        </favie-upload-pictures>
        <div class="btn-remove" *ngIf="uploadedImageLink" (click)="uploadPicturesComponent.remove(0)">
          <i class="icon-trash"></i>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{tabLabel.link}}">
      <div class="link">
        <favie-input [control]="imageLinkControl" [field]="imageLinkField"></favie-input>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="expiration-section" *ngIf="field?.expireDate?.isDisplayed">
    <div class="end-of-right">
      <mat-checkbox [checked]="hasExpireDateControl.value" (click)="changeEndOfRight($event, true)"></mat-checkbox>
      <favie-datepicker [control]="expireDateControl"
                        [field]="expireDateField">
      </favie-datepicker>
    </div>
    <div class="no-end-of-right">
      <mat-checkbox [checked]="!hasExpireDateControl.value" (click)="changeEndOfRight($event, false)"></mat-checkbox>
      <span>{{ 'Global.ImageUploader.NoEndOfRight' | translate }}</span>
    </div>
  </div>

  <mat-error *ngIf="currentTabIndex === TAB_INDEX_UPLOAD && imageLinkControl.value">
    {{ 'Global.ImageUploader.Error.ConflictWithLink' | translate }}
  </mat-error>
  <mat-error *ngIf="currentTabIndex === TAB_INDEX_LINK && uploadedImageLink">
    {{ 'Global.ImageUploader.Error.ConflictWithImport' | translate }}
  </mat-error>

  <mat-error *ngIf="errors?.length">
    <div *ngFor="let error of errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </div>
  </mat-error>
</section>
