<div>
  <div fxLayout="row" fxLayoutAlign="end center" *ngIf="isCollapsed">
    <i class="icon-cancel-circle" (click)="dialogRef.close()"></i>
  </div>
  <section class="from-sign-in-sign-up">
    <div class="form-box">
      <div class="center">
        <h1 class="from-sign-in-sign-up big_title" *ngIf="!data?.customTitle">
          {{ title }}
        </h1>
        <h1 class="from-sign-in-sign-up big_title" *ngIf="data?.customTitle">
          {{ data.customTitle }}
        </h1>
        <span class="from-sign-in-sign-up small_title"> {{ subTitle }} </span>
      </div>
      <favie-social-media
        [socialLabel]="socialLabel"
        [localLabel]="localLabel"
        (googleEmitter)="googleEmitter()"
        (facebookEmitter)="facebookEmitter()"
        (appleEmitter)="appleEmitter()"
        [icon]="data.socialIcon">
      </favie-social-media>
      <div *ngIf="isSignInMode; then signinBlock; else signupBlock"></div>
      <ng-template #signinBlock>
        <favie-sign-in
          [form]="formSignIn"
          (submitEmitter)="onSubmitSignIn($event)"
        ></favie-sign-in>
      </ng-template>
      <ng-template #signupBlock>
        <favie-sign-up
          [form]="formSignUp"
          (submitEmitter)="onSubmitSignUp($event)"
        ></favie-sign-up>
      </ng-template>
      <div class="center changeModal">
        {{changeModalText}}
        <a (click)="changeModalMode()" class="form-link">{{changeModalLabel}}</a>
      </div>
    </div>
  </section>
</div>
