import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'googlemaps';
import { BaseComponent } from '../base-component/base.component';
import { AddressAutoCompleteFormField } from '../favie-form/interfaces/form-field.interface';

@Component({
  selector: 'favie-address-autocomplete',
  templateUrl: './favie-address-autocomplete.component.html',
})
export class FavieAddressAutocompleteComponent extends BaseComponent {
  @Input() field: AddressAutoCompleteFormField;
  @Input() control: FormControl;
  @Input() addressType: 'establishment' | 'address' | 'geocode' = 'address';

  @Output() addressChanged: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();

  @ViewChild('addressInput') addressInput: any;

  private readonly AUTOCOMPLETE_FIELDS = [
    'address_component',
    'formatted_address',
    'name',
    'place_id',
    'type',
    'url',
    'utc_offset_minutes',
    'vicinity',
    'geometry',
  ];

  onAfterViewInit() {
    this.getAutocompleteInput();
  }

  private getAutocompleteInput() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressInput.nativeElement,
      {
        fields: this.AUTOCOMPLETE_FIELDS,
        types: [this.addressType],
      }
    );

    if (this.field.regionRestrictions?.length) {
      autocomplete.setComponentRestrictions({
        country: this.field.regionRestrictions,
      });
    }

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.onPlaceChanged(place);
    });
  }

  private onPlaceChanged(place: google.maps.places.PlaceResult) {
    this.addressChanged.emit(place);
  }
}
