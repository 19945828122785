import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../components/base-component/base.component';

export interface SocialIcon {
  facebook: string;
  gmail: string;
  apple: string;
}
@Component({
  selector: 'favie-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent extends BaseComponent {
  @Input() socialLabel: string;
  @Input() localLabel: string;
  @Input() icon: SocialIcon;
  @Output() googleEmitter: EventEmitter<any> = new EventEmitter();
  @Output() facebookEmitter: EventEmitter<any> = new EventEmitter();
  @Output() appleEmitter: EventEmitter<any> = new EventEmitter();
  constructor() {
    super();
  }

  public handleGoogle() {
    this.googleEmitter.next();
  }

  public handleApple() {
    this.appleEmitter.next();
  }

  public handleFacebook() {
    this.facebookEmitter.next();
  }
}
