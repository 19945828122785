import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { BaseDialogComponent } from '../components/base-dialog/base-dialog.component';
import { FavieAddressAutocompleteComponent } from '../components/favie-address-autocomplete/favie-address-autocomplete.component';
import { FavieAutoCompleteComponent } from '../components/favie-auto-complete/favie-auto-complete.component';
import { FavieBreadCrumbItemComponent } from '../components/favie-breadcrumb/favie-breadcrumb-item.component';
import { FavieBreadCrumbComponent } from '../components/favie-breadcrumb/favie-breadcrumb.component';
import { FavieCheckboxComponent } from '../components/favie-checkbox/favie-checkbox.component';
import { FavieCkEditor5Component } from '../components/favie-ckeditor5/favie-ckeditor5.component';
import { FavieContentColumnFieldComponent } from '../components/favie-content-column-field/favie-content-column-field.component';
import { FavieContentColumnComponent } from '../components/favie-content-column/favie-content-column.component';
import { FavieContentHeaderComponent } from '../components/favie-content-header/favie-content-header.component';
import { FavieDatepickerYearComponent } from '../components/favie-datepicker/date-picker-year/datepicker-year.component';
import { FavieDatepickerMonthYearComponent } from '../components/favie-datepicker/datepicker-month-year/datepicker-month-year.component';
import { FavieDatepickerComponent } from '../components/favie-datepicker/favie-datepicker.component';
import { FavieDragDropComponent } from '../components/favie-drag-drop/favie-drag-drop.component';
import { FavieDropdownComponent } from '../components/favie-dropdown/favie-dropdown.component';
import { FavieDynamicSectionComponent } from '../components/favie-dynamic-section/favie-dynamic-section.component';
import { FavieFormComponent } from '../components/favie-form/favie-form.component';
import { FavieImageUploaderComponent } from '../components/favie-image-uploader/favie-image-uploader.component';
import { FavieInfoPanelComponent } from '../components/favie-info-panel/favie-info-panel.component';
import { FavieInputComponent } from '../components/favie-input/favie-input.component';
import { FavieLabelComponent } from '../components/favie-label/favie-label.component';
import { FaviePaginatorComponent } from '../components/favie-paginator/favie-paginator.component';
import { FaviePasswrodComponent } from '../components/favie-password/favie-password.component';
import { FaviePercentageComponent } from '../components/favie-percentage/favie-percentage.component';
import { FaviePhoneComponent } from '../components/favie-phone/favie-phone.component';
import { FaviePriceComponent } from '../components/favie-price/favie-price.component';
import { FavieRadioButtonComponent } from '../components/favie-radio-button/favie-radio-button.component';
import { FavieSearchBarComponent } from '../components/favie-search-bar/favie-search-bar.component';
import { FavieSectionComponent } from '../components/favie-section/favie-section.component';
import { FavieStepperActionComponent } from '../components/favie-stepper-action/favie-stepper-action.component';
import { FavieTextAreaEditorComponent } from '../components/favie-textarea-editor/favie-textarea-editor.component';
import { FavieTextareaComponent } from '../components/favie-textarea/favie-textarea.component';
import { FavieWebAdminHeaderComponent } from '../components/favie-web-admin/components/favie-web-admin-header/favie-web-admin-header.component';
import { FavieWebAdminSigninFormComponent } from '../components/favie-web-admin/components/favie-web-admin-signin-form/favie-web-admin-signin-form.component';
import { SimpleFooterComponent } from '../components/footer/components/simple-footer/simple-footer.component';
import { FooterComponent } from '../components/footer/footer.component';
import { BurgerMenuComponent } from '../components/header/components/burger-menu/burger-menu.component';
import { SimpleHeaderButtonComponent } from '../components/header/components/simple-header-button/simple-header-button.component';
import { SimpleHeaderItemsComponent } from '../components/header/components/simple-header-items/simple-header-items.component';
import { SimpleHeaderProfileComponent } from '../components/header/components/simple-header-profile/simple-header-profile.component';
import { SimpleHeaderSecondaryComponent } from '../components/header/components/simple-header-secondary/simple-header-secondary.component';
import { SimpleHeaderComponent } from '../components/header/components/simple-header/simple-header.component';
import { FavieUserMenuComponent } from '../components/header/components/user-menu/user-menu.component';
import { HeaderComponent } from '../components/header/header.component';
import { ImageCropperDiaLogComponent } from '../components/image-cropper-dialog/image-cropper-dialog.component';
import { InfiniteScrollComponent } from '../components/infinite-scroll/infinite-scroll.component';
import { LandingPageContentMainComponent } from '../components/landing-page-content/components/landing-page-content-main/landing-page-content-main.component';
import { LandingPageContentSubComponent } from '../components/landing-page-content/components/landing-page-content-sub/landing-page-content-sub.component';
import { LandingPageContentComponent } from '../components/landing-page-content/landing-page-content.component';
import { LanguageSwitcherComponent } from '../components/language-switcher-component/language-switcher.component';
import { LocalSpinnerComponent } from '../components/local-spinner/local-spinner.component';
import { SectionTitleComponent } from '../components/section/components/section-title/section-title.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { StripeComponent } from '../components/stripe-component/stripe.component';
import { UploadPicturesComponent } from '../components/upload-pictures/upload-pictures.component';
import { DirectivesModule } from '../directives/directives.module';
import { MainLayoutComponent } from '../layouts/main-layout/main-layout.component';
import { PageContentLayoutComponent } from '../layouts/page-content-layout/page-content-layout.component';
import { PageLayoutComponent } from '../layouts/page-layout/page-layout.component';
import { PipesModule } from '../pipes/pipes.module';
import { LanguageService } from '../services/language.service';
import { ImageCropperComponent } from './../components/image-cropper/image-cropper.component';
import { SectionSimpleComponent } from './../components/section/components/section-simple/section-simple.component';
import { AppMaterialModule } from './app-material.module';
import { FavieAuthComponent } from './auth/components/favie-auth.component';
import { FavieSignInButtonComponent } from './auth/components/favie-sign-in-button/favie-sign-in-button.component';
import { FavieForgotPasswordDialogComponent } from './auth/components/forgot-password-dialog/favie-forgot-password-dialog.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { FavieSignInComponent } from './auth/components/sign-in/favie-sign-in.component';
import { FavieSignUpSignInDialogComponent } from './auth/components/sign-up-sign-in-dialog/favie-sign-up-sign-in-dialog.component';
import { FavieSignUpSocialMediaDialogComponent } from './auth/components/sign-up-social-media-dialog/favie-sign-up-social-media-dialog.component';
import { FavieSignUpComponent } from './auth/components/sign-up/favie-sign-up.component';
import { SocialMediaComponent } from './auth/components/social-media/social-media.component';
import { FavieTimePickerComponent } from '../components/favie-time-picker/favie-time-picker.component';
import {
  FavieImageUploadOrDragDropComponent
} from '../components/favie-image-upload-or-drag-drop/favie-image-upload-or-drag-drop';
import {
  FileDragNDropDirective
} from '../components/favie-image-upload-or-drag-drop/favie-image-upload-or-drag-drop.directive';
import { FavieMatSlideToggleComponent } from '../components/favie-slide-toggle/favie-mat-slide-toggle.component';

const components: Type<any>[] = [
  BaseDialogComponent,
  FavieAddressAutocompleteComponent,
  FavieAutoCompleteComponent,
  FavieCheckboxComponent,
  FavieContentHeaderComponent,
  FavieContentColumnFieldComponent,
  FavieContentColumnComponent,
  FavieDatepickerComponent,
  FavieDropdownComponent,
  FavieFormComponent,
  FavieInfoPanelComponent,
  FavieInputComponent,
  FavieLabelComponent,
  FaviePaginatorComponent,
  FavieSearchBarComponent,
  FavieStepperActionComponent,
  FavieTextareaComponent,
  FavieWebAdminHeaderComponent,
  FavieWebAdminSigninFormComponent,
  SimpleFooterComponent,
  FooterComponent,
  BurgerMenuComponent,
  SimpleHeaderButtonComponent,
  SimpleHeaderItemsComponent,
  SimpleHeaderSecondaryComponent,
  SimpleHeaderComponent,
  FavieUserMenuComponent,
  HeaderComponent,
  ImageCropperDiaLogComponent,
  InfiniteScrollComponent,
  LandingPageContentMainComponent,
  LandingPageContentSubComponent,
  LandingPageContentComponent,
  LanguageSwitcherComponent,
  LocalSpinnerComponent,
  SectionTitleComponent,
  SidebarComponent,
  SpinnerComponent,
  StripeComponent,
  UploadPicturesComponent,
  MainLayoutComponent,
  PageContentLayoutComponent,
  PageLayoutComponent,
  ImageCropperComponent,
  SectionSimpleComponent,
  FavieAuthComponent,
  FavieSignInButtonComponent,
  FavieForgotPasswordDialogComponent,
  ResetPasswordComponent,
  FavieSignInComponent,
  FavieSignUpSignInDialogComponent,
  FavieSignUpComponent,
  SocialMediaComponent,
  FavieCkEditor5Component,
  FaviePasswrodComponent,
  SimpleHeaderProfileComponent,
  FaviePriceComponent,
  FavieRadioButtonComponent,
  FavieSignUpSocialMediaDialogComponent,
  FavieDragDropComponent,
  FavieDatepickerMonthYearComponent,
  FavieBreadCrumbComponent,
  FavieBreadCrumbItemComponent,
  FavieDatepickerYearComponent,
  FavieImageUploaderComponent,
  FavieTextAreaEditorComponent,
  FaviePhoneComponent,
  FaviePercentageComponent,
  FavieDynamicSectionComponent,
  FavieSectionComponent,
  FavieTimePickerComponent,
  FavieImageUploadOrDragDropComponent,
  FileDragNDropDirective,
  FavieMatSlideToggleComponent
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
    InfiniteScrollModule,
    AppMaterialModule,
    CKEditorModule,
    PipesModule,
    DirectivesModule,
    DragDropModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
  ],
  declarations: [components],
  providers: [LanguageService],
  exports: [components],
})
export class AllComponentModule { }
