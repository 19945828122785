<section class="d__flex favie-input__section">
  <favie-label *ngIf="field?.labelOutSide" [label]="field?.labelOutSide" [required]="field?.required"></favie-label>

  <mat-form-field appearance="fill">
    <mat-label *ngIf="field?.label">{{ field?.label }}</mat-label>
    <div [class.input__icon]="field?.icon" class="input__wrapper">
      <input
        matInput
        [formControl]="control"
        [type]="field?.inputType || 'text'"
        [placeholder]="field?.placeholder || ''"
        [required]="field?.required"
        [minlength]="field?.minLength"
        [maxlength]="field?.maxLength"
        [pattern]="field?.pattern"
      />
      <span matSuffix *ngIf="field?.suffix">{{ field?.suffix }}</span>
      <i matSuffix *ngIf="field?.icon" [ngClass]="field?.icon"></i>
    </div>

    <mat-error *ngIf="field?.errors?.length">
      <ng-container *ngFor="let error of field.errors">
        <ng-template [ngIf]="control.hasError(error.type)">
          {{ error.message }}
        </ng-template>
      </ng-container>
    </mat-error>
  </mat-form-field>

  <button *ngIf="field?.inputType === 'url'"
          mat-raised-button
          color="primary"
          class="test-link m__b--24 m__l--16"
          [class.m__t--8]="field?.labelOutSide"
          (click)="onTestLink()">
    <span>{{ 'Global.TestLink' | translate }}</span>
  </button>
</section>
