<section
  class="landing-page-sub__section"
  [style.background]="content?.background || 'inherit'">
  <div class="container">
    <div class="icon" *ngIf="content?.iconUrl">
      <img [src]="content.iconUrl" />
    </div>
    <div class="title">{{ content.title || "Title" }}</div>
    <div class="label" *ngIf="content?.label">{{ content.label }}</div>
  </div>
</section>
