import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FormInput } from 'src/common/components/favie-form/interfaces/form-input.interface';
import { CommonConstant } from 'src/common/constants/common.constant';
import { AuthService } from 'src/common/modules/auth/components/services/auth.service';
import { BaseComponent } from '../../../../components/base-component/base.component';
import { FavieFormComponent } from '../../../../components/favie-form/favie-form.component';

@Component({
  selector: 'favie-forgot-password-dialog',
  templateUrl: './favie-forgot-password-dialog.component.html',
  styleUrls: ['./favie-forgot-password-dialog.component.scss'],
})
export class FavieForgotPasswordDialogComponent extends BaseComponent {
  formInput: FormInput;

  @ViewChild('forgotPasswordForm')
  forgotPasswordFormComponent: FavieFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<FavieForgotPasswordDialogComponent>,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  onInit() {
    this.initForm();
  }

  async onSubmit(data) {
    const input = {
      email: data.email.toLowerCase(),
    };
    try {
      this.forgotPasswordFormComponent.form.controls['email'].setErrors(null);
      await this.authService.forgotPassword(input);
      this.snackBar.open(
        this.translateService.instant('Message.Success'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      this.onCanel();
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        const gqlError = err.graphQLErrors[0].extensions?.exception;
        if (gqlError && gqlError.status === 405) {
          this.forgotPasswordFormComponent.form.controls['email'].setErrors(
            this.forgotPasswordFailedAtSocial(gqlError.message)
          );
          this.snackBar.open(
            this.translateService.instant('forgotPassword.failed'),
            null,
            CommonConstant.FAILURE_SNACKBAR_CONFIG
          );
          return;
        }
      }
      const msg = err.message;
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    }
  }

  onCanel() {
    this.dialogRef.close();
  }

  private initForm() {
    if (this.data) {
      this.formInput = this.data?.formInput;
    }
  }

  private forgotPasswordFailedAtSocial(message: string) {
    switch (message) {
      case 'google.com':
        return { EMAIL_SOCIAL_GOOGLE: true };
      case 'facebook.com':
        return { EMAIL_SOCIAL_FACEBOOK: true };
      default:
        return { EMAIL_SOCIAL_APPLE: true };
    }
  }
}
