import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { TranslateLoaderService } from './translate-loader.service';

export function createTranslateLoader(http: HttpClient, transferState: TransferState) {
  return new TranslateLoaderService('./assets/i18n/', '.json', http, transferState);
}

@NgModule({
  imports: [
    NgxTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  exports: [NgxTranslateModule],
})
export class TranslateModule {}
