import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../../components/base-component/base.component';
import { FormInput } from '../../../../components/favie-form/interfaces/form-input.interface';
import { CommonConstant } from '../../../../constants/common.constant';
import { LocalSpinnerService } from '../../../../services/local-spinner.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'favie-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends BaseComponent {
  public formInput: FormInput;

  private token;

  private readonly localSpinnerId = 'reset-password';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly localSpinnerService: LocalSpinnerService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  onInit(): void {
    this.token = this.route.snapshot.queryParams.token;
    this.initFormInput();
  }

  async onSubmit(value) {
    if (value.newPassword !== value.passwordConfirmation) {
      return this.snackBar.open(
        this.translateService.instant('passwordNotMatching'),
        null,
        CommonConstant.FAILURE_SNACKBAR_CONFIG
      );
    }

    this.localSpinnerService.startLocalSpinner(this.localSpinnerId);

    try {
      const input = {
        token: this.token,
        password: value.newPassword,
      };

      const result: any = await this.authService.resetPassword(input);
      const email = result.data.resetPassword;
      this.snackBar.open(
        this.translateService.instant('Message.Success'),
        null,
        CommonConstant.SUCCESS_SNACKBAR_CONFIG
      );
      const inputSignIn = {
        email,
        password: value.newPassword,
      };
      this.authService.signIn(inputSignIn);
      this.router.navigateByUrl('/');
    } catch (err) {
      const msg = err.message;
      this.snackBar.open(msg, null, CommonConstant.FAILURE_SNACKBAR_CONFIG);
    } finally {
      this.localSpinnerService.stopLocalSpinner(this.localSpinnerId);
    }
  }

  private initFormInput() {
    this.formInput = this.authService.getResetPasswordFormInput();

    this.formInput.primaryLocalSpinnerId = this.localSpinnerId;
    this.localSpinnerService.registerComponent(this.localSpinnerId);
  }
}
