<section class="language-switcher__section">
  <div
    class="language-switcher favie__menu--secondary d__flex justify__content--center align__items--center"
    (click)="openLanguageSecondaryMenu()"
  >
    <img class="m__r--8" *ngIf="iconLocales" [src]="iconLocales[locale]" alt="icon language"> 
    <span class="locale">
      {{ locale | uppercase }}
    </span>

    <img
      *ngIf="!iconClass"
      class="icon"
      src="./assets/icons/arrow-downward-fill.svg"
    />
    <i *ngIf="iconClass" class="icon" [class]="iconClass"></i>

    <div class="toggle-secondaryMenu" *ngIf="displayLanguageSecondaryMenu">
      <ul>
        <li *ngFor="let language of languages">
          <img *ngIf="iconLocales" [src]="iconLocales[language.value]" alt="icon language">
          <a (click)="changeLanguage(language.value)">            
            {{ language.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>
