<section
  *ngIf="isVisible"
  class="favie__sidebar"
  [class.favie__sidebar--small]="!isExpand"
  [class.d__none]="isHide"
>
  <div class="favie__sidebar--inner-scroll">
    <ng-template [ngIf]="logo">
      <div class="favie__sidebar__logo">
        <a [routerLink]="logo.routerLink">
          <img [src]="logo.src" />
        </a>
      </div>
      <hr />
    </ng-template>
    <ng-content select="[header]"></ng-content>

    <ul class="fav__sidebar-top-level-items">
      <li
        *ngFor="let item of items"
        class="favie__sidebar__item"
        [class.active]="activeItem === item.item"
      >
        <a [routerLink]="item.routerLink">
          <div class="favie__sidebar__item__highlight"></div>
          <mat-icon *ngIf="item?.matIcon">{{ item?.matIcon }}</mat-icon>
          <i *ngIf="item?.icon" class="icomoon" [class]="item?.icon"></i>
          <span class="label">{{ item.label }}</span>
        </a>
      </li>
      <ng-content select="[content]"></ng-content>
      <li
        class="favie__sidebar__item collapse-item"
        [class.expanded]="isExpand"
      >
        <a (click)="onToggleExpand()">
          <i class="icon-home"></i>
          <span [class.d__none]="!isExpand">Collapse sidebar</span>
        </a>
      </li>
    </ul>

    <ng-content select="[footer]"></ng-content>
  </div>
</section>
