import {
  ResponsiveLayoutService,
  Layouts,
} from './../../../../services/responsive-layout.service';
import { Component, Input } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from '../../../../components/base-component/base.component';
import { FavieSignUpSignInDialogService } from '../sign-up-sign-in-dialog/services/favie-sign-up-sign-in-dialog.service';

@Component({
  selector: 'favie-sign-in-button',
  templateUrl: './favie-sign-in-button.component.html',
})
export class FavieSignInButtonComponent extends BaseComponent {
  @Input() form;

  private currentLayout;

  constructor(
    private readonly favieSignUpSignInDialogService: FavieSignUpSignInDialogService,
    private readonly responsiveLayoutService: ResponsiveLayoutService
  ) {
    super();
  }

  onInit() {
    this.subscribe(
      this.responsiveLayoutService.getCurrentLayout(),
      (layout) => {
        this.currentLayout = layout;
      }
    );
  }

  toggleDialog() {
    let dialogConfig = new MatDialogConfig();
    if (!this.currentLayout || this.currentLayout === Layouts.MOBILE) {
      dialogConfig = {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        closeOnNavigation: true,
        data: this.formDataDiaLog(),
      };
    } else {
      dialogConfig = {
        width: '528px',
        height: 'auto',
        minHeight: '435px',
        maxHeight: '760px',
        closeOnNavigation: true,
        data: this.formDataDiaLog(),
      };
    }
    this.favieSignUpSignInDialogService.openDialog(dialogConfig);
  }

  private formDataDiaLog() {
    const formSignIn = this.form?.formSignIn;
    const formSignUp = this.form?.formSignUp;
    const socialIcon = this.form?.socialIcon;
    return {
      title: {
        title: this.form?.title.title,
        signIn: this.form?.title?.signIn,
        signUp: this.form?.title?.signUp,
        mainTitle: this.form?.title?.mainTitle,
        subTitle: this.form?.title?.subTitle,
        dontHaveAccount: this.form?.title?.dontHaveAccount,
        alreadyHaveAccount: this.form?.title?.alreadyHaveAccount,
        signinWith: this.form?.title?.signinWith,
        orSigninWith: this.form?.title?.orSigninWith,
        signupWith: this.form?.title?.signupWith,
        orSignupWith: this.form?.title?.orSignupWith,
        agreeTo: this.form?.title?.agreeTo,
        agreeToLinkText: this.form?.title?.agreeToLinkText
      },
      formSignIn,
      formSignUp,
      socialIcon,
    };
  }
}
