<section class="favie-forgot-password__section">
  <ng-template [ngIf]="data">
    <div class="close__btn" fxLayoutAlign="end" (click)="dialogRef.close()">
      <i *ngIf="!data?.close" class="icon-cancel-circle"></i>
      <img
        *ngIf="data?.close"
        [src]="data?.close?.src"
        [style.max-width]="data?.close?.maxWidth"
        [style.max-height]="data?.close?.maxHeight"
      />
    </div>
  </ng-template>
  <div fxLayout="column" fxLayoutAlign="center center" class="m__b--24">
    <h1 *ngIf="data?.title">{{ data?.title }}</h1>
    <span *ngIf="data?.subTitle">{{ data.subTitle }}</span>
  </div>
  <favie-form
    #forgotPasswordForm
    class="dialog__form"
    [formInput]="formInput"
    (primaryEmitter)="onSubmit($event)"
    (secondaryEmitter)="onCanel()"
  ></favie-form>
</section>
