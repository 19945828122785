import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FavieImageTemp } from '../upload-pictures/upload-pictures.component';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appFileDragDrop]'
})

export class FileDragNDropDirective {
  @Output() files: EventEmitter<FavieImageTemp[]> = new EventEmitter();
  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    const images: FavieImageTemp[] = [];
    const innerFiles = evt.dataTransfer.files;
    const readFile = (file) => {
      if (file.size > environment.imageUploadMaximumFileSize) {
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        images.push({
          file,
          src: e.target.result,
        });
        this.files.emit(images);
      };
      fileReader.readAsDataURL(file);
    };

    readFile(innerFiles[0]);
  }
}
