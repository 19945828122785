import { Pipe, PipeTransform } from '@angular/core';
import { PriceUtil } from '../utils/price.utils';
const padding = '000000';

@Pipe({
  name: 'currencyPipe',
})
export class FavieCurrencyPipe implements PipeTransform {
  private decimalSeparator: string;
  private thousandSeparator: string;

  constructor() {
    this.decimalSeparator = ',';
    this.thousandSeparator = ' ';
  }

  transform(value: string, fractionSize: number = 0): string {
    return PriceUtil.toString(value, fractionSize);
  }

  parse(value: string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);

    integer = integer.replace(new RegExp(this.thousandSeparator, 'g'), '');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0
        ? this.decimalSeparator +
          (fraction + padding).substring(0, fractionSize)
        : '';
    return integer + fraction;
  }
}
