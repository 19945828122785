<section class="d__flex flex__direction--column favie-input__section" [class.has-error]="control.invalid">
  <favie-label
    *ngIf="field?.labelOutSide"
    [label]="field?.labelOutSide"
    [required]="field?.required"
  ></favie-label>

  <favie-form class="w-100" #formComponent *ngIf="formInput" [formInput]="formInput"></favie-form>

  <mat-error *ngIf="field?.errors?.length && (control.touched || control.dirty)">
    <ng-container *ngFor="let error of field.errors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </ng-container>
  </mat-error>
</section>
