import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { TokenInterceptor } from '../common/interceptors/token.interceptor';
import { FirebaseModule } from '../common/modules/firebase/firebase.module';
import { GraphQLModule } from '../common/modules/gql/gql.module';
import { InfrastructureModule } from '../common/modules/infrastructure.module';
import { PipesModule as InfrastructurePipesModule } from '../common/pipes/pipes.module';
import { ContextDataService } from '../common/services/context-data.service';
import { CountryService } from '../common/services/country.service';
import { NgSwCheckForUpdateService } from '../common/services/ngsw-check-for-update.service';
import { NgSwLogUpdateService } from '../common/services/ngsw-log-update.service';
import { NgSwPromptUpdateService } from '../common/services/ngsw-prompt-update.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageInterceptor } from './share/interceptors/language.interceptor';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    InfrastructureModule,
    GraphQLModule,
    FirebaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    InfrastructurePipesModule,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    LayoutModule,
    TranslateModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    ContextDataService,
    CountryService,
    NgSwLogUpdateService,
    NgSwCheckForUpdateService,
    NgSwPromptUpdateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
