<section class="info-panel__section m__b--16">
  <ng-template [ngIf]="info.title">
    <div class="title">
      <h2>{{ info.title }}</h2>
    </div>
  </ng-template>

  <ng-template [ngIf]="info.fields">
    <div class="fields">
      <div class="field" *ngFor="let field of info.fields">
        <div class="label">{{ field.label }}</div>
        <div class="value m__l--16">{{ field.value }}</div>
      </div>
    </div>
  </ng-template>
</section>
